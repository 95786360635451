import React, { useEffect, useState } from 'react';
import { FILE_OPTION } from '../../../constants/common.constant';
import { getAlignerVideo } from '../../../Services/alignerService';
import AlignerVideo from '../../../assets/Videos/Aligner-videos/Treatment-Plan-Louis-Zanden-Bosch.mp4';

const AlignerVideoIframe = ({ heightSize, alignerUser }) => {
    const [videoFile, setVideoFile] = useState();

    useEffect(() => {
        if (alignerUser?.file_option === FILE_OPTION.UPLOAD) {
            getAlignerVideo(alignerUser.video_name)
            .then((res) => {
                setVideoFile(res?.data?.data);
            })
            
        }
    }, [alignerUser]);

    return (
        <>
            {
                alignerUser?.file_option === FILE_OPTION.WEB_URL ?
                    <iframe
                        title='aligner video'
                        src={alignerUser.web_url}
                        className='w-100 border'
                        height={heightSize}
                        controls
                    ></iframe> : <video className='w-100' src={videoFile} preload='auto' height={heightSize} controls></video>
            }
            {/* <iframe title='aligner'
            src='https://www.image-instruments.de/webviewer/index.html?mlink=https://cloud.frenchdesigners.fr/Client3615/21664/E34BD5E6DEA14390AF89B6D7FF51548A.iiwgl&fg=555&bg=eee&p=WBDPVF'
            className='w-100'
            height={imgRef?.current?.height}
        ></iframe> */}
        </>
    )
};

export default AlignerVideoIframe;