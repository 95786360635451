import { USER_TYPE } from "../../../constants/common.constant";
import { TAGS_SHORT_NAME } from "./Utility";

export const getReportType = (data) => {
    if (data?.isAligner) {
        return 'aligner_report';
    } else if (data?.isImageAnnotation) {
        return 'image_annotation';
    } else if (data?.isThirdPartyReports) {
        return 'third_party_report';
    } else {
        return 'hygiene_report';
    }
}

const tagsKeysAndValue = [
    {
        key: 'is_cavity_access',
        value: 'cavities'
    },
    {
        key: 'is_tartar_access',
        value: 'tartar'
    },
    {
        key: 'is_gum_recession_access',
        value: 'gum recession'
    },
    {
        key: 'is_gum_swollen_access',
        value: 'gum swollen'
    },
    {
        key: 'is_broken_teeth_access',
        value: 'broken teeth'
    },
    {
        key: 'is_enamel_access',
        value: 'enamel'
    },
    {
        key: 'is_gaps_access',
        value: 'gaps'
    },
];

export const getTagsListArray = (data) => {
    const tagsListArray = [];
    tagsKeysAndValue.forEach((tag) => {
        if (data[tag.key]) {
            tagsListArray.push(tag.value);
        }
    });
    return tagsListArray;
}

export const checkTagsAccess = (tag, tagsAndAccess, userType) => {
    if (userType === USER_TYPE.ALIGNER) {
        return true;
    }

    const tagName = TAGS_SHORT_NAME[tag];
    let isTagMatched = false;

    switch (tagName) {
        case 'Cavities':
            return isTagMatched = tagsAndAccess.is_cavity_access;
        case 'Tartar':
            return isTagMatched = tagsAndAccess.is_tartar_access;
        case 'Gum Swollen':
            return isTagMatched = tagsAndAccess.is_gum_swollen_access;
        case 'Gum Recession':
            return isTagMatched = tagsAndAccess.is_gum_recession_access;
        case 'Enamel':
            return isTagMatched = tagsAndAccess.is_enamel_access;
        case 'Broken Teeth':
            return isTagMatched = tagsAndAccess.is_broken_teeth_access;
        case 'Gaps':
            return isTagMatched = tagsAndAccess.is_gaps_access;
        default:
            return isTagMatched;
    }
}