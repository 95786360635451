import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

export default function PositionedSnackbar(props) {
    const { severity, message, isOpenSnackbar, vertical = 'top', horizontal = 'center', autoHideDuration } = props;

    const handleClose = () => {
        props.onCloseSnackBar(false);
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={isOpenSnackbar}
            autoHideDuration={autoHideDuration ? autoHideDuration : 6000}
            onClose={handleClose}
            key={vertical + horizontal}
        >
            <Alert onClose={handleClose} variant="filled" severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}
