import React, { useEffect, useRef, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Box, CardHeader, Chip, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';

import { GetBucketFile } from '../../../Services/reportService';
import { GetShortName, TAGS_COLOR_CODE, TAGS_SHORT_NAME } from '../Dialog/Utility';
import ToothTable from '../Table/ToothTable';
import TeethChip from '../Chip/TeethChip';
import LegendsList from '../List/LegendsList';
import { API_RESPONSE, DEFAULT_BUCKET } from '../../../constants/common.constant';
import { getImageName, getImageSubFolder } from '../../../utility/common.utility';
import { checkTagsAccess, getTagsListArray } from '../Dialog/reportDialog.helper';

export default function ReviewerFeedbackCard({ title, image, isAnnotator, reportTagsData, feedbackTitle, feedback }) {

    const [selectedImageTags, setSelectedImageTags] = useState([]);
    const [imageTagsData, setImageTagsData] = useState();
    const [missingTeethData, setMissingTeethData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [reportImage, setReportImage] = useState('');
    const [isTagsFound, setIsTagsFound] = useState(false);
    const imgRef = useRef();
    const darkTeethOptionsData = ['white', 'normal', 'yellow/grey'];
    const poorAlignmentOptionsData = ['crooked teeth', 'teeth in front', 'teeth pushed back'];
    const bucketName = DEFAULT_BUCKET;
    const txtFilePath = 'toothlens-image-store/output';
    const jsonFilePath = 'toothlens-image-store/json_output';
    const storeValue = useSelector(store => store.userData);

    useEffect(() => {
        let missingTeeth = [];
        reportTagsData.forEach((item) => {
            missingTeeth = [...missingTeeth, ...item.missing_teeth];
        });
        setMissingTeethData(missingTeeth);

        const splitImage = getImageName(image);
        const imageSubFolder = getImageSubFolder(image);
        getImage(splitImage, imageSubFolder);
    }, []);

    const getImage = async (reportImg, imageSubFolder = 'input') => {
        setIsLoading(true);
        const imageData = await GetBucketFile({ fileName: reportImg, fileFolder: `${bucketName}/${imageSubFolder}` });
        setIsLoading(false);
        setReportImage(imageData.data.data);
        if (imgRef) {
            fetchData(reportImg);
        }
    }

    const getTagName = (key, data) => {
        return key === 'cavity' ? data.type ? data.type : 'C1' : GetShortName(key, '_')
    }

    const getTxtTags = async (reportImg) => {
        const txtData = await GetBucketFile({ fileName: `${reportImg.split('.')[0]}.txt`, fileFolder: txtFilePath });
        if (txtData?.data?.data !== API_RESPONSE.NO_DATA) {
            tagsCalculation(txtData);
        } else {
            setIsTagsFound(true);
        }
    }

    const handleJsonTags = (data, reportImg) => {
        if (data !== API_RESPONSE.NO_DATA) {
            getJsonTagsData(data);
            setImageTagsData(data);
        } else {
            isAnnotator ? getTxtTags(reportImg) : setIsTagsFound(true);;
        }
    }

    const fetchData = async (reportImg) => {
        try {
            const responseData = await Promise.all([
                GetBucketFile({ fileName: `${reportImg.split('.')[0]}.json`, fileFolder: jsonFilePath }),
                GetBucketFile({ fileName: `${reportImg.split('.')[0]}-reviewer.json`, fileFolder: jsonFilePath })
            ]);
            const [annotatorJsonData, reviewerJsonData] = responseData;
            if (isAnnotator) {
                const data = annotatorJsonData.data.data;
                handleJsonTags(data, reportImg);
            } else {
                const data = reviewerJsonData.data.data;
                handleJsonTags(data, reportImg);
            }
            setIsLoading(false);
        } catch (error) {
        }
    }

    async function getJsonTagsData(tagsData) {
        if (tagsData !== 'No Data') {
            const imageTags = selectedImageTags ? [...selectedImageTags] : [];
            const imgNaturalWidth = imgRef?.current?.naturalWidth;
            const imgNaturalHeight = imgRef?.current?.naturalHeight;
            const renderImageWidth = imgRef?.current?.width;
            const renderImageHeight = imgRef?.current?.height;
            const coordinatesData = [];
            for (let key in tagsData) {
                if (Array.isArray(tagsData[key]) && key !== 'missing_teeth') {
                    tagsData[key].forEach((item) => {
                        let { x, y, w, h } = item;
                        x = (x * renderImageWidth) / imgNaturalWidth;
                        y = (y * renderImageHeight) / imgNaturalHeight;
                        w = (w * renderImageWidth) / imgNaturalWidth;
                        h = (h * renderImageHeight) / imgNaturalHeight;
                        coordinatesData.push({ x, y, width: w, height: h });
                        const shortName = getTagName(key, item);
                        const colorCode = TAGS_COLOR_CODE[shortName];
                        imageTags.push({
                            name: shortName,
                            style: {
                                left: `${x}px`, top: `${y}px`,
                                width: `${w}px`, height: `${h}px`,
                                border: `3px solid ${colorCode}`, background: 'transparent'
                            },
                            nameStyle: {
                                position: 'relative',
                                top: '-2px',
                                left: '0',
                                background: colorCode,
                                color: colorCode
                            },
                        });
                    });
                }
            }
            setSelectedImageTags(imageTags)
        }
    }

    function tagsCalculation(tags) {
        const coordinates = [];
        const imgWidth = imgRef.current?.width;
        const imgHeight = imgRef.current?.height;
        const imageTags = selectedImageTags ? [...selectedImageTags] : [];

        const tagsData = tags.data.data.split('\n');
        tagsData.forEach((data, index) => {
            const splitCoordinates = data.split(' ').slice(1, 5);
            if (splitCoordinates.length > 1) {
                const { x, y, w, h } = {
                    x: Number(splitCoordinates[0]),
                    y: Number(splitCoordinates[1]),
                    w: Number(splitCoordinates[2]),
                    h: Number(splitCoordinates[3])
                };
                let x_min = (x - w / 2) * imgWidth;
                let x_max = (x + w / 2) * imgWidth;
                let y_min = (y - h / 2) * imgHeight;
                let y_max = (y + h / 2) * imgHeight;
                coordinates.push({
                    x: x_min,
                    y: y_min,
                    width: x_max - x_min,
                    height: y_max - y_min
                });
                const colorCode = Number(data.split(' ')[0]) === 1 ? '#5fbadd' : 'red';
                imageTags.push({
                    name: Number(data.split(' ')[0]) === 1 ? 'T' : 'C1',
                    style: {
                        left: `${x_min}px`, top: `${y_min}px`,
                        width: `${x_max - x_min}px`, height: `${y_max - y_min}px`,
                        border: `3px solid ${colorCode}`, background: 'transparent'
                    },
                    nameStyle: {
                        position: 'relative',
                        top: '-2px',
                        left: '0',
                        background: colorCode,
                        color: colorCode
                    },
                });
                setSelectedImageTags(imageTags)
            }
        });
    }

    const checkTagAccess = (name) => {
        return storeValue?.assigned_tags.find(item => {
            return TAGS_SHORT_NAME[GetShortName(item, ' ')] === TAGS_SHORT_NAME[name]
        });
    }

    return (
        !isLoading ? (
            <Card className='border'>
                <CardHeader
                    title={title}
                    className='border primary-bg text-white text-center'
                />
                <CardContent className='p-0'>
                    <div className='position-relative'>
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            width="500"
                            // height="435"
                            className='w-100'
                            image={reportImage}
                            ref={imgRef}
                        />
                        {
                            selectedImageTags.length > 0 && selectedImageTags.map((item, index) => (
                                checkTagsAccess(item.name, storeValue.tags_and_access, storeValue.user_type) && <div
                                    key={index}
                                    className="popup tag-name border-0"
                                    style={item.style}
                                >
                                    <div className='drag-tag-box h-100' style={{ border: item.style.border }}>
                                        <div className='d-inline-flex ps-1 font-size-14 popup-header' style={item.nameStyle}>
                                            {/* <span className='text-white text-capitalize'>{item.name}</span> */}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                    <div className='p-3'>
                        {/* <p><b>NOTE:</b> {isTagsFound ? 'Image not reviewed!' : 'Image reviewed!'}</p> */}

                        {/* Legend list */}
                        <div className='border-bottom mb-2'>
                            <LegendsList title={title} tagsList={getTagsListArray(storeValue.tags_and_access)} isCheckBoxRequired={false} />
                        </div>

                        <div className='border-bottom mb-2 py-2'>
                            <b>{feedbackTitle}:</b>
                            <p>{feedback ? feedback : 'No feedback found!'}</p>
                        </div>

                        <div className='border-bottom mb-2 py-2'>
                            <b>Image useable:</b>
                            <Chip className='mx-3 py-3'
                                color={imageTagsData?.image_useable?.is_image_useable ? 'success' : 'error'}
                                label={imageTagsData?.image_useable?.is_image_useable ? 'Yes' : 'No'} />
                        </div>
                        <div className='border-bottom mb-2 py-2'>
                            <b>Missing teeth:</b>
                            <div>
                                <ToothTable
                                    missing_teeth={missingTeethData}
                                    selectTooth={() => { }}
                                    idName={isAnnotator ? 'annotatorTeeth' : 'reviewerTeeth'}
                                />
                            </div>
                        </div>
                        <div className='border-bottom mb-2 py-2'>
                            <b>Gaps in front teeth:</b>
                            <div>
                                <div className='font-size-14'>Upper teeth:
                                    <span
                                        className={`${imageTagsData?.gaps_in_front_teeth?.upper_teeth ? 'text-danger' : 'text-success'}  fw-bold ms-1`}
                                    >
                                        {imageTagsData?.gaps_in_front_teeth?.upper_teeth ? 'Yes' : 'No'}
                                    </span>
                                </div>
                                <div className='font-size-14'>Lower teeth:
                                    <span className={`${imageTagsData?.gaps_in_front_teeth?.lower_teeth ? 'text-danger' : 'text-success'}  fw-bold ms-1`}>
                                        {imageTagsData?.gaps_in_front_teeth?.lower_teeth ? 'Yes' : 'No'}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='border-bottom mb-2 py-2'>
                            <b>Poor alignment:</b>
                            <div>
                                <div className='font-size-14'>Upper teeth:
                                    <TeethChip
                                        chipsData={poorAlignmentOptionsData}
                                        selectedValue={imageTagsData?.poor_alignment?.upper_teeth ? imageTagsData?.poor_alignment?.upper_teeth : []} />
                                </div>
                                <div className='font-size-14'>Lower teeth:
                                    <TeethChip
                                        chipsData={poorAlignmentOptionsData}
                                        selectedValue={imageTagsData?.poor_alignment?.lower_teeth ? imageTagsData?.poor_alignment?.lower_teeth : []} />
                                </div>
                            </div>
                        </div>
                        <div className='border-bottom mb-2 py-2'>
                            <b>Dark teeth:</b>
                            <div>
                                <div className='font-size-14'>Upper teeth:
                                    <TeethChip
                                        chipsData={darkTeethOptionsData}
                                        selectedValue={imageTagsData?.dark_teeth?.upper_teeth ? [imageTagsData?.dark_teeth?.upper_teeth] : []} />
                                </div>
                                <div className='font-size-14'>Lower teeth:
                                    <TeethChip
                                        chipsData={darkTeethOptionsData}
                                        selectedValue={imageTagsData?.dark_teeth?.lower_teeth ? [imageTagsData?.dark_teeth?.lower_teeth] : []} />
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        ) : (
            <Box sx={{ pr: 2 }}>
                <Skeleton variant="rectangular" width={510} height={518} />
                <Skeleton />
                <Skeleton width="60%" />
            </Box>
        )

    );
}