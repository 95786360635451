import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import AnnotatorFilter from '../Autocomplete/AnnotatorFilter';
import { assignFolderAPI } from '../../../Services/admin.service';

export default function AssignedFolderDialog({ isDialogOpen, handleCloseDialog }) {
    const [open, setOpen] = useState(isDialogOpen);
    const [bucket, setBucket] = useState();
    const [folder, setFolder] = useState();
    const [annotator, setAnnotator] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        handleCloseDialog(false);
    };

    const getFiltersData = (data) => {
        setAnnotator(data._id);
    }

    const handleSubmit = async () => {
        if (bucket && folder && annotator) {
            setIsLoading(true);
            try {
                const result = await assignFolderAPI({ bucket, folder, annotator });
                handleClose(result);
            } catch (error) {
            }
            setIsLoading(false);
        }
    }

    return (
        <Dialog maxWidth="md" open={open} onClose={handleClose}>
            <DialogTitle>Assign Folder</DialogTitle>
            <DialogContent className='p-3'>
                <DialogContentText>
                    To Assign folders to an Annotator
                </DialogContentText>
                <FormControl fullWidth className='my-2'>
                    <InputLabel id="demo-simple-select-label">Bucket</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="bucket"
                        label="Bucket"
                        fullWidth
                        value={bucket}
                        onChange={(event) => setBucket(event.target.value)}
                    >
                        <MenuItem value="toothlens-image-store">toothlens-image-store</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    margin="dense"
                    id="folderName"
                    label="Folder name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    className='mb-3'
                    value={folder}
                    onChange={(event) => setFolder(event.target.value)}
                />
                <AnnotatorFilter
                    getFiltersData={getFiltersData}
                    // getLoadingState={setIsLoading}
                    getFilterState={() => {}}
                    width="100%"
                    label="Annotators"
                />
            </DialogContent>
            <DialogActions className='p-3'>
                <Button onClick={handleClose} variant='contained' color='error'>Cancel</Button>
                <Button onClick={handleSubmit} variant='contained'>
                    {
                        isLoading ?
                            <CircularProgress className='text-white' style={{ width: '25px', height: '25px' }} /> : 'Submit'
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
}