import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { Alert, ListItemButton, ListItemIcon, Snackbar } from '@mui/material';
import Crop32Icon from '@mui/icons-material/Crop32';
import { Col, Row } from 'react-bootstrap';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import Draggable from 'react-draggable';


import ImagePlaceholder from '../../../assets/image-placeholder1.svg';
import SwipeableCard from '../Cards/SwipeableCard';
import { GetShortName, STATUS_VALUE, VDC_TAGS_COLOR_CODE, toTitleCase } from './Utility';
import { GetVDCBucketFile, StoreVdcJsonFile, reportImageUploadService } from '../../../Services/reportService';
import LegendsListVDC from '../List/LegendsListVDC';
import { dragElement } from './common/dragAndDrop';
import './Dialog.css';

let imageTagsData = [];

export default function AlertDialogSlide(props) {
    const [open, setOpen] = useState(false);
    // const { parentFolder, subFolder, imageName } = useParams();
    const paramsData = [...window.location.pathname.split('/').filter(Boolean).slice(1)];
    const imageName = paramsData.splice(paramsData.length - 1, 1)[0];
    const fileFolder = paramsData.join('/');
    const jsonFileName = `${paramsData.slice(1).join(':')}:${imageName.split('.')[0]}.json`;

    let rectangles = [];
    const location = useLocation();
    const [locationData, setLocationData] = useState(location?.state?.data);
    const [rectanglesData, setRectanglesData] = useState([]);
    const [reportImagesType, setReportImagesType] = useState(['front_teeth', 'upper_jaw', 'lower_jaw', 'left_side', 'right_side']);
    const [activeImage, setActiveImage] = useState();
    const [imageAngle, setImageAngle] = useState();
    const [editAndDeleteDropdown, setEditAndDeleteDropdown] = useState();
    const [tagIndex, setTagIndex] = useState();

    // DOM elements
    const [screenshot, setScreenShot] = useState();
    const [marquee, setMarquee] = useState();
    const [boxes, setBoxes] = useState();
    const [imageTags, setImageTags] = useState({ zIndex: '9', display: 'none' });
    const [selectedImageTags, setSelectedImageTags] = useState([]);
    const [isShowSelectCategoryCard, setIsShowSelectCategoryCard] = useState(false);
    const [stepsData, setStepsData] = useState();
    const [tagsObj, setTagsObj] = useState();
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(true);
    const [snackbarData, setSnackbarData] = useState(null);
    const [contrast, setContrast] = useState(100)
    const [brightness, setBrightness] = useState(100);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    // ref
    const imgRef = useRef();
    const marqueeRef = useRef();
    const screenshotRef = useRef();

    // Temp variables
    let startX = 0;
    let startY = 0;
    const marqueeRect = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        window.close();
        setOpen(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarData(null);
    }

    const trackPos = (e, data) => {
        // console.log(e.clientX)
        setPosition({ x: data.x, y: data.y });
    };


    useEffect(() => {
        GetVDCBucketFile({ fileName: imageName, fileFolder }).then((resData) => {
            if (resData.data.data !== 'No Data') {
                setActiveImage(resData.data.data);
                setTimeout(getImageCoordinates);
                reportImageUploadService({ imageUrl: resData.data.data, fileName: imageName, fileFolder: fileFolder.split("/").slice(1).join("/") }).then((res) => {
                    // setActiveImage(res.data)
                }).catch((err) => {
                    console.log("err", err);
                })
            } else {
                setIsSnackbarOpen(true);
                setSnackbarData({ message: 'Image not found', severity: 'error' });
            }
        });
    }, []);

    useEffect(() => {
        const domValue = document.querySelector.bind(document);
        // setActiveImage(setActiveReportImages(0));
        setScreenShot(domValue('#screenshot'));
        // setMarquee(domValue('#marquee'));
        setBoxes(domValue('#boxes'));
        // setCanvas(document.getElementById('canvas'));

        marqueeRef?.current?.classList.add('hide');
        if (boxes) {
            redraw();
            // getImageCoordinates();
        }
    }, [marqueeRef, screenshot]);

    function drawRegion() {
        screenshot?.addEventListener('pointerdown', startDrag);
    }

    function startDrag(ev) {
        if (!boxes.innerHTML) {
            rectangles = [];
        }
        if (ev.button === 1) {
            const rect = hitTest(ev.layerX, ev.layerY);
            if (rect) {
                rectangles.splice(rectangles.indexOf(rect), 1);
                redraw();
            }
            return;
        }
        setTagIndex();
        window.addEventListener('pointerup', stopDrag);
        screenshot.addEventListener('pointermove', moveDrag);
        marqueeRef.current.classList.remove('hide');
        startX = ev.layerX;
        startY = ev.layerY;
        drawRect(marqueeRef, startX, startY, 0, 0);
    }

    function stopDrag(ev) {
        marqueeRef.current.classList.add('hide');
        window.removeEventListener('pointerup', stopDrag);
        screenshot.removeEventListener('pointermove', moveDrag);
        // ev.stopPropagation();
        // ev.preventDefault();
        if (ev.target === screenshot && marqueeRect.width && marqueeRect.height) {
            const lastTagValue = imageTagsData[imageTagsData.length - 1];
            if (marqueeRect.x !== lastTagValue?.x && marqueeRect.y !== lastTagValue?.y) {
                rectangles.push(Object.assign({}, marqueeRect));
                imageTagsData.push(Object.assign({}, marqueeRect));
                setRectanglesData(imageTagsData);
                redraw();
                setIsShowSelectCategoryCard(true);
                setImageTags({ left: `${marqueeRect.x}px`, top: `${marqueeRect.y + marqueeRect.height}px`, display: 'block', zIndex: '3' });
                document.getElementById('draw').classList.remove('pointers');
                // Calling drag element
                // setTimeout(() => {
                //     dragElement('select-tags-card', 'select-tags-card-header');
                // }, 100);
            }
        }
    }

    function moveDrag(ev) {
        let x = ev.layerX;
        let y = ev.layerY;
        let width = startX - x;
        let height = startY - y;
        if (width < 0) {
            width *= -1;
            x -= width;
        }
        if (height < 0) {
            height *= -1;
            y -= height;
        }
        Object.assign(marqueeRect, { x, y, width, height });
        drawRect(marqueeRef.current, marqueeRect);
    }

    function hitTest(x, y) {
        return rectangles.find(rect => (
            x >= rect.x &&
            y >= rect.y &&
            x <= rect.x + rect.width &&
            y <= rect.y + rect.height
        ));
    }

    function redraw() {
        boxes.innerHTML = '';
        rectangles.forEach((data) => {
            boxes.appendChild(drawRect(
                document.createElementNS("http://www.w3.org/2000/svg", 'rect'), data
            ));
        });
    }

    function drawRect(rect, data) {
        const { x, y, width, height } = data;
        if (x && y && width && height) {
            rect?.setAttributeNS(null, 'width', width);
            rect?.setAttributeNS(null, 'height', height);
            rect?.setAttributeNS(null, 'x', x);
            rect?.setAttributeNS(null, 'y', y);
            rect?.addEventListener('click', (event) => {
                // event.stopPropagation();
                // event.preventDefault();
                // console.log('rect', event.getAttributesNS('http://www.w3.org/2000/svg', 'width'));
            })
            return rect;
        }
    }


    function clearRect() {
        boxes.innerHTML = '';
        rectangles = [];
        setRectanglesData([]);
        setSelectedImageTags([]);
        setImageTags({ display: 'none' });
        setIsShowSelectCategoryCard(false);
        imageTagsData = [];
    }

    function getSelectedTags(tag) {
        if (tag?.steps1) {
            const rectData = rectanglesData[rectanglesData.length - 1];
            const tags = [...selectedImageTags];
            if (tagIndex !== undefined) {
                tags[tagIndex].name = `${tag.steps1}/${tag.steps2}/${tag.steps3}`;
                tags[tagIndex].steps1 = tag.steps1;
                tags[tagIndex].steps2 = tag.steps2;
                tags[tagIndex].steps3 = tag.steps3;
                tags[tagIndex].style = {
                    left: `${rectanglesData[tagIndex].x}px`, top: `${rectanglesData[tagIndex].y}px`,
                    width: `${rectanglesData[tagIndex].width}px`, height: `${rectanglesData[tagIndex].height}px`, border: `3px solid ${tag.color}`, background: 'transparent',
                };
                tags[tagIndex].nameStyle = { position: 'relative', top: '-45px', left: '-25px', background: tag.color, color: tag.color };
            } else {
                tags.push({
                    name: `${tag.steps1}/${tag.steps2}/${tag.steps3}`,
                    style: {
                        left: `${rectData.x}px`, top: `${rectData.y}px`,
                        width: `${rectData.width}px`, height: `${rectData.height}px`, border: `3px solid ${tag.color}`, background: 'transparent',
                    },
                    nameStyle: { position: 'relative', top: '-3px', left: '0', background: tag.color, color: tag.color },
                    steps1: tag.steps1,
                    steps2: tag.steps2,
                    steps3: tag.steps3
                });
            }
            setSelectedImageTags(tags);
        } else {
            imageTagsData.splice(imageTagsData.length - 1, 1);
        };
        setTagIndex();
        setStepsData();
        setImageTags({ display: 'none' });
        setIsShowSelectCategoryCard(false);
        document.getElementById('draw').classList.add('pointers');
        boxes.innerHTML = '';
    }

    function dragAndResizeBox(ev, item, index, parentClassName, subClassName) {
        ev.target.style.border = `3px dotted ${item.nameStyle.background}`;
        draggableDiv(index, parentClassName, subClassName, true);
        ev.stopPropagation();
        const elem = document.getElementById('drawContainer');
        elem.addEventListener('click', () => {
            ev.target.style.border = item.style.border;
            elem.removeEventListener('click', () => { }, false);
        });
        ev.target.addEventListener('mousedown', (event) => {
            console.log('event', event);
            // mousedown(event, ev, item)
        });
    }

    function initDragElement(tagIndex, parentClassName, subClassName, isTagBox = false) {
        var pos1 = 0,
            pos2 = 0,
            pos3 = 0,
            pos4 = 0;
        var popups = document.getElementsByClassName(parentClassName);
        var elmnt = null;
        var currentZIndex = 100; //TODO reset z index when a threshold is passed

        for (var i = 0; i < popups.length; i++) {
            var popup = popups[i];
            var header = getHeader(popup);

            popup.onmousedown = function () {
                this.style.zIndex = "" + ++currentZIndex;
            };

            if (header) {
                header.parentPopup = popup;
                header.onmousedown = dragMouseDown;
            }
        }

        function dragMouseDown(e) {
            elmnt = this.parentPopup;
            elmnt.style.zIndex = "" + ++currentZIndex;

            e = e || window.event;
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            console.log('pos3, pos4', pos3, pos4);
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            if (!elmnt) {
                return;
            }

            e = e || window.event;
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = elmnt.offsetTop - pos2 + "px";
            elmnt.style.left = elmnt.offsetLeft - pos1 + "px";

        }

        function closeDragElement() {
            /* stop moving when mouse button is released:*/
            document.onmouseup = null;
            document.onmousemove = null;

            if (isTagBox) {
                imageTagsData[tagIndex].x = Number(elmnt.style.left.split('px')[0]);
                imageTagsData[tagIndex].y = Number(elmnt.style.top.split('px')[0]);
                console.log('elmnt.style', elmnt.style.left, elmnt.style.top);
            }

        }

        function getHeader(element) {
            var headerItems = element.getElementsByClassName(subClassName);

            if (headerItems.length === 1) {
                return headerItems[0];
            }

            return null;
        }
    }

    function initResizeElement(tagIndex) {
        var popups = document.getElementsByClassName("popup");
        var element = null;
        var startX, startY, startWidth, startHeight;

        for (var i = 0; i < popups.length; i++) {
            var p = popups[i];

            var right = document.createElement("div");
            right.className = "resizer-right";
            p.appendChild(right);
            right.addEventListener("mousedown", initDrag, false);
            right.parentPopup = p;

            var bottom = document.createElement("div");
            bottom.className = "resizer-bottom";
            p.appendChild(bottom);
            bottom.addEventListener("mousedown", initDrag, false);
            bottom.parentPopup = p;

            var both = document.createElement("div");
            both.className = "resizer-both";
            p.appendChild(both);
            both.addEventListener("mousedown", initDrag, false);
            both.parentPopup = p;
        }

        function initDrag(e) {
            element = this.parentPopup;

            startX = e.clientX;
            startY = e.clientY;
            startWidth = parseInt(
                document.defaultView.getComputedStyle(element).width,
                10
            );
            startHeight = parseInt(
                document.defaultView.getComputedStyle(element).height,
                10
            );
            document.documentElement.addEventListener("mousemove", doDrag, false);
            document.documentElement.addEventListener("mouseup", stopDrag, false);
        }

        function doDrag(e) {
            element.style.width = startWidth + e.clientX - startX + "px";
            element.style.height = startHeight + e.clientY - startY + "px";
        }

        function stopDrag() {
            document.documentElement.removeEventListener("mousemove", doDrag, false);
            document.documentElement.removeEventListener("mouseup", stopDrag, false);
            // updating image tags
            const getElementStyle = element.style;
            imageTagsData[tagIndex].x = Number(getElementStyle.left.split('px')[0]);
            imageTagsData[tagIndex].y = Number(getElementStyle.top.split('px')[0]);
            imageTagsData[tagIndex].width = Number(getElementStyle.width.split('px')[0]);
            imageTagsData[tagIndex].height = Number(getElementStyle.height.split('px')[0]);
            console.log('X, Y, Width, Height', getElementStyle.left, getElementStyle.top, getElementStyle.width, getElementStyle.height);
        }
    }

    function draggableDiv(tagIndex, parentClassName, subClassName, isTagBox) {
        initDragElement(tagIndex, parentClassName, subClassName, isTagBox);
        initResizeElement(tagIndex);
    }

    function getImageCoordinates() {
        const imgWidth = imgRef?.current?.width;
        const imgHeight = imgRef?.current?.height;

        let coordinates = [];
        const imageTags = [...selectedImageTags];

        GetVDCBucketFile({ fileName: jsonFileName, fileFolder: 'toothlens-image-store/vdc-demo' })
            .then((tag) => {
                if (tag?.data?.data !== 'No Data') {
                    const tagsData = tag.data.data;
                    const imgNaturalWidth = imgRef.current.naturalWidth;
                    const imgNaturalHeight = imgRef.current.naturalHeight;
                    const coordinatesData = [];
                    for (let key in tagsData) {
                        if (Array.isArray(tagsData[key].teeth)) {
                            // eslint-disable-next-line no-loop-func
                            tagsData[key].teeth.forEach((item) => {
                                let [x, y, w, h] = item.pixel;
                                x = (x * imgWidth) / imgNaturalWidth;
                                y = (y * imgHeight) / imgNaturalHeight;
                                w = (w * imgWidth) / imgNaturalWidth;
                                h = (h * imgHeight) / imgNaturalHeight;
                                coordinatesData.push({ x, y, width: w, height: h });
                                const tagColor = key === 'cavity' ? toTitleCase(key) : GetShortName(key, '_');
                                const colorCode = VDC_TAGS_COLOR_CODE[tagColor];
                                imageTags.push({
                                    name: `${key === 'cavity' ? toTitleCase(key)
                                        : GetShortName(key, '_')}${item.number ?
                                            '/' + item.number : '/' + item.quadrant}/${GetShortName(item.status, ' ')}`,
                                    style: {
                                        left: `${x}px`, top: `${y}px`,
                                        width: `${w}px`, height: `${h}px`,
                                        border: `3px solid ${colorCode}`, background: 'transparent'
                                    },
                                    nameStyle: {
                                        position: 'relative',
                                        top: '-3px',
                                        left: '0',
                                        background: colorCode,
                                        color: colorCode
                                    },
                                    steps1: key === 'cavity' ? toTitleCase(key) : GetShortName(key, '_'),
                                    steps2: item.number ? item.number : item.quadrant,
                                    steps3: GetShortName(item.status, ' ')
                                });
                                setSelectedImageTags(imageTags);
                                setRectanglesData(coordinatesData);
                                imageTagsData = coordinatesData;
                            });
                        }
                    }
                } else {
                    getImageTags(imgWidth, imgHeight);
                }
            })
            .catch((err) => {
                getImageTags(imgWidth, imgHeight);
            })
    }

    function getImageTags(imgWidth, imgHeight) {
        let coordinates = [];
        const imageTags = [...selectedImageTags];
        GetVDCBucketFile({ fileName: `${imageName.split('.')[0]}.txt`, fileFolder })
            // .then(r => r.text())
            .then(tags => {
                const tagsData = tags.data.data.split('\n');
                tagsData.forEach((data, index) => {
                    const splitCoordinates = data.split(' ').slice(1, 5);
                    if (splitCoordinates.length > 1) {
                        const { x, y, w, h } = {
                            x: Number(splitCoordinates[0]),
                            y: Number(splitCoordinates[1]),
                            w: Number(splitCoordinates[2]),
                            h: Number(splitCoordinates[3])
                        };
                        const { x_min, x_max, y_min, y_max } = getTagsCoordinates({ x, y, w, h }, imgWidth, imgHeight);
                        coordinates.push({
                            x: x_min,
                            y: y_min,
                            width: x_max - x_min,
                            height: y_max - y_min
                        });
                        const colorCode = Number(data.split(' ')[0]) === 1 ? '#FF7A59' : '#FD636B';
                        imageTags.push({
                            name: Number(data.split(' ')[0]) === 1 ? 'PB' : 'Cavity',
                            style: {
                                left: `${x_min}px`, top: `${y_min}px`,
                                width: `${x_max - x_min}px`, height: `${y_max - y_min}px`,
                                border: `3px solid ${colorCode}`, background: 'transparent'
                            },
                            nameStyle: {
                                position: 'relative',
                                top: '-3px',
                                left: '0',
                                background: colorCode,
                                color: colorCode
                            },
                            steps1: Number(data.split(' ')[0]) ? 'PB' : 'Cavity',
                            steps2: '',
                            steps3: ''
                        });
                        setSelectedImageTags(imageTags);
                        setRectanglesData(coordinates);
                        imageTagsData = coordinates;
                    }
                });
                saveImageTags(imageTags, true);
            });
    }

    function getTagsCoordinates({ x, y, w, h }, imgWidth, imgHeight) {
        let x_min = (x - w / 2) * imgWidth;
        let x_max = (x + w / 2) * imgWidth;
        let y_min = (y - h / 2) * imgHeight;
        let y_max = (y + h / 2) * imgHeight;

        return {
            x_min,
            x_max,
            y_min,
            y_max
        }
    }

    function handleEditAndDeleteTag(event, index) {
        event.stopPropagation();
        setTagIndex(index);
        const eleStyle = { ...imageTagsData[index] };
        eleStyle.top = `${eleStyle.y + 25}px`;
        eleStyle.left = `${eleStyle.x}px`;
        setEditAndDeleteDropdown(eleStyle);
        imgRef.current.addEventListener('click', () => {
            setEditAndDeleteDropdown();
        });

    }

    function handleEditTag() {
        setStepsData(selectedImageTags[tagIndex]);
        setIsShowSelectCategoryCard(true);
        setEditAndDeleteDropdown();
    }

    function handleDeleteTag() {
        imageTagsData.splice(tagIndex, 1);
        const imageTags = [...selectedImageTags];
        imageTags.splice(tagIndex, 1);
        setSelectedImageTags(imageTags);
        setEditAndDeleteDropdown();
    }

    function saveImageTags(imageTags = [], isInitialSave = false) {
        window.opener?.postMessage(
            {
                event_id: 'SaveImage',
                data: {
                    imagename: `/${fileFolder}/${imageName}`
                }
            },
            "*"
        );

        const jsonData = {
            image_name: imageName,
            cavity: {},
            defective_restoration: {},
            broken_tooth: {},
            plaque_buildup: {},
            gum_inflammation: {},
            isUseable: true
        }

        imageTags = imageTags.length > 0 ? imageTags : selectedImageTags;
        const formattedJsonData = formatJsonData(jsonData, imageTags)
        // console.log('formattedJsonData', formattedJsonData);
        if (formattedJsonData) {
            StoreVdcJsonFile(formattedJsonData, 'toothlens-image-store', 'vdc-demo', jsonFileName)
                .then((response) => {
                    if (!isInitialSave) {
                        setSnackbarData({ message: 'Successfully stored data', severity: 'success' });
                        setTimeout(() => {
                            window.close();
                        }, 1000);
                    }
                })
                .catch((err) => {
                    console.log('err', err);
                })
        }
        return;
    }

    function formatJsonData(jsonData, imageTags) {
        const imgNaturalWidth = imgRef.current.naturalWidth;
        const imgNaturalHeight = imgRef.current.naturalHeight;
        const renderImageWidth = imgRef.current.width;
        const renderImageHeight = imgRef.current.height;
        let isValidData = true;

        imageTags.forEach((item, index) => {
            let { x, y, width: w, height: h } = imageTagsData[index];
            // console.log((x*480)/752, (y*328)/512, (w*480)/752, (h*328)/512);
            x = (x * imgNaturalWidth) / renderImageWidth;
            y = (y * imgNaturalHeight) / renderImageHeight;
            w = (w * imgNaturalWidth) / renderImageWidth;
            h = (h * imgNaturalHeight) / renderImageHeight;
            let teethData = [];
            switch (item.steps1) {
                case 'Cavity':
                    teethData = jsonData.cavity?.teeth?.length > 0 ? [...jsonData.cavity.teeth] : [];
                    teethData.push({
                        number: item.steps2,
                        pixel: [x, y, w, h],
                        status: STATUS_VALUE[item.steps3],
                        quadrant: ""
                    });
                    jsonData.cavity.teeth = teethData;
                    jsonData.cavity.images = "";
                    break;
                case 'DR':
                    teethData = jsonData.defective_restoration?.teeth?.length > 0 ? [...jsonData.defective_restoration.teeth] : [];
                    teethData.push({
                        number: item.steps2,
                        pixel: [x, y, w, h],
                        status: STATUS_VALUE[item.steps3],
                        quadrant: ""
                    });
                    jsonData.defective_restoration.teeth = teethData;
                    jsonData.defective_restoration.images = "";
                    break;
                case 'BT':
                    teethData = jsonData.broken_tooth?.teeth?.length > 0 ? [...jsonData.broken_tooth.teeth] : [];
                    teethData.push({
                        number: item.steps2,
                        pixel: [x, y, w, h],
                        status: STATUS_VALUE[item.steps3],
                        quadrant: ""
                    });
                    jsonData.broken_tooth.teeth = teethData;
                    jsonData.broken_tooth.images = "";
                    break;
                case 'PB':
                    teethData = jsonData.plaque_buildup?.teeth?.length > 0 ? [...jsonData.plaque_buildup.teeth] : [];
                    teethData.push({
                        number: "",
                        pixel: [x, y, w, h],
                        status: STATUS_VALUE[item.steps3],
                        quadrant: item.steps2
                    })
                    jsonData.plaque_buildup.teeth = teethData;
                    jsonData.plaque_buildup.images = "";
                    break;

                case 'GI':
                    teethData = jsonData.gum_inflammation?.teeth?.length > 0 ? [...jsonData.gum_inflammation.teeth] : [];
                    teethData.push({
                        number: "",
                        pixel: [x, y, w, h],
                        status: STATUS_VALUE[item.steps3],
                        quadrant: item.steps2
                    })
                    jsonData.gum_inflammation.teeth = teethData;
                    jsonData.gum_inflammation.images = "";
                    break;

                default:
                    break;
            }
        });
        return isValidData ? jsonData : false;
    }

    return (
        <div className='container-fluid'>
            <div>
                <Snackbar
                    open={snackbarData?.message ? true : false}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert onClose={handleSnackbarClose} variant="filled" severity={snackbarData?.severity} sx={{ width: '100%' }}>
                        {snackbarData?.message}
                    </Alert>
                </Snackbar>
                <div className='bg-dark text-white'>
                    <Row>
                        <Col xs={1} className="text-center">
                            <ListItemButton className='d-flex flex-column' onClick={drawRegion}>
                                <ListItemIcon>
                                    <Crop32Icon className='text-white font-size-35' />
                                </ListItemIcon>
                                <div className='font-size-12'>Rectangle</div>
                            </ListItemButton>
                        </Col>
                        <Col xs={1} className="text-center">
                            <ListItemButton className='d-flex flex-column' onClick={clearRect}>
                                <ListItemIcon>
                                    <ClearIcon className='text-white font-size-35' />
                                </ListItemIcon>
                                <div className='font-size-12'>Clear</div>
                            </ListItemButton>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='row'>
                <div className={`col-8`} style={{ height: `${window.innerHeight - 190}px`, overflow: 'auto' }}>
                    <div
                        className='position-relative'
                        id="drawContainer"
                    >
                        <img
                            // width="752"
                            // height="512"
                            className='w-100'
                            src={activeImage ? `${activeImage}` : ImagePlaceholder}
                            id="screenshot"
                            alt="report"
                            draggable="false"
                            ref={imgRef}
                            style={{ filter: `contrast(${contrast}%) brightness(${brightness}%)` }}
                        />

                        <svg height={imgRef.current?.height}
                            // viewBox={`0 0 752 512`}
                            id="draw"
                            className='pointers w-100'
                            xmlns="http://www.w3.org/2000/svg">
                            <rect id="marquee" x="450" y="420" width="150" height="150" ref={marqueeRef} />
                            <g id="boxes"></g>
                        </svg>

                        {
                            selectedImageTags.map((item, index) => (
                                <div
                                    key={index}
                                    className="popup tag-name"
                                    style={item.style}
                                    onClick={(event) => dragAndResizeBox(event, item, index, 'popup', 'popup-header')}
                                >
                                    {/* <span>{item.name} <ArrowDropDownIcon /></span> */}
                                    {/* <span className="p-1 popup-header"
                                            style={item.nameStyle}>{item.name}</span>
                                        <span className="p-1 cursor-pointer" style={item.nameStyle}>
                                            <ArrowDropDownIcon />
                                        </span> */}
                                    <div className='d-inline-flex ps-1 font-size-14 popup-header' style={item.nameStyle}
                                        onClick={($event) => handleEditAndDeleteTag($event, index)}>
                                        <span className='text-white'>{item.name}</span>
                                        <span>
                                            <ArrowDropDownIcon className='primary-bg text-white'
                                                style={{ width: '20px', background: item.nameStyle.background, height: 'auto' }} />
                                        </span>
                                    </div>
                                </div>
                            ))
                        }

                        {
                            editAndDeleteDropdown && (
                                <div className='position-absolute edit-and-delete-tag' style={editAndDeleteDropdown}>
                                    <Button className='p-0 font-size-13 text-capitalize cursor-pointer justify-content-start primary-black'
                                        onClick={handleEditTag}>
                                        <ModeEditIcon className='edit-icon me-1' /> Edit</Button>
                                    <Button className='p-0  font-size-13 text-capitalize cursor-pointer justify-content-start primary-black'
                                        onClick={handleDeleteTag}>
                                        <DeleteIcon className='edit-icon me-1' /> Delete</Button>
                                </div>
                            )
                        }

                        {
                            isShowSelectCategoryCard && (
                                <Draggable onDrag={(e, data) => trackPos(e, data)}>
                                    <div className='position-absolute select-tags-card'
                                        style={{ top: `${imageTagsData[imageTagsData.length - 1].y}px` }}>
                                        <div className='select-tags-card-header position-relative cursor-move'>
                                            <SwipeableCard onSelectedTags={getSelectedTags} stepsData={stepsData} tagIndex={tagIndex} />
                                        </div>
                                        {/* <div>
                                            x: {position.x.toFixed(0)}, y: {position.y.toFixed(0)}
                                        </div> */}
                                    </div>
                                </Draggable>
                            )
                        }
                    </div>
                </div>
                <div className='col-4'>
                    {/* Adjust Contrast and Brightness */}
                    <div className="d-flex align-items-center mt-3">
                        <span className="me-4 font-size-14 fw-bold">Adjust Contrast : </span>
                        <input type="range" min="0" max="200" value={contrast}
                            onChange={(event) => { setContrast(event.target.value) }} />
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="me-3 font-size-14 fw-bold">Adjust Brightness : </span>
                        <input type="range" min="0" max="200" value={brightness}
                            onChange={(event) => { setBrightness(event.target.value) }} />
                    </div>
                    <LegendsListVDC />
                    <DialogActions className='mb-3'>
                        <Button variant="contained" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" onClick={saveImageTags}>Save</Button>
                    </DialogActions>
                    <hr />
                </div>
            </div>
            {/* <div className='row position-absolute bottom-0'>
                <div className='col-12'>
                    <DialogActions className='mb-3'>
                        <Button variant="contained" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" onClick={saveImageTags}>Save</Button>
                    </DialogActions>
                </div>
            </div> */}
        </div>
    );
}