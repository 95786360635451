import React, { useEffect } from 'react';

const ToothTable = ({ selectTooth, missing_teeth, idName = 'teeth' }) => {

    const centeredTeethCssClass = idName === 'teeth' ? 'teeth-selected-color-secondary' : '';
    useEffect(() => {
        if (missing_teeth) {
            missing_teeth.forEach((item) => {
                document.getElementById(`${idName}${item}`)?.classList?.add('teeth-selected-color');
            });
        }
    }, []);

    return (
        <table className='select-tooth-table'>
            <tbody>
                <tr>
                    <td id={`${idName}1`} onClick={($event) => selectTooth($event)}>1</td>
                    <td id={`${idName}2`} onClick={($event) => selectTooth($event)}>2</td>
                    <td id={`${idName}3`} onClick={($event) => selectTooth($event)}>3</td>
                    <td id={`${idName}4`} onClick={($event) => selectTooth($event)}>4</td>
                    <td id={`${idName}5`} onClick={($event) => selectTooth($event)}>5</td>
                    <td id={`${idName}6`} onClick={($event) => selectTooth($event)}>6</td>
                    <td id={`${idName}7`} onClick={($event) => selectTooth($event)}>7</td>
                    <td id={`${idName}8`} className={`${centeredTeethCssClass}`} onClick={($event) => selectTooth($event)}>8</td>
                    <td id={`${idName}9`} className={`${centeredTeethCssClass}`} onClick={($event) => selectTooth($event)}>9</td>
                    <td id={`${idName}10`} onClick={($event) => selectTooth($event)}>10</td>
                    <td id={`${idName}11`} onClick={($event) => selectTooth($event)}>11</td>
                    <td id={`${idName}12`} onClick={($event) => selectTooth($event)}>12</td>
                    <td id={`${idName}13`} onClick={($event) => selectTooth($event)}>13</td>
                    <td id={`${idName}14`} onClick={($event) => selectTooth($event)}>14</td>
                    <td id={`${idName}15`} onClick={($event) => selectTooth($event)}>15</td>
                    <td id={`${idName}16`} onClick={($event) => selectTooth($event)}>16</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td id={`${idName}A`} onClick={($event) => selectTooth($event)}>A</td>
                    <td id={`${idName}B`} onClick={($event) => selectTooth($event)}>B</td>
                    <td id={`${idName}C`} onClick={($event) => selectTooth($event)}>C</td>
                    <td id={`${idName}D`} onClick={($event) => selectTooth($event)}>D</td>
                    <td id={`${idName}E`} onClick={($event) => selectTooth($event)}>E</td>
                    <td id={`${idName}F`} onClick={($event) => selectTooth($event)}>F</td>
                    <td id={`${idName}G`} onClick={($event) => selectTooth($event)}>G</td>
                    <td id={`${idName}H`} onClick={($event) => selectTooth($event)}>H</td>
                    <td id={`${idName}I`} onClick={($event) => selectTooth($event)}>I</td>
                    <td id={`${idName}J`} onClick={($event) => selectTooth($event)}>J</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td id={`${idName}T`} onClick={($event) => selectTooth($event)}>T</td>
                    <td id={`${idName}S`} onClick={($event) => selectTooth($event)}>S</td>
                    <td id={`${idName}R`} onClick={($event) => selectTooth($event)}>R</td>
                    <td id={`${idName}Q`} onClick={($event) => selectTooth($event)}>Q</td>
                    <td id={`${idName}P`} onClick={($event) => selectTooth($event)}>P</td>
                    <td id={`${idName}O`} onClick={($event) => selectTooth($event)}>O</td>
                    <td id={`${idName}N`} onClick={($event) => selectTooth($event)}>N</td>
                    <td id={`${idName}M`} onClick={($event) => selectTooth($event)}>M</td>
                    <td id={`${idName}L`} onClick={($event) => selectTooth($event)}>L</td>
                    <td id={`${idName}K`} onClick={($event) => selectTooth($event)}>K</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td id={`${idName}32`} onClick={($event) => selectTooth($event)}>32</td>
                    <td id={`${idName}31`} onClick={($event) => selectTooth($event)}>31</td>
                    <td id={`${idName}30`} onClick={($event) => selectTooth($event)}>30</td>
                    <td id={`${idName}29`} onClick={($event) => selectTooth($event)}>29</td>
                    <td id={`${idName}28`} onClick={($event) => selectTooth($event)}>28</td>
                    <td id={`${idName}27`} onClick={($event) => selectTooth($event)}>27</td>
                    <td id={`${idName}26`} onClick={($event) => selectTooth($event)}>26</td>
                    <td id={`${idName}25`} className={`${centeredTeethCssClass}`} onClick={($event) => selectTooth($event)}>25</td>
                    <td id={`${idName}24`} className={`${centeredTeethCssClass}`} onClick={($event) => selectTooth($event)}>24</td>
                    <td id={`${idName}23`} onClick={($event) => selectTooth($event)}>23</td>
                    <td id={`${idName}22`} onClick={($event) => selectTooth($event)}>22</td>
                    <td id={`${idName}21`} onClick={($event) => selectTooth($event)}>21</td>
                    <td id={`${idName}20`} onClick={($event) => selectTooth($event)}>20</td>
                    <td id={`${idName}19`} onClick={($event) => selectTooth($event)}>19</td>
                    <td id={`${idName}18`} onClick={($event) => selectTooth($event)}>18</td>
                    <td id={`${idName}17`} onClick={($event) => selectTooth($event)}>17</td>
                </tr>
            </tbody>
        </table>
    )
}

export default ToothTable;