import { Box, CssBaseline } from '@mui/material'
import React, { useEffect, useState } from 'react'
import HeaderNav from '../../../Components/UI/Header/HeaderNav'
import ReportImage from '../../../Components/Report/ReportImage.component'
import { getFolderImages } from '../../../Services/admin.service'
import InfiniteScroll from 'react-infinite-scroller'
import ReportImageInDialogBox from '../../../Components/UI/Dialog/ReportImageInDialogBox'
import { useLocation, useNavigate } from 'react-router-dom'

const ListImages = () => {

    const navigate = useNavigate();
    const [images, setImages] = useState([]);
    const location = useLocation();
    const [imageContinuationToken, setImageContinuationToken] = useState();
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false);
    const [imageData, setImageData] = useState();
    const [completedImagesObj, setCompletedImagesObj] = useState({});
    const [loadingText, setLoadingText] = useState('');
    const [isCompletedImages, setIsCompletedImages] = useState(false);

    // Getting bucket, subFolder and txtSubFolder
    const folderData = location?.state?.data?.split("/");
    const bucket = folderData.splice(0, 1)[0];
    const subFolder = folderData.join("/");
    const txtSubFolder =  `${subFolder.split("/")[0]}/output`;

    const getImages = async () => {
        setLoadingText('Loading...');
        try {
            // const folderData = location?.state?.data?.split('/');
            // const bucket = folderData.splice(0, 1)[0];
            // const subFolder = folderData.join('/');
            const results = await getFolderImages({ bucket, subFolder, token: imageContinuationToken });
            setLoadingText('');
            if ([...new Set([...images, ...results.data.data.images])].length === images.length) {
                setLoadingText('Images are not remaining in the folder')
                setIsCompletedImages(true);
            }
            setImages([...new Set([...images, ...results.data.data.images])])
            setImageContinuationToken(results.data.data.token)
        } catch (error) {
            localStorage.clear();
            navigate('/');
        }
    }

    useEffect(() => {
        getImages();
    }, []);

    function handleDialogBox(index, image, typesOfImages) {
        setImageData({ image, imageType: '' });
        setIsDialogBoxOpen(true);
    }

    const handleCloseDialogBox = (data) => {
        setIsDialogBoxOpen(false);
        setCompletedImagesObj({ ...completedImagesObj, ...data });
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <HeaderNav title="Toothlens Annotation Tool" />

            <div className='container mt-5 pt-5'>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={isCompletedImages ? () => {} : getImages}
                    hasMore={true}
                    loader={<div className="loader my-4 text-center" key={0}>{loadingText}</div>}
                >
                    <div className='row'>
                        {
                            images.map((img, index) => {
                                return !completedImagesObj[img.split('/')[2]] && (
                                    <ReportImage
                                    key={`notAnnotated${img}`}
                                    bucket={`${bucket}/${subFolder}`}
                                    image={img.split('/')[2]}
                                    imageTypeName={img.split('/')[2]}
                                    handleDialogBox={() => handleDialogBox(index, img.split('/')[2], [])}
                                    isImageAnnotated={true}
                                />
                                )
                            })
                        }
                    </div>
                </InfiniteScroll>
            </div>

            {
                isDialogBoxOpen && (
                    <>
                        {<ReportImageInDialogBox
                            bucketName={bucket}
                            JSONBucketName="toothlens-image-store"
                            JSONFolderName="json_output"
                            subFolderName={subFolder}
                            txtFolderName={txtSubFolder}
                            isDialogBoxOpen={isDialogBoxOpen}
                            imageData={imageData}
                            handleCloseDialogBox={handleCloseDialogBox}
                            missingTeeth={[]}
                            isAligner={false}
                            isGapsInFrontTeethReports={false}
                            isThirdPartyReports={false}
                            isImageAnnotation={true}
                        />}
                    </>
                )
            }

        </Box>
    )
}

export default ListImages