import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import { Form, Formik } from 'formik';

import { updateAnnotatorTagsAPI } from '../../../Services/annotator.service';
import TagOptionsListWithCheckbox from './Components/TagOptionsListWithCheckbox';
import PermissionOptionsWithSwitch from './Components/PermissionOptionsWithSwitch';


const AssignTagsToAnnotatorDialog = ({ isDialogOpen, handleCloseDialog, annotatorData, handleDialogSubmit }) => {
    const [open, setOpen] = useState(isDialogOpen);
    const [isLoading, setIsLoading] = useState(false);
    const [permissionObjData, setPermissionObjData] = useState();

    const handleClose = () => {
        handleCloseDialog(false);
    };

    const handleFormSubmit = async (formValues) => {
        if (!annotatorData) {
            handleDialogSubmit({ message: 'Annotator not found!', severity: 'error' });
            return handleClose();
        }

        setIsLoading(true);
        try {
            const requestBody = {
                annotator: annotatorData._id,
                ...formValues,
                ...permissionObjData,
                status: formValues.status ? 'active' : 'inactive'
            }
            const result = await updateAnnotatorTagsAPI(requestBody);
            handleDialogSubmit({ message: 'Tags updated successfully!', severity: 'success' });
            handleClose();
        } catch (error) {
            const errorMessage = error?.data?.message ? error?.data?.message : 'Internal server error';
            handleDialogSubmit({ message: errorMessage, severity: 'error' });
        }
        setIsLoading(false);
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle className='text-center'>Assign tags and permissions</DialogTitle>
            <DialogContent className='p-3'>
                <Formik
                    initialValues={{
                        assigned_tags: annotatorData?.assigned_tags ? annotatorData.assigned_tags : [],
                        status: annotatorData?.status ? annotatorData.status === 'active' ? true : false : false,
                        user_type: annotatorData?.user_type ? annotatorData.user_type : '',
                        is_cavity_access: annotatorData?.tags_and_access.is_cavity_access,
                        is_tartar_access: annotatorData?.tags_and_access.is_tartar_access,
                        is_gum_recession_access: annotatorData?.tags_and_access.is_gum_recession_access,
                        is_gum_swollen_access: annotatorData?.tags_and_access.is_gum_swollen_access,
                        is_broken_teeth_access: annotatorData?.tags_and_access.is_broken_teeth_access,
                        is_enamel_access: annotatorData?.tags_and_access.is_enamel_access,
                        is_gaps_access: annotatorData?.tags_and_access.is_gaps_access
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        handleFormSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {({ values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting, }) => (
                        <Form>
                            <div className='row'>
                                <div className='col-6'>
                                    <FormControl fullWidth className='my-2'>
                                        <InputLabel id="demo-simple-select-label">User type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="user_type"
                                            label="User type"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.user_type}
                                        >
                                            <MenuItem value="annotator">Annotator</MenuItem>
                                            <MenuItem value="super_annotator">Super Annotator</MenuItem>
                                            <MenuItem value="reviewer">Reviewer</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='col-6 mb-4 text-center'>
                                    <label className='fw-bold w-100'>User Status</label>
                                    <FormControl>
                                        <FormControlLabel control={<Switch
                                            name="status"
                                            checked={values.status}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.status} />} label={values.status ? 'Active' : 'Inactive'} />
                                    </FormControl>
                                </div>
                                <div className='col-6 text-center'>
                                    <label className='fw-bold mb-2'>Tags</label>
                                    <TagOptionsListWithCheckbox
                                        formValues={values}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur} />
                                </div>
                                <div className='col-6'>
                                    <label className='fw-bold mb-2'>Permissions</label>
                                    <PermissionOptionsWithSwitch
                                        annotatorData={annotatorData}
                                        handlePermissionOptions={(data) => setPermissionObjData(data)} />
                                </div>
                            </div>

                            <div className='col-12 text-end'>
                                <Button onClick={handleClose} variant='contained' type='button' color='error' className='me-2'>Cancel</Button>
                                <Button onClick={handleSubmit} variant='contained' type='submit'
                                    disabled={isSubmitting}>
                                    {
                                        isLoading ? <CircularProgress className='text-white' style={{ width: '25px', height: '25px' }} /> : 'Submit'
                                    }
                                </Button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

export default AssignTagsToAnnotatorDialog;