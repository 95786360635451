import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    _id: '',
    first_name: '',
    email: '',
    access_tags: [],
    is_image_useable_access: false,
    is_poor_alignment_access: false,
    is_dark_teeth_access: false,
    is_missing_teeth_access: false,
    is_third_party_report_access: false,
    status: '',
    user_type: '',
}

const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        updateTagsAndPermission(state, action) {
            const updateValue = { ...state, ...action.payload };
            localStorage.setItem('user', JSON.stringify(action.payload));
            return updateValue;
        }
    }
});

export const userDataAction = userDataSlice.actions;
export default userDataSlice.reducer;