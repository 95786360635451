import axios from "axios";
import { API_HOST } from "./baseUrl";
import { getHeaders, handleError, handleSuccess } from "./common.service";


export const getThirdPartyReportsAPI = (cancelToken) => {
    return new Promise((resolve, reject) => {
        return axios
            .get(API_HOST + `third-party-reports/get-report`, { headers: getHeaders(), signal: cancelToken })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};

export const saveJSONData = (data) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(API_HOST + `third-party-reports/save-JSON`, data, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};
