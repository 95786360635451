import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import NestedList from '../UI/List/List';
import Button from '@mui/material/Button';

import { Fab } from '@mui/material';
import DataTable from '../UI/Table/TableGrid';
import BasicTable from '../UI/Table/TableGrid';
import SelectSmall from '../UI/SelectBox/SelectBox';
import { useLocation } from 'react-router-dom';
import { CAVITY_IMG_URL, TARTAR_IMG_URL } from '../../Services/baseUrl';
import AlertDialogSlide from '../UI/Dialog/ReportImageDialog';

import './rect.css';

const drawerWidth = 240;

export default function ImageTool(props) {

  /**
   * Collection of rectangles defining user generated regions
   */
  let rectangles = [{ x: 105, y: 110, width: 100, height: 100 }];
  const location = useLocation();
  const [locationData, setLocationData] = useState(location.state.data);
  const [rectanglesData, setRectanglesData] = useState([{ x: 105, y: 110, width: 100, height: 100 }]);
  const [reportImagesType, setReportImagesType] = useState(['front_teeth', 'upper_jaw', 'lower_jaw', 'left_side', 'right_side']);
  const [activeImage, setActiveImage] = useState();
  const [imageAngle, setImageAngle] = useState();

  // DOM elements
  const [screenshot, setScreenShot] = useState();
  // const $draw = domValue('#draw');
  const [marquee, setMarquee] = useState();
  const [boxes, setBoxes] = useState();
  const [imageTags, setImageTags] = useState({ zIndex: '9', display: 'none' });
  const [selectedImageTags, setSelectedImageTags] = useState([]);
  const imgRef = useRef();

  // Temp variables
  let startX = 0;
  let startY = 0;
  const marqueeRect = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };

  useEffect(() => {
    const domValue = document.querySelector.bind(document);
    setActiveImage(setActiveReportImages(0));
    setScreenShot(domValue('#screenshot'));
    setMarquee(domValue('#marquee'));
    setBoxes(domValue('#boxes'));
    // setCanvas(document.getElementById('canvas'));

    marquee?.classList.add('hide');
    if (boxes) {
      redraw();
      const tags = [...selectedImageTags]; //`${rectData.y - 25}px`
      tags.push({
        name: 'cavity',
        style: {
          left: `${105}px`, top: `${110}px`,
          width: `${100}px`, height: `100px`, border: '3px solid #5fbadd', background: 'transparent'
        }
      });
      setSelectedImageTags(tags);
    }


    return () => {
    }

  }, [marquee, screenshot]);

  function drawRegion() {
    screenshot?.addEventListener('pointerdown', startDrag);
  }

  function startDrag(ev) {
    // middle button delete rect
    if (!boxes.innerHTML) {
      rectangles = [];
    }
    if (ev.button === 1) {
      const rect = hitTest(ev.layerX, ev.layerY);
      if (rect) {
        rectangles.splice(rectangles.indexOf(rect), 1);
        redraw();
      }
      return;
    }
    window.addEventListener('pointerup', stopDrag);
    screenshot.addEventListener('pointermove', moveDrag);
    marquee.classList.remove('hide');
    startX = ev.layerX;
    startY = ev.layerY;
    drawRect(marquee, startX, startY, 0, 0);
  }

  function stopDrag(ev) {
    marquee.classList.add('hide');
    window.removeEventListener('pointerup', stopDrag);
    screenshot.removeEventListener('pointermove', moveDrag);
    // ev.stopPropagation();
    // ev.preventDefault();
    if (ev.target === screenshot && marqueeRect.width && marqueeRect.height) {
      const lastTagValue = rectangles[rectangles.length - 1];
      if (marqueeRect.x !== lastTagValue?.x && marqueeRect.y !== lastTagValue?.y) {
        rectangles.push(Object.assign({}, marqueeRect));
        setRectanglesData([...rectangles]);
        redraw();
        setImageTags({ left: `${marqueeRect.x}px`, top: `${marqueeRect.y + marqueeRect.height}px`, display: 'block', zIndex: '3' });
        document.getElementById('draw').classList.remove('pointers');
      }
    }
  }

  function moveDrag(ev) {
    let x = ev.layerX;
    let y = ev.layerY;
    let width = startX - x;
    let height = startY - y;
    if (width < 0) {
      width *= -1;
      x -= width;
    }
    if (height < 0) {
      height *= -1;
      y -= height;
    }
    Object.assign(marqueeRect, { x, y, width, height });
    drawRect(marquee, marqueeRect);
  }

  function hitTest(x, y) {
    return rectangles.find(rect => (
      x >= rect.x &&
      y >= rect.y &&
      x <= rect.x + rect.width &&
      y <= rect.y + rect.height
    ));
  }

  function redraw() {
    boxes.innerHTML = '';
    rectangles.forEach((data) => {
      boxes.appendChild(drawRect(
        document.createElementNS("http://www.w3.org/2000/svg", 'rect'), data
      ));
    });
  }

  function drawRect(rect, data) {
    const { x, y, width, height } = data;
    if (x && y && width && height) {
      rect?.setAttributeNS(null, 'width', width);
      rect?.setAttributeNS(null, 'height', height);
      rect?.setAttributeNS(null, 'x', x);
      rect?.setAttributeNS(null, 'y', y);
      rect?.addEventListener('click', (event) => {
        // event.stopPropagation();
        // event.preventDefault();
        // console.log('rect', event.getAttributesNS('http://www.w3.org/2000/svg', 'width'));
      })
      return rect;
    }
  }


  function clearRect() {
    boxes.innerHTML = '';
    rectangles = [];
    setRectanglesData([]);
    setSelectedImageTags([]);
    setImageTags({ display: 'none' });
  }

  function exportToJsonFile(jsonData) {
    let dataStr = JSON.stringify(jsonData);
    let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

    let exportFileDefaultName = 'data.json';

    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  }

  function parseJSONToCSVStr(jsonData) {
    if (jsonData.length === 0) {
      return '';
    }

    let keys = Object.keys(jsonData[0]);

    let columnDelimiter = ',';
    let lineDelimiter = '\n';

    let csvColumnHeader = keys.join(columnDelimiter);
    let csvStr = csvColumnHeader + lineDelimiter;

    jsonData.forEach(item => {
      keys.forEach((key, index) => {
        if ((index > 0) && (index < keys.length - 1)) {
          csvStr += columnDelimiter;
        }
        csvStr += item[key];
      });
      csvStr += lineDelimiter;
    });

    return encodeURIComponent(csvStr);;
  }

  function exportToCsvFile(jsonData) {
    let csvStr = parseJSONToCSVStr(jsonData);
    let dataUri = 'data:text/csv;charset=utf-8,' + csvStr;

    let exportFileDefaultName = 'data.csv';

    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  }

  function getSelectedTags(tag) {
    const rectData = rectanglesData[rectanglesData.length - 1];
    const tags = [...selectedImageTags]; //`${rectData.y - 25}px`
    tags.push({
      name: tag,
      style: {
        left: `${rectData.x}px`, top: `${rectData.y}px`,
        width: `${rectData.width}px`, height: `${rectData.height}px`, border: '3px solid #5fbadd', background: 'transparent',
      }
    });
    setSelectedImageTags(tags);
    setImageTags({ display: 'none' });
    document.getElementById('draw').classList.add('pointers');
    boxes.innerHTML = '';
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function setActiveReportImages(index) {
    setImageAngle(reportImagesType[index]);
    return locationData.isCavityOrTartar === 'cavity' ? `${CAVITY_IMG_URL}${locationData[reportImagesType[index]]}` :
      `${TARTAR_IMG_URL}${locationData[reportImagesType[index]]}`
  }

  function dragAndResizeBox(ev, item, index) {
    ev.target.style.border = '3px dotted #5fbadd';
    draggableDiv();
    ev.stopPropagation();
    const elem = document.getElementById('drawContainer');
    elem.addEventListener('click', () => {
      // alert('clicked');
      ev.target.style.border = '3px solid rgb(95, 186, 221)';
      elem.removeEventListener('click', () => {}, false);
    });
    ev.target.addEventListener('mousedown', (event) => {
      console.log('event', event);
      // mousedown(event, ev, item)
    });
  }

  function initDragElement() {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;
    var popups = document.getElementsByClassName("popup");
    var elmnt = null;
    var currentZIndex = 100; //TODO reset z index when a threshold is passed

    for (var i = 0; i < popups.length; i++) {
      var popup = popups[i];
      var header = getHeader(popup);

      popup.onmousedown = function () {
        this.style.zIndex = "" + ++currentZIndex;
      };

      if (header) {
        header.parentPopup = popup;
        header.onmousedown = dragMouseDown;
      }
    }

    function dragMouseDown(e) {
      elmnt = this.parentPopup;
      elmnt.style.zIndex = "" + ++currentZIndex;

      e = e || window.event;
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      console.log('pos3, pos4', pos3, pos4);
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      if (!elmnt) {
        return;
      }

      e = e || window.event;
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = elmnt.offsetTop - pos2 + "px";
      elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
      console.log('elmnt.style', elmnt.style.left, elmnt.style.top);
    }

    function getHeader(element) {
      var headerItems = element.getElementsByClassName("popup-header");

      if (headerItems.length === 1) {
        return headerItems[0];
      }

      return null;
    }
  }

  function initResizeElement() {
    var popups = document.getElementsByClassName("popup");
    var element = null;
    var startX, startY, startWidth, startHeight;

    for (var i = 0; i < popups.length; i++) {
      var p = popups[i];

      var right = document.createElement("div");
      right.className = "resizer-right";
      p.appendChild(right);
      right.addEventListener("mousedown", initDrag, false);
      right.parentPopup = p;

      var bottom = document.createElement("div");
      bottom.className = "resizer-bottom";
      p.appendChild(bottom);
      bottom.addEventListener("mousedown", initDrag, false);
      bottom.parentPopup = p;

      var both = document.createElement("div");
      both.className = "resizer-both";
      p.appendChild(both);
      both.addEventListener("mousedown", initDrag, false);
      both.parentPopup = p;
    }

    function initDrag(e) {
      element = this.parentPopup;

      startX = e.clientX;
      startY = e.clientY;
      startWidth = parseInt(
        document.defaultView.getComputedStyle(element).width,
        10
      );
      startHeight = parseInt(
        document.defaultView.getComputedStyle(element).height,
        10
      );
      document.documentElement.addEventListener("mousemove", doDrag, false);
      document.documentElement.addEventListener("mouseup", stopDrag, false);
    }

    function doDrag(e) {
      element.style.width = startWidth + e.clientX - startX + "px";
      element.style.height = startHeight + e.clientY - startY + "px";
    }

    function stopDrag() {
      document.documentElement.removeEventListener("mousemove", doDrag, false);
      document.documentElement.removeEventListener("mouseup", stopDrag, false);
      console.log('startX, startY, startWidth, startHeight', element.style.left, element.style.top, element.style.width, element.style.height)
    }
  }

  function draggableDiv() {
    initDragElement();
    initResizeElement();
  }

  function makeResizableDiv(div) {
    const element = document.querySelector(div);
    const resizers = document.querySelectorAll(div + ' .resizer')
    const minimum_size = 20;
    let original_width = 0;
    let original_height = 0;
    let original_x = 0;
    let original_y = 0;
    let original_mouse_x = 0;
    let original_mouse_y = 0;
    for (let i = 0;i < resizers.length; i++) {
      const currentResizer = resizers[i];
      // eslint-disable-next-line no-loop-func
      currentResizer.addEventListener('mousedown', function(e) {
        e.preventDefault()
        original_width = parseFloat(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
        original_height = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
        original_x = element.getBoundingClientRect().left;
        original_y = element.getBoundingClientRect().top;
        original_mouse_x = e.pageX;
        original_mouse_y = e.pageY;
        window.addEventListener('mousemove', resize)
        window.addEventListener('mouseup', stopResize)
      })
      
      // eslint-disable-next-line no-loop-func
      function resize(e) {
        if (currentResizer.classList.contains('bottom-right')) {
          const width = original_width + (e.pageX - original_mouse_x);
          const height = original_height + (e.pageY - original_mouse_y)
          if (width > minimum_size) {
            element.style.width = width + 'px'
          }
          if (height > minimum_size) {
            element.style.height = height + 'px'
          }
        }
        else if (currentResizer.classList.contains('bottom-left')) {
          const height = original_height + (e.pageY - original_mouse_y)
          const width = original_width - (e.pageX - original_mouse_x)
          if (height > minimum_size) {
            element.style.height = height + 'px'
          }
          if (width > minimum_size) {
            element.style.width = width + 'px'
            element.style.left = original_x + (e.pageX - original_mouse_x) + 'px'
          }
        }
        else if (currentResizer.classList.contains('top-right')) {
          const width = original_width + (e.pageX - original_mouse_x)
          const height = original_height - (e.pageY - original_mouse_y)
          if (width > minimum_size) {
            element.style.width = width + 'px'
          }
          if (height > minimum_size) {
            element.style.height = height + 'px'
            element.style.top = original_y + (e.pageY - original_mouse_y) + 'px'
          }
        }
        else {
          const width = original_width - (e.pageX - original_mouse_x)
          const height = original_height - (e.pageY - original_mouse_y)
          if (width > minimum_size) {
            element.style.width = width + 'px'
            element.style.left = original_x + (e.pageX - original_mouse_x) + 'px'
          }
          if (height > minimum_size) {
            element.style.height = height + 'px'
            element.style.top = original_y + (e.pageY - original_mouse_y) + 'px'
          }
        }
      }
      
      function stopResize() {
        window.removeEventListener('mousemove', resize)
      }
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar className='justify-content-center'>
          <Typography variant="h6" noWrap component="div">
            Toothlens Annotation Tool
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <NestedList
            drawRegion={drawRegion}
          />
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => exportToCsvFile(rectanglesData)}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Download as csv" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => exportToJsonFile(rectanglesData)}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Download as json" />
              </ListItemButton>
            </ListItem>
          </List>

        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <div className='container'>
          <div className='row align-item-center justify-content-center'>
            <div className='col-6 position-relative'>
              <div className='height-600px width-500px position-relative'>
                <div className='position-relative w-100 height-85 overflow-hidden' id="drawContainer">
                  <img
                    width="500"
                    height="500"
                    src={activeImage}
                    id="screenshot"
                    alt="report"
                    draggable="false"
                    ref={imgRef}
                  />

                  <svg width="500" height="500" viewBox="0 0 500 500" id="draw" className='pointers' xmlns="http://www.w3.org/2000/svg">
                    <rect id="marquee" x="450" y="420" width="150" height="150" />
                    <g id="boxes"></g>
                  </svg>

                  {
                    selectedImageTags.map((item, index) => (
                      // <div
                      //   key={index}
                      //   className='position-absolute tag-name'
                      //   style={item.style}
                      //   onClick={(event) => dragAndResizeBox(event, item, index)}>
                      //   <span className='p-1'
                      //     style={{ position: 'relative', top: '-25px', background: '#5fbadd' }}>
                      //     {item.name}</span>
                      // </div>
                      <div
                        key={index}
                        className="popup tag-name"
                        style={item.style}
                        onClick={(event) => dragAndResizeBox(event, item, index)}
                      >
                        <span className="p-1 popup-header" style={{ position: 'relative', top: '-25px', background: '#5fbadd' }}>{item.name}</span>
                      </div>
                    ))
                  }

                  <div className='position-absolute tag-box' style={imageTags}>
                    <SelectSmall onSelectedTags={getSelectedTags} />
                  </div>
                </div>
                <div className='row my-1 mx-0'>
                  {
                    reportImagesType.map((item, index) => (
                      <div key={item} className='col-2 width-20 small-img px-1'
                        onClick={() => {
                          setActiveImage(setActiveReportImages(index));
                          clearRect();
                        }}>
                        <img
                          className='w-100'
                          src={locationData.isCavityOrTartar === 'cavity' ?
                            `${CAVITY_IMG_URL}${locationData[item]}` :
                            `${TARTAR_IMG_URL}${locationData[item]}`}
                          alt={item}
                          // draggable="false"
                        />
                      </div>
                    ))
                  }
                  <div className='col-12 text-end my-2 p-0'>
                    <Button variant="contained" onClick={clearRect}>Clear</Button>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-6'>
              {/* <h5>Tags</h5> */}
              <BasicTable
                reason={locationData.reason}
                imgAngle={imageAngle}
              />
              {/* <AlertDialogSlide
                image={activeImage}
              /> */}
              {/* <button onClick={() => makeResizableDiv('.resizable')}>Check</button> */}

              {/* <div className="testing">
                <div className='resizable'>
                  <div className='resizers'>
                    <div className='resizer top-left'></div>
                    <div className='resizer top-right'></div>
                    <div className='resizer bottom-left'></div>
                    <div className='resizer bottom-right'></div>
                  </div>
                </div>
              </div> */}
            </div>

          </div>
          <Divider />
        </div>

      </Box>
    </Box>
  );
}