export const TAGS_OPTIONS = [
    {
        label: 'cavities',
        value: 'cavities',
        key: 'is_cavity_access'
    },
    {
        label: 'gum recession',
        value: 'gum recession',
        key: 'is_gum_recession_access'
    },
    {
        label: 'tartar',
        value: 'tartar',
        key: 'is_tartar_access'
    },
    {
        label: 'gum swollen',
        value: 'gum swollen',
        key: 'is_gum_swollen_access'
    },
    {
        label: 'broken teeth',
        value: 'broken teeth',
        key: 'is_broken_teeth_access'
    },
    {
        label: 'enamel',
        value: 'enamel',
        key: 'is_enamel_access'
    },
    {
        label: 'gaps',
        value: 'gaps',
        key: 'is_gaps_access'
    }
]