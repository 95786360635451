import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReviewerFeedbackCard from '../../Components/UI/Cards/ReviewerFeedbackCard';
import { Chip, Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function ReviewerFeedback() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const locationData = location?.state?.data;

  // console.log("locationData", locationData);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          aria-label="full width tabs example"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Front teeth" {...a11yProps(0)} />
          <Tab label="Upper jaw" {...a11yProps(1)} />
          <Tab label="Lower jaw" {...a11yProps(2)} />
          <Tab label="Left side" {...a11yProps(2)} />
          <Tab label="Right side" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div className='container'>
            <div className='row'>
              <div className='text-center col-12 my-4'>
                <h4 className='text-uppercase'><Divider><Chip label="FRONT TEETH" /></Divider></h4>
              </div>
              <div className='col-6 d-flex align-items-center justify-content-center'>
                <ReviewerFeedbackCard
                  key="Annotator"
                  title="Annotator"
                  image={locationData.report.front_teeth}
                  isAnnotator={true}
                  reportTagsData={locationData?.report_tags_data || []}
                  feedbackTitle="Reviewer feedback"
                  feedback={locationData?.front_teeth_feedback}
                />
              </div>
              <div className='col-6 d-flex align-items-center justify-content-center'>
                <ReviewerFeedbackCard
                  key="Reviewer"
                  title="Reviewer"
                  image={locationData.report.front_teeth}
                  isAnnotator={false}
                  reportTagsData={locationData?.reviewer_tags_data || []}
                  feedbackTitle="Feedback given"
                  feedback={locationData?.front_teeth_feedback}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div className='container'>
            <div className='row'>
              <div className='text-center col-12 my-4'>
                <h4 className='text-uppercase'><Divider><Chip label="UPPER JAW" /></Divider></h4>
              </div>
              <div className='col-6 d-flex align-items-center justify-content-center'>
                <ReviewerFeedbackCard
                  title="Annotator"
                  image={locationData.report.upper_jaw}
                  isAnnotator={true}
                  reportTagsData={locationData?.report_tags_data || []}
                  feedbackTitle="Reviewer feedback"
                  feedback={locationData?.upper_teeth_feedback}
                />
              </div>
              <div className='col-6 d-flex align-items-center justify-content-center'>
                <ReviewerFeedbackCard
                  title="Reviewer"
                  image={locationData.report.upper_jaw}
                  isAnnotator={false}
                  reportTagsData={locationData?.reviewer_tags_data || []}
                  feedbackTitle="Feedback given"
                  feedback={locationData?.upper_teeth_feedback}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <div className='container'>
            <div className='row'>
              <div className='text-center col-12 my-4'>
                <h4 className='text-uppercase'><Divider><Chip label="LOWER JAW" /></Divider></h4>
              </div>
              <div className='col-6 d-flex align-items-center justify-content-center'>
                <ReviewerFeedbackCard
                  title="Annotator"
                  image={locationData.report.lower_jaw}
                  isAnnotator={true}
                  reportTagsData={locationData?.report_tags_data}
                  feedbackTitle="Reviewer feedback"
                  feedback={locationData?.lower_jaw_feedback}
                />
              </div>
              <div className='col-6 d-flex align-items-center justify-content-center'>
                <ReviewerFeedbackCard
                  title="Reviewer"
                  image={locationData.report.lower_jaw}
                  isAnnotator={false}
                  reportTagsData={locationData?.reviewer_tags_data}
                  feedbackTitle="Feedback given"
                  feedback={locationData?.lower_jaw_feedback}
                />
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} index={3} dir={theme.direction}>
          <div className='container'>
            <div className='row'>
              <div className='text-center col-12 my-4'>
                <h4 className='text-uppercase'><Divider><Chip label="LEFT SIDE" /></Divider></h4>
              </div>
              <div className='col-6 d-flex align-items-center justify-content-center'>
                <ReviewerFeedbackCard
                  title="Annotator"
                  image={locationData.report.left_side}
                  isAnnotator={true}
                  reportTagsData={locationData?.report_tags_data}
                  feedbackTitle="Reviewer feedback"
                  feedback={locationData?.left_side_feedback}
                />
              </div>
              <div className='col-6 d-flex align-items-center justify-content-center'>
                <ReviewerFeedbackCard
                  title="Reviewer"
                  image={locationData.report.left_side}
                  isAnnotator={false}
                  reportTagsData={locationData?.reviewer_tags_data}
                  feedbackTitle="Feedback given"
                  feedback={locationData?.left_side_feedback}
                />
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} index={4} dir={theme.direction}>
          <div className='container'>
            <div className='row'>
              <div className='text-center col-12 my-4'>
                <h4 className='text-uppercase'><Divider><Chip label="RIGHT SIDE" /></Divider></h4>
              </div>
              <div className='col-6 d-flex align-items-center justify-content-center'>
                <ReviewerFeedbackCard
                  title="Annotator"
                  image={locationData.report.right_side}
                  isAnnotator={true}
                  reportTagsData={locationData?.report_tags_data}
                  feedbackTitle="Reviewer feedback"
                  feedback={locationData?.right_side_feedback}
                />
              </div>
              <div className='col-6 d-flex align-items-center justify-content-center'>
                <ReviewerFeedbackCard
                  title="Reviewer"
                  image={locationData.report.right_side}
                  isAnnotator={false}
                  reportTagsData={locationData?.reviewer_tags_data}
                  feedbackTitle="Feedback given"
                  feedback={locationData?.right_side_feedback}
                />
              </div>
            </div>
          </div>
        </TabPanel>

      </SwipeableViews>
    </Box>
  );
}