import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    timerState: null
};

const setTimerSlice = createSlice({
    name: 'timer',
    initialState,
    reducers: {
        setAnnotatorTimer(store) {
            store.timerState = setTimeout(() => {
                console.log('Timer completed')
            }, 10000);
        }
    }
});

export const setTimerActions = setTimerSlice.actions;

export default setTimerSlice.reducer;