import React, { useEffect } from 'react';

const FDISystemTooth = ({ selectTooth, missing_teeth, idName = 'teeth' }) => {

    const centeredTeethCssClass = idName === 'teeth' ? 'teeth-selected-color-secondary' : '';
    useEffect(() => {
        if (missing_teeth) {
            missing_teeth.forEach((item) => {
                document.getElementById(`${idName}${item}`)?.classList?.add('teeth-selected-color');
            });
        }
    }, []);

    return (
        <table className='select-tooth-table'>
            <tbody>
                <tr>
                    <td id={`${idName}18`} onClick={($event) => selectTooth($event)}>18</td>
                    <td id={`${idName}17`} onClick={($event) => selectTooth($event)}>17</td>
                    <td id={`${idName}16`} onClick={($event) => selectTooth($event)}>16</td>
                    <td id={`${idName}15`} onClick={($event) => selectTooth($event)}>15</td>
                    <td id={`${idName}14`} onClick={($event) => selectTooth($event)}>14</td>
                    <td id={`${idName}13`} onClick={($event) => selectTooth($event)}>13</td>
                    <td id={`${idName}12`} onClick={($event) => selectTooth($event)}>12</td>
                    <td id={`${idName}11`} className={`${centeredTeethCssClass}`} onClick={($event) => selectTooth($event)}>11</td>
                    <td id={`${idName}21`} className={`${centeredTeethCssClass}`} onClick={($event) => selectTooth($event)}>21</td>
                    <td id={`${idName}22`} onClick={($event) => selectTooth($event)}>22</td>
                    <td id={`${idName}23`} onClick={($event) => selectTooth($event)}>23</td>
                    <td id={`${idName}24`} onClick={($event) => selectTooth($event)}>24</td>
                    <td id={`${idName}25`} onClick={($event) => selectTooth($event)}>25</td>
                    <td id={`${idName}26`} onClick={($event) => selectTooth($event)}>26</td>
                    <td id={`${idName}27`} onClick={($event) => selectTooth($event)}>27</td>
                    <td id={`${idName}28`} onClick={($event) => selectTooth($event)}>28</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td id={`${idName}55`} onClick={($event) => selectTooth($event)}>55</td>
                    <td id={`${idName}54`} onClick={($event) => selectTooth($event)}>54</td>
                    <td id={`${idName}53`} onClick={($event) => selectTooth($event)}>53</td>
                    <td id={`${idName}52`} onClick={($event) => selectTooth($event)}>52</td>
                    <td id={`${idName}51`} onClick={($event) => selectTooth($event)}>51</td>
                    <td id={`${idName}61`} onClick={($event) => selectTooth($event)}>61</td>
                    <td id={`${idName}62`} onClick={($event) => selectTooth($event)}>62</td>
                    <td id={`${idName}63`} onClick={($event) => selectTooth($event)}>63</td>
                    <td id={`${idName}64`} onClick={($event) => selectTooth($event)}>64</td>
                    <td id={`${idName}65`} onClick={($event) => selectTooth($event)}>65</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td id={`${idName}85`} onClick={($event) => selectTooth($event)}>85</td>
                    <td id={`${idName}84`} onClick={($event) => selectTooth($event)}>84</td>
                    <td id={`${idName}83`} onClick={($event) => selectTooth($event)}>83</td>
                    <td id={`${idName}82`} onClick={($event) => selectTooth($event)}>82</td>
                    <td id={`${idName}81`} onClick={($event) => selectTooth($event)}>81</td>
                    <td id={`${idName}71`} onClick={($event) => selectTooth($event)}>71</td>
                    <td id={`${idName}72`} onClick={($event) => selectTooth($event)}>72</td>
                    <td id={`${idName}73`} onClick={($event) => selectTooth($event)}>73</td>
                    <td id={`${idName}74`} onClick={($event) => selectTooth($event)}>74</td>
                    <td id={`${idName}75`} onClick={($event) => selectTooth($event)}>75</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td id={`${idName}48`} onClick={($event) => selectTooth($event)}>48</td>
                    <td id={`${idName}47`} onClick={($event) => selectTooth($event)}>47</td>
                    <td id={`${idName}46`} onClick={($event) => selectTooth($event)}>46</td>
                    <td id={`${idName}45`} onClick={($event) => selectTooth($event)}>45</td>
                    <td id={`${idName}44`} onClick={($event) => selectTooth($event)}>44</td>
                    <td id={`${idName}43`} onClick={($event) => selectTooth($event)}>43</td>
                    <td id={`${idName}42`} onClick={($event) => selectTooth($event)}>42</td>
                    <td id={`${idName}41`} className={`${centeredTeethCssClass}`} onClick={($event) => selectTooth($event)}>41</td>
                    <td id={`${idName}31`} className={`${centeredTeethCssClass}`} onClick={($event) => selectTooth($event)}>31</td>
                    <td id={`${idName}32`} onClick={($event) => selectTooth($event)}>32</td>
                    <td id={`${idName}33`} onClick={($event) => selectTooth($event)}>33</td>
                    <td id={`${idName}34`} onClick={($event) => selectTooth($event)}>34</td>
                    <td id={`${idName}35`} onClick={($event) => selectTooth($event)}>35</td>
                    <td id={`${idName}36`} onClick={($event) => selectTooth($event)}>36</td>
                    <td id={`${idName}37`} onClick={($event) => selectTooth($event)}>37</td>
                    <td id={`${idName}38`} onClick={($event) => selectTooth($event)}>38</td>
                </tr>
            </tbody>
        </table>
    )
}

export default FDISystemTooth;