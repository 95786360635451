import React, { useEffect, useState } from 'react'

import HeaderNav from "../../Components/UI/Header/HeaderNav";
import { USER_TYPE } from "../../constants/common.constant";
import AnnotatorListTable from './components/AnnotatorListTable';
import { getAnnotatorUsersAPI } from '../../Services/annotator.service';
import PositionedSnackbar from '../../Components/UI/Snackbar/Snackbar';

const AnnotatorManagement = () => {
    const [annotatorListData, setAnnotatorListData] = useState();
    const [reportCount, setReportCount] = useState(0);
    const [snackbarData, setSnackbarData] = useState();
    const user = JSON.parse(localStorage.getItem('user'));

    const getAnnotatorUsers = async (paginationData) => {
        try {
            const response = await getAnnotatorUsersAPI({ pageIndex: paginationData.pageIndex + 1, pageSize: paginationData.pageSize });
            if (response?.data?.data) {
                setAnnotatorListData(response.data.data);
                setReportCount(response.data.count);
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        // getAnnotatorUsers();
    }, []);

    // NOTE: Here data is for snackbar
    const handleSubmitOfTagsDialog = (data) => {
        if (data?.severity === 'success') {
            getAnnotatorUsers();
        }
        setSnackbarData(data);
    }

    const handlePagination = (paginationData) => {
        getAnnotatorUsers(paginationData)
    }


    return (
        <>
            <HeaderNav title="Annotator Management" />
            {
                snackbarData && (
                    <PositionedSnackbar
                        message={snackbarData.message}
                        severity={snackbarData.severity}
                        isOpenSnackbar={snackbarData.message ? true : false}
                        vertical="top"
                        horizontal="right"
                        autoHideDuration={2000}
                        onCloseSnackBar={() => setSnackbarData('')}
                    />
                )
            }
            <div className="container mt-5 pt-5">
                <div className="row">
                    <div className="col-12">
                        <AnnotatorListTable
                            btnLabel="Annotated reports"
                            btnUrl="annotated-report"
                            annotatorListData={annotatorListData}
                            reportCount={reportCount}
                            isFilterRequired={false}
                            isAnnotator={user?.user_type === USER_TYPE.ANNOTATOR ? true : false}
                            handlePaginationData={handlePagination}
                            handleSubmitOfTagsDialog={handleSubmitOfTagsDialog}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnnotatorManagement;