import { Box, Chip, Stack } from "@mui/material";
import { useEffect, useState } from "react";

const TeethChip = ({ chipsData, selectedValue }) => {

    const [teethData, setTeethData] = useState([]);

    const transformData = (array1, array2) => {
        return array1.map((item) => {
            const isValueMatched = array2.find((item1) => item === item1);
            return {
                name: item,
                selected: isValueMatched ? true : false
            }
        });
    }

    useEffect(() => {
        setTeethData(transformData(chipsData, selectedValue));
    }, [selectedValue]);

    return (
        <Box sx={{ m: 2 }}>
            <Stack direction="row" spacing={1}>
                {
                    teethData.map((item) => {
                        return <Chip key={item.name} label={item.name}
                        className={`${item.selected ? 'primary-bg-important text-white' : ''}`}
                        />
                    })
                }
            </Stack>
        </Box>
    )
}

export default TeethChip;