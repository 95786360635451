import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useState } from 'react';

import { TAGS_OPTIONS } from '../common/tagsOptionsData';

const TagOptionsListWithCheckbox = ({ handleChange, handleBlur, formValues }) => {
    const [tagsListData, setTagsListData] = useState(JSON.parse(JSON.stringify(TAGS_OPTIONS))); // Deep clone Array

    return (
        <FormGroup>
            {
                tagsListData.map((tag, index) => {
                    return (
                        <FormControlLabel
                            className='mb-1 text-capitalize'
                            key={tag.label}
                            // role='group'
                            control={<Checkbox
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={formValues[tag.key]}
                                name={tag.key}
                                checked={formValues[tag.key]}
                            />}
                            label={tag.label}
                        />
                    )
                })
            }
        </FormGroup>
    )
}

export default TagOptionsListWithCheckbox;