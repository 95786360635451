export const handleSuccess = (response, resolve, reject) => {
    if (response.status === 200) {
        return resolve(response);
    }
    reject(response);
}

export const handleError = (error, reject) => {
    if (error && error.response) {
        reject({ ...error.response, isError: true });
    } else {
        reject({ message: 'Network error', isError: true });
    }
}

export const getHeaders = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: user?.authToken,
    };
    return headers;
}
