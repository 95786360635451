import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';

import AlertDialogSlide from '../../Components/UI/Dialog/ReportImageDialog';

export default function ViewReportImages(props) {
    return (
        <>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar className='justify-content-center'>
                    <Typography variant="h6" noWrap component="div">
                        Report Images
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className="container-fluid mt-5 pt-5">
                <div className="row">
                    <div className="col-12">
                    <AlertDialogSlide/>
                    </div>
                </div>
            </div>
        </>
    )
}