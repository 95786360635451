import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GetReports, GetReviewedReports } from "../../Services/reportService";
import { getAllAlignerReports } from "../../Services/alignerService";
import { USER_TYPE } from "../../constants/common.constant";
import ReportListTable from "./ReportListTable";
import HeaderNav from "../../Components/UI/Header/HeaderNav";

const ReportWithOutList = () => {
    const navigate = useNavigate();
    const [reportListData, setReportListData] = useState();
    const [reportCount, setReportCount] = useState();
    const user = JSON.parse(localStorage.getItem('user'));

    const handleError = () => {
        localStorage.clear();
        navigate('/')
    }

    const getAnnotatorReports = () => {
        GetReports().then(async (res) => {
            setReportListData(res.data.data);
        }, err => handleError());
    }

    async function getAlignerReports(pageIndex, pageSize) {
        try {
            const getReportsData = await getAllAlignerReports(pageIndex, pageSize);
            setReportListData(getReportsData.data.data);
            setReportCount(getReportsData.data.count)
        } catch (error) {
            handleError();
        }
    }

    const getReportsBasedOnUser = () => {
        if (!user) {
            return navigate('/')
        } else if (user.user_type === USER_TYPE.ANNOTATOR || user.user_type === USER_TYPE.SUPER_ANNOTATOR) {
            // return getAnnotatorReports();
            return setReportListData([]);
        }
    }

    useEffect(() => {
        getReportsBasedOnUser();
    }, []);

    const handlePagination = (paginationData) => {
        if (user?.user_type === USER_TYPE.REVIEWER) {
            GetReviewedReports({ pageIndex: paginationData.pageIndex + 1, pageSize: paginationData.pageSize }).then((res) => {
                const { data, count } = res.data;
                setReportListData(data);
                setReportCount(count);
            }, err => handleError());
        } else if (user.user_type === USER_TYPE.ALIGNER) {
            getAlignerReports(paginationData.pageIndex + 1, paginationData.pageSize)
        }
        
    }

    return (
        <>
          <HeaderNav title="Report List" />
            <div className="container mt-5 pt-5">
                <div className="row">
                    <div className="col-12">
                        <ReportListTable
                            // btnLabel="Annotated reports"
                            // btnUrl="annotated-report"
                            reportListData={reportListData}
                            reportCount={reportCount}
                            isAnnotator={user?.user_type === USER_TYPE.ANNOTATOR || user.user_type === USER_TYPE.SUPER_ANNOTATOR ? true : false}
                            handlePaginationData={handlePagination}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportWithOutList;