import { Button } from "@mui/material"
import { Link } from "react-router-dom"

const ButtonLink = ({ btnUrl, btnLabel, isActive }) => {
    return (
        <Link to={btnUrl}>
            <Button
                color="primary"
                variant={isActive ? "contained" : "outlined"}
            >
                {btnLabel}
            </Button>
        </Link>
    )
}

export default ButtonLink;