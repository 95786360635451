import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import "./Login.css";
import { userLogin } from "../../Services/authService";
import PositionedSnackbar from "../../Components/UI/Snackbar/Snackbar";
import { USER_TYPE } from "../../constants/common.constant";
import { userDataAction } from "../../store/userDataSlice";
import { useDispatch } from "react-redux";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://toothlens.com/" target="_blank">
        Toothlens
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbarData, setSnackbarData] = useState('');
  const user = JSON.parse(localStorage.getItem('user'));
  

  useEffect(() => {
    if (user) {
      navigate('/report-list');
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
        email: data.get("email"),
        password: data.get("password"),
    }
    if (formData.email.trim() === '' && !formData.password) {
     return setSnackbarData({ message: 'Invalid credential', severity: 'error' });
    }

    userLogin(formData)
        .then((res) => {
          setSnackbarData({ message: 'Login successfully!', severity: 'success' });
          setTimeout(() => {
            if (res.data.code === 200) {
              // storing data to a store
              dispatch(userDataAction.updateTagsAndPermission(res?.data?.data));
              const userType = res.data.data.user_type;
              if (userType === USER_TYPE.ANNOTATOR || userType === USER_TYPE.SUPER_ANNOTATOR || userType === USER_TYPE.ALIGNER) {
                navigate('/report-list');
              } else if (userType === USER_TYPE.REVIEWER) {
                navigate('/report-list');
              } else if (userType === USER_TYPE.ADMIN) {
                navigate('/reviewed-reports');
              }
            }
          }, 1000);
        }, err => {
          setSnackbarData({ message: err.data.message, severity: 'error' });
        });

  };

  function onCloseSnackBar(status) {
    setSnackbarData('');
  }

  return (
    <div className="login-container">
      {
          snackbarData && (
            <PositionedSnackbar
              message={snackbarData.message}
              severity={snackbarData.severity}
              isOpenSnackbar={snackbarData.message ? true : false}
              vertical="top"
              horizontal="right"
              onCloseSnackBar={onCloseSnackBar}
            />
          )
        }
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src="https://app.toothlens.com/static/media/logo67x67.71b426ee.svg"
                alt="app logo"
                width="50"
                height="50"
              />
            </div>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
              <Button
                type="submit"
                className="primary-bg py-3"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            </Box>
          </Box>
          <Copyright sx={{ mt: 4, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </div>
  );
}
