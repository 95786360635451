import axios from "axios";
import { API_HOST } from "./baseUrl";
import { getHeaders, handleError, handleSuccess } from "./common.service";

export const ReviewReportGetAPI = () => {
  const authToken = JSON.parse(localStorage.getItem('user'))?.authToken;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(API_HOST + `review-report`, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const GetReportWithoutFlag = () => {
  const authToken = JSON.parse(localStorage.getItem('user'))?.authToken;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(API_HOST + `review-report/without-flag`, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const StoreVdcJsonFile = (jsonData, parentFolder, subFolder, jsonFileName) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const dataObj = {
    data: jsonData,
    parentFolder,
    subFolder,
    jsonFileName
  }
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `review-report/vdc/save-json`, dataObj, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const StoreJsonFile = (data) => {
  const authToken = JSON.parse(localStorage.getItem('user'))?.authToken;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `review-report/save-json`, data, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const GetBucketFile = (fileData) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: user?.authToken,
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `review-report/get-file`, fileData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const GetVDCBucketFile = (fileData) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `review-report/vdc/get-file`, fileData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const vdcJsonStore = (fileData) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `review-report/vdc/store-json`, fileData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const GetReports = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: user?.authToken,
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(API_HOST + `tool/get-report/${user._id}`, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const submitReportData = (data) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: user?.authToken,
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `reportReviewed/create`, data, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const GetReviewedReports = (paginationData) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: user?.authToken,
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `reportReviewed/get-report`, paginationData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const GetAnnotatedReports = (userId = null, isFilterApplied = false) => {
  const user = JSON.parse(localStorage.getItem('user'));
  userId = userId ? userId : user._id;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: user?.authToken,
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `tool/get-annotated-reports/${userId}`, { isFilterApplied }, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

// Get annotator users
export const GetAnnotatorUsers = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: user?.authToken,
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(API_HOST + `tool/get-annotator`, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

// Get reviewed report by reportId
export const GetReviewedReportByReportId = (reportId) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: user?.authToken,
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(API_HOST + `reportReviewed/${reportId}`, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const GetReportByAnnotatorId = (reportId) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: user?.authToken,
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(API_HOST + `tool/annotator/${reportId}`, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const getPoorAlignmentReportsAPI = (paginationData, cancelToken) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: user?.authToken,
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `reportReviewed/poor-alignment-reports`, paginationData, { headers: headers, signal: cancelToken })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const reviewerReviewedReports = (pageData) => {
  return new Promise((resolve, reject) => {
      return axios
          .get(API_HOST + `reportReviewed/reviewer-reports/${pageData.pageIndex}/${pageData.pageSize}/${pageData.startDate}/${pageData.endDate}/${pageData.annotatorId}`,
          { headers: getHeaders() })
          .then((response) => {
              handleSuccess(response, resolve, reject);
          })
          .catch((error) => {
              handleError(error, reject);
          });
  });
};

export const reportImageUploadService = (reqData) => {
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  var blob = dataURItoBlob(reqData.imageUrl);
  var file = new File([blob], "report_image.jpeg", { type: "image/jpeg" });
  var formData = new FormData();
  formData.append("report_image", file);
  formData.append("imageName", reqData.fileName);
  formData.append("fileFolder", reqData.fileFolder);

  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `review-report/vdc/store-images`, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        return error;
      });
  });
};