import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import './TableGrid.css';
import { Button } from '@mui/material';
import { Formik } from 'formik';

export default function BasicTable({ reason, imgAngle }) {

    const [reportImageAngle, setReportImageAngle] = useState();

    useEffect(() => {
        setReportImageAngle(imgAngle);
    }, [imgAngle])

    return (
        <Formik
            initialValues={{
                usable: "usable",
                imageContent: "mouth",
                imageStatus: 'original',
                imageAngle: reportImageAngle,
                tag: 'bright' 
            }}
                onSubmit={(values, { setSubmitting }) => {
                    console.log('form values', values, reportImageAngle);
                    setSubmitting(false);
                    values.imageAngle = reportImageAngle;
                }}

        >
            {(formProp) => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                } = formProp;

                return (
                    <form onSubmit={handleSubmit}>
                        <table className='tags-table'>
                            <thead>
                                <tr>
                                    <th>Tags property</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><b>Reason</b></td>
                                    <td>{reason}</td>
                                </tr>
                                <tr>
                                    <td><b>Usability</b></td>
                                    <td>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={values.usable}
                                                name="usable"
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel value="usable" control={<Radio />} label="Usable" />
                                                <FormControlLabel value="non-usable" control={<Radio />} label="Non-usable" />
                                            </RadioGroup>
                                        </FormControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Image content</b></td>
                                    <td>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={values.imageContent}
                                                name="imageContent"
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel value="mouth" control={<Radio />} label="Mouth" />
                                                <FormControlLabel value="face" control={<Radio />} label="Face" />
                                            </RadioGroup>
                                        </FormControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Image status</b></td>
                                    <td>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={values.imageStatus}
                                                name="imageStatus"
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel value="original" control={<Radio />} label="Original" />
                                                <FormControlLabel value="modified" control={<Radio />} label="Modified" />
                                            </RadioGroup>
                                        </FormControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Image Angle</b></td>
                                    <td>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={imgAngle ? imgAngle : ''}
                                                name="imageAngle"
                                                onChange={handleChange}
                                                // onBlur={handleBlur}
                                            >
                                                <FormControlLabel value="front_teeth" control={<Radio />} label="Front teeth" />
                                                <FormControlLabel value="upper_jaw" control={<Radio />} label="Upper jaw" />
                                                <FormControlLabel value="lower_jaw" control={<Radio />} label="Lower jaw" />
                                                <FormControlLabel value="left_side" control={<Radio />} label="Left side" />
                                                <FormControlLabel value="right_side" control={<Radio />} label="Right side" />
                                            </RadioGroup>
                                        </FormControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Tags</b></td>
                                    <td>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={values.tag}
                                                name="tag"
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel value="blur" control={<Radio />} label="Blur" />
                                                <FormControlLabel value="dark" control={<Radio />} label="Dark" />
                                                <FormControlLabel value="bright" control={<Radio />} label="Bright" />
                                            </RadioGroup>
                                        </FormControl>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='col-12 text-end my-2 p-0'>
                            <Button type='submit' variant="contained">Submit</Button>
                        </div>
                    </form>
                )
            }
            }

        </Formik>
    );
}