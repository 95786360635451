import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Checkbox,
    CircularProgress, FormControl, FormControlLabel, FormGroup, IconButton, InputAdornment, InputLabel,
    MenuItem, OutlinedInput, Select, Switch
} from '@mui/material';
import { AccountCircle, EmailOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { ErrorMessage, Form, Formik } from 'formik';

import { createAnnotatorAPI } from '../../../Services/annotator.service';
import TagOptionsListWithCheckbox from './Components/TagOptionsListWithCheckbox';
import PermissionOptionsWithSwitch from './Components/PermissionOptionsWithSwitch';

const CreateAnnotatorDialog = ({ isDialogOpen, handleCloseDialog, annotatorData, handleDialogSubmit }) => {
    const [open, setOpen] = useState(isDialogOpen);
    const [isLoading, setIsLoading] = useState(false);
    const [permissionObjData, setPermissionObjData] = useState();
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClose = () => {
        handleCloseDialog(false);
    };

    const handleFormSubmit = async (formValues) => {
        setIsLoading(true);
        try {
            const requestBody = {
                ...formValues,
                ...permissionObjData,
                status: formValues.status ? 'active' : 'inactive'
            }
            // alert(JSON.stringify(requestBody, null, 2));
            const result = await createAnnotatorAPI(requestBody);
            if (result.data.code === 200) {
                handleDialogSubmit({ message: 'Account created successfully!', severity: 'success' });
                handleClose();
            }
        } catch (error) {
            const errorMessage = error?.data?.message ? error?.data?.message : 'Internal server error';
            handleDialogSubmit({ message: errorMessage, severity: 'error' });
        }
        setIsLoading(false);
    }

    return (
        <Dialog maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle className='text-center'>Create Annotator</DialogTitle>
            <DialogContent className='p-3'>

                <Formik
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        email: '',
                        password: '',
                        user_type: '',
                        status: true,
                        is_cavity_access: false,
                        is_tartar_access: false,
                        is_gum_recession_access: false,
                        is_gum_swollen_access: false,
                        is_broken_teeth_access: false,
                        is_enamel_access: false,
                        is_gaps_access: false
                    }}
                    validate={values => {
                        const errors = {};
                        if (!values.first_name) {
                            errors.first_name = 'First name is required';
                        }
                        if (!values.password) {
                            errors.password = 'Password is required';
                        }
                        if (!values.user_type) {
                            errors.user_type = 'User type is required';
                        }
                        if (!values.email) {
                            errors.email = 'Email is required';
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = 'Invalid email address';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        handleFormSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {({ values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting, }) => (
                        <Form>
                            <div className='row'>
                                <div className='col-12 text-end mb-2'><span className='text-danger'>*</span> Required</div>
                                <div className='col-6'>
                                    <FormControl sx={{ width: '100%' }} className='my-2' variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-first-name">First name <span className='text-danger fs-4'>*</span></InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-first-name"
                                            startAdornment={<InputAdornment position="end"> <AccountCircle /></InputAdornment>}
                                            aria-describedby="outlined-first-name-helper-text"
                                            label="First name"
                                            name="first_name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.first_name}
                                        />
                                        <ErrorMessage name="first_name" className='text-danger' component="div" />
                                    </FormControl>
                                </div>
                                <div className='col-6'>
                                    <FormControl sx={{ width: '100%' }} className='my-2' variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-last-name">Last name</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-last-name"
                                            startAdornment={<InputAdornment position="end"> <AccountCircle /></InputAdornment>}
                                            aria-describedby="outlined-last-name-helper-text"
                                            label="last name"
                                            name="last_name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.last_name}
                                        />
                                    </FormControl>
                                </div>
                                <div className='col-6'>
                                    <FormControl sx={{ width: '100%' }} className='my-2' variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-last-name">Email <span className='text-danger fs-4'>*</span></InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-email"
                                            startAdornment={<InputAdornment position="end"> <EmailOutlined /></InputAdornment>}
                                            aria-describedby="outlined-email-helper-text"
                                            label="email"
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        <ErrorMessage className='text-danger fs-6' name="email" component="div" />
                                    </FormControl>
                                </div>
                                <div className='col-6'>
                                    <FormControl sx={{ width: '100%' }} className='my-2' variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Password <span className='text-danger fs-4'>*</span></InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                            name="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                        />
                                        <ErrorMessage className='text-danger fs-6' name="password" component="div" />
                                    </FormControl>
                                </div>
                                <div className='col-6'>
                                    <FormControl fullWidth className='my-2'>
                                        <InputLabel id="demo-simple-select-label">User type <span className='text-danger fs-4'>*</span></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="user_type"
                                            label="User type"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.user_type}
                                        >
                                            <MenuItem value="annotator">Annotator</MenuItem>
                                            <MenuItem value="super_annotator">Super Annotator</MenuItem>
                                            <MenuItem value="reviewer">Reviewer</MenuItem>
                                        </Select>
                                        <ErrorMessage className='text-danger fs-6' name="role" component="div" />
                                    </FormControl>
                                </div>
                                <div className='col-6'>
                                    <FormControl fullWidth className='mt-3'>
                                        <FormControlLabel control={<Switch
                                            name="status"
                                            checked={values.status}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.status} />} label="Active" />
                                    </FormControl>
                                </div>
                                <div className='col-6 text-center'>
                                    <label className='fw-bold mb-2'>Tags</label>
                                    <TagOptionsListWithCheckbox
                                        formValues={values}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur} />
                                </div>
                                <div className='col-6'>
                                    <label className='fw-bold mb-2'>Permissions</label>
                                    <PermissionOptionsWithSwitch
                                        annotatorData={null}
                                        handlePermissionOptions={(data) => setPermissionObjData(data)} />
                                </div>
                            </div>

                            <div className='col-12 text-end'>
                                <Button onClick={handleClose} variant='contained' type='button' color='error' className='me-2'>Cancel</Button>
                                <Button onClick={handleSubmit} variant='contained' type='submit'
                                    disabled={isSubmitting}>
                                    {
                                        isLoading ? <CircularProgress className='text-white' style={{ width: '25px', height: '25px' }} /> : 'Submit'
                                    }
                                </Button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

export default CreateAnnotatorDialog;