import React, { useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';

import AnnotatorFilter from '../../Components/UI/Autocomplete/AnnotatorFilter';
import ButtonLink from './Components/ButtonLink';
import { ButtonLinkData } from '../../utility/tab.utility';
import './../../stylesheet/common.css';
import { GetAnnotatedReports } from '../../Services/reportService';
import { USER_TYPE } from '../../constants/common.constant';

const ReportListTable = (props) => {
    const navigate = useNavigate();
    const locationPath = useLocation();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10
    });
    const [isLoading, setIsLoading] = useState(false);
    const [reportData, setReportData] = useState();
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const buttonLinkData = ButtonLinkData(user);

    useEffect(() => {
        setIsLoading(true);
        if (props.reportListData?.length > 0) {
            setReportData(props.reportListData)
        }
        setIsLoading(false);
    }, [props.reportListData]);

    useEffect(() => {
        if (!isFilterApplied) {
            props.handlePaginationData(pagination);
            setIsLoading(true);
        }
    }, [pagination.pageIndex, pagination.pageSize]);

    //defining columns outside of the component is fine, is stable
    const columns = [
        {
            accessorKey: '_id',
            header: 'ID',
            size: 120,
            Cell: ({ row }) => (
                <>
                    <AssignmentIcon className='primary-color' />
                    <span className='ms-2'>{row.original._id}</span>
                </>
            ),
        },
        {
            header: 'Annotator Name',
            Cell: ({ row }) => {
                return <div>{row.original?.name ? row.original.name : row.original?.annotator?.first_name}</div>
            }
        },
        {
            header: 'Action',
            Cell: ({ row }) => {
                const stateData = isFilterApplied ?
                    {
                        _id: row.original?.report?._id,
                        name: row.original?.annotator?.first_name,
                        email: row.original?.annotator?.email,
                        ...row.original?.report
                    } : {
                        ...row.original,
                        isThirdPartyReports: props?.isThirdPartyReports ? props?.isThirdPartyReports : false,
                        isGapsInFrontTeethReports: props?.isGapsInFrontTeethReports ? true : false
                    }
                return <Button onClick={() => navigate('/annotation-tool', {
                    state: {
                        data: stateData
                    }
                })}>view</Button>
            },
        }
    ];

    const getFiltersData = async (data) => {
        setIsLoading(true);
        try {
            const res = await GetAnnotatedReports(data._id, true);
            setReportData(res.data.data);
        } catch (error) {
            
        }
        setIsLoading(false);
    }

    const getLoadingState = (data) => {
        setIsLoading(data);
    }

    const getFilterState = (value) => {
        if (!value) {
            props.handlePaginationData(pagination);
        }
        setIsFilterApplied(value)
    }

    return (
        <>
            {
                user?.user_type === USER_TYPE.REVIEWER && <AnnotatorFilter
                getFiltersData={getFiltersData}
                // getLoadingState={setIsLoading}
                getFilterState={getFilterState} />
            }
            <MaterialReactTable
                columns={columns}
                data={reportData ?? []} //fallback to array if data is undefined
                // enableStickyHeader
                rowNumberMode="original"
                enableRowNumbers
                enablePagination={isFilterApplied ? false : true}
                rowCount={props.reportCount}
                manualPagination={true}
                onPaginationChange={setPagination}
                state={{ pagination, isLoading }}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        {
                            buttonLinkData.map((item) => {
                                return <ButtonLink
                                    key={item.btnLabel}
                                    btnUrl={item.btnUrl}
                                    btnLabel={item.btnLabel}
                                    isActive={locationPath.pathname === item.btnUrl ? true : false}
                                />
                            })
                        }
                    </Box>
                )}
            />
        </>
    )
};

export default ReportListTable;
