import React, { useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContentCopy } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import './../../stylesheet/common.css';
import ButtonLink from './Components/ButtonLink';
import { ButtonLinkData } from '../../utility/tab.utility';

const AnnotatedReportList = (props) => {
    const navigate = useNavigate();
    const locationPath = useLocation();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
        isLoading: true
    });
    const user = JSON.parse(localStorage.getItem('user'));
    const buttonLinkData = ButtonLinkData(user);

    useEffect(() => {
        props.handlePaginationData(pagination);
    }, [pagination.pageIndex, pagination.pageSize]);

    //defining columns outside of the component is fine, is stable
    const columns = [
        {
            accessorKey: '_id',
            header: 'ID',
            size: 120,
            Cell: ({ row }) => (
                <>
                    <AssignmentIcon className='primary-color' />
                    <span className='ms-2'>{row.original._id}</span>
                </>
            ),
        },
        {
            header: 'Annotated',
            Cell: ({ row}) => (
                <Stack spacing={1}>
                    <Stack direction="row" spacing={1}>
                        { new Date(row.original.createdAt).toLocaleDateString() }
                        {/* <CheckCircleIcon color='success' /> */}
                    </Stack>
                </Stack>
            )
        },
        {
            header: 'Reviewed',
            Cell: ({ row }) => (
                <>
                    <Stack spacing={1}>
                        <Stack direction="row" spacing={1}>
                            { row.original.reviewed_confirmed_date ?  new Date(Number(row.original.reviewed_confirmed_date)).toLocaleDateString() : '' }
                            {/* {row.original.reviewer ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />} */}
                        </Stack>
                    </Stack>
                </>
            )
        },
        {
            header: 'Action',
            Cell: ({ row }) => (
                <Button onClick={() => navigate('/reviewer-feedback', {
                    state: {
                        data: row.original
                    }
                })}>view</Button>
            ),
        }
    ];

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        // showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        csvExporter.generateCsv(props.reportListData);
    };

    const handleRedirect = () => {
        return navigate(`/${props.btnUrl}`)
    };

    return <MaterialReactTable
        columns={columns}
        data={props.reportListData}
        // enableStickyHeader
        rowNumberMode="original"
        enableRowNumbers
        // pageCount={10}
        // rowCount={props.reportCount}
        // manualPagination={true}
        // onPaginationChange={setPagination}
        // state={{ pagination }}
        // isLoading={true}
        renderTopToolbarCustomActions={({ table }) => (
            <Box
                sx={{  gap: '1rem', p: '0.5rem'}}
            >
                <div>
                    {
                        buttonLinkData.map((item) => {
                            return <ButtonLink
                                key={item.btnLabel}
                                btnUrl={item.btnUrl}
                                btnLabel={item.btnLabel}
                                isActive={locationPath.pathname === item.btnUrl ? true : false}
                            />
                        })
                    }
                </div>
               <div className='mt-3' style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap'  }}>
               <div
                    color="primary"
                    onClick={handleRedirect}
                    variant="outline"
                >
                    Useable reports count: <b className='primary-color' style={{ fontSize: '20px' }}> {props.reportListData.length}</b>
                </div>
                <div
                    color="primary"
                    onClick={handleRedirect}
                    variant="outline"
                >
                    Total annotated reports count: <b className='primary-color' style={{ fontSize: '20px' }}>
                        {props.annotatedReportCount ? props.annotatedReportCount : 0}</b>
                </div>
               </div>
            </Box>
        )}
    />;
};

export default AnnotatedReportList;
