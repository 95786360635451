import React, { useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AssignTagsToAnnotatorDialog from '../../../Components/UI/Dialog/AssignTagsToAnnotatorDialog';
import CreateAnnotatorDialog from '../../../Components/UI/Dialog/CreateAnnotatorDialog';
import AlertDialog from '../../../Components/UI/Dialog/AlertDialog';
import { removeAssignedFolder } from '../../../Services/annotator.service';
import { ToastContainer, toast } from 'react-toastify';
import AssignedFolderDialog from '../../../Components/UI/Dialog/AssignedFolderDialog';

const columns = [
    {
        header: 'Annotator Name',
        Cell: ({ row }) => {
            return <div className='text-uppercase'>{row.original?.first_name}</div>
        }
    },
    {
        header: 'Role',
        Cell: ({ row }) => {
            return <div className='text-uppercase'>{row.original?.user_type}</div>
        }
    }
];

const AnnotatorListTable = (props) => {
    const navigate = useNavigate();
    const locationPath = useLocation();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10
    });
    const [isLoading, setIsLoading] = useState(false);
    const [reportData, setReportData] = useState();
    const [isAssignTagsDialogBoxOpen, setIsAssignTagsDialogBoxOpen] = useState(false);
    const [annotatorData, setAnnotatorData] = useState();
    const [isCreateAnnotatorDialogShow, setIsCreateAnnotatorDialogShow] = useState(false);
    const [isShowAlertDialog, setIsShowAlertDialog] = useState(false);
    const [folderData, setFolderData] = useState();
    const [isShowAssignFolderDialog, setIsShowAssignFolderDialog] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        if (props.annotatorListData?.length > 0) {
            setReportData(props.annotatorListData)
        }
        setIsLoading(false);
    }, [props.annotatorListData]);

    useEffect(() => {
        props.handlePaginationData(pagination);
        setIsLoading(true);
    }, [pagination.pageIndex, pagination.pageSize]);

    const getAnnotatorData = (data) => {
        setIsAssignTagsDialogBoxOpen(true);
        setAnnotatorData(data);
    }

    const handleDialogSubmit = (snackbarData) => {
        props.handleSubmitOfTagsDialog(snackbarData);
    }

    const handleRemoveFolder = async () => {
        setIsLoading(true);
        try {
            const { annotatorId, folderName, index } = folderData;
            const response = await removeAssignedFolder({ annotatorId, folderName });
            if (response?.status === 200) {
                let annotators = [...reportData];
                annotators[index].assigned_folders = response.data.data.assigned_folders;
                setReportData(annotators);
                toast.success("Successfully Removed folder", {
                    position: "top-right"
                });
            }
        } catch (error) {
            toast.error("Something went wrong !", {
                position: "top-right"
            });
        }
        setIsLoading(false);
        setIsShowAlertDialog(false);
    }

    const handleAnnotatorFolderData = (annotatorId, folderName, index) => {
        setFolderData({ annotatorId, folderName, index });
        setIsShowAlertDialog(true)
    }

    const handleAssignFolderDialog = () => {
        setIsShowAssignFolderDialog(false)
        props.handlePaginationData(pagination);
        setIsLoading(true);
    }

    return (
        <>
            <ToastContainer />
            <MaterialReactTable
                columns={
                    [
                        ...columns,
                        {
                            header: 'Action',
                            Cell: ({ row }) => {
                                return <Button variant='contained' onClick={() => getAnnotatorData(row.original)}>Update Tags and permissions</Button>
                            },
                        }
                    ]
                }
                data={reportData ?? []} //fallback to array if data is undefined
                // enableStickyHeader
                rowNumberMode="original"
                enableRowNumbers
                enablePagination={true}
                rowCount={props.reportCount}
                manualPagination={true}
                onPaginationChange={setPagination}
                state={{ pagination, isLoading }}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <Button variant="contained" onClick={() => setIsShowAssignFolderDialog(true)}>Assign Folder</Button>
                        <Button variant='contained' onClick={() => setIsCreateAnnotatorDialogShow(true)}>Create User</Button>
                    </Box>
                )}
                //custom expand button rotation
                muiExpandButtonProps={({ row, table }) => ({
                    onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
                    sx: {
                        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                        transition: 'transform 0.2s',
                    },
                })}
                //conditionally render detail panel
                renderDetailPanel={({ row }) =>
                    <Box
                        sx={{
                            display: 'grid',
                            margin: 'auto',
                            gridTemplateColumns: '1fr 1fr',
                            width: '100%',
                        }}
                    >
                        {
                            row.original.assigned_folders.length ? (
                                <div>
                                    <h5>Assigned Folders:</h5>
                                    {
                                        row.original.assigned_folders.map((folder, index) => {
                                            return <div key={folder}>{folder}
                                                <span className='text-info ms-2 cursor-pointer'
                                                    onClick={() => handleAnnotatorFolderData(row.original._id, folder, row.index)}>
                                                    Remove
                                                </span>
                                            </div>
                                        })
                                    }
                                </div>
                            ) : <h5>Folders not assigned</h5>
                        }
                    </Box>
                }
            />
            {/* Alert dialog */}
            {
                isShowAlertDialog && <AlertDialog
                    message="Are you sure you want to remove the folder?"
                    open={isShowAlertDialog}
                    onClose={setIsShowAlertDialog}
                    onSubmit={handleRemoveFolder}
                    isLoader={isLoading}
                />
            }
            {/* Assign tags dialog */}
            {
                isAssignTagsDialogBoxOpen && <AssignTagsToAnnotatorDialog
                    isDialogOpen={isAssignTagsDialogBoxOpen}
                    handleCloseDialog={() => setIsAssignTagsDialogBoxOpen(false)}
                    annotatorData={annotatorData}
                    handleDialogSubmit={handleDialogSubmit}
                />
            }
            {/* Create annotator dialog */}
            {
                isCreateAnnotatorDialogShow && <CreateAnnotatorDialog
                    isDialogOpen={isCreateAnnotatorDialogShow}
                    handleCloseDialog={setIsCreateAnnotatorDialogShow}
                    annotatorData={null}
                    handleDialogSubmit={handleDialogSubmit}
                />
            }
            {/* Assign folder dialog */}
            {
                isShowAssignFolderDialog && <AssignedFolderDialog
                isDialogOpen={isShowAssignFolderDialog}
                handleCloseDialog={handleAssignFolderDialog} />
            }
        </>
    )
};

export default AnnotatorListTable;
