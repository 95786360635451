import { configureStore } from '@reduxjs/toolkit';
import AnnotatorTimerReducer from './annotatorTimer';
import uiSlice from './uiSlice';
import reportImageSlice from './reportImageSlice';
import userDataSlice from './userDataSlice';

const store = configureStore({
    reducer: {
        timer: AnnotatorTimerReducer,
        ui: uiSlice,
        reportImage: reportImageSlice,
        userData: userDataSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
})


export default store;