import { useState } from "react";
import { Button, Popover } from "@mui/material";
import FDISystemTooth from "../../Table/FDISystemTooth";
import ToothTab from '../../ToothTab/ToothTab';
import { COUNTRY } from "../../../../constants/common.constant";
import ToothTable from "../../Table/ToothTable";

const ToothChartPopover = ({ selectTooth, teethData, appDetails }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <label className='mb-2 font-size-14 me-2'><b>Missing teeth:</b></label>
            <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                Open tooth chart
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className='p-2'>
                    {/* <ToothTab selectTooth={selectTooth} missing_teeth={teethData} /> */}
                    {/* <ToothTable selectTooth={selectTooth} missing_teeth={teethData} /> */}
                    {
                        appDetails?.app_country === COUNTRY.UNITED_STATES || appDetails?.app_country === COUNTRY.USA ? 
                        <ToothTable selectTooth={selectTooth} missing_teeth={teethData} /> :
                        <FDISystemTooth selectTooth={selectTooth} missing_teeth={teethData} />
                    }
                </div>
            </Popover>
        </div>
    )
}

export default ToothChartPopover;