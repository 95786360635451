import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { GetAnnotatedReports, GetAnnotatorUsers } from '../../../Services/reportService';

export default function AnnotatorFilter({ getFiltersData, getFilterState, getLoadingState, width, label }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        const annotatorUsers = await GetAnnotatorUsers();
        if (annotatorUsers.data.data.length > 0) {
            setOptions([...annotatorUsers.data.data]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={async (event, value) => {
        if (value?._id) {
            getFiltersData(value);
            getFilterState(true);
        } else {
            getFiltersData();
            getFilterState(false);
        }
      }}
      isOptionEqualToValue={(option, value) => option.first_name === value.first_name}
      getOptionLabel={(option) => option.first_name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
