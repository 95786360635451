import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { GetBucketFile } from '../../Services/reportService';
import { API_RESPONSE } from '../../constants/common.constant';
import { reportImageActions } from '../../store/reportImageSlice';


const ReportImage = ({ image, handleDialogBox, imageTypeName, bucket, isImageAnnotated }) => {

    const navigate = useNavigate();
    const getImagesFromStore = useSelector(state => state.reportImage);
    const [activeImage, setActiveImage] = useState(getImagesFromStore[`${bucket}/${image}`]);
    const [isImageFound, setIsImageFound] = useState(true);
    const dispatch = useDispatch();

    const getFilesFromServer = () => {
        if (!getImagesFromStore[`${bucket}/${image}`]) {
            GetBucketFile({ fileName: image, fileFolder: bucket, isImageAnnotated }).then((resData) => {
                if (resData.data.data !== API_RESPONSE.NO_DATA) {
                    setActiveImage(resData.data.data);
                    dispatch(reportImageActions.saveReportImage({ name: `${bucket}/${image}`, image: resData.data.data }));
                } else {
                    setIsImageFound(false);
                }
            })
            .catch((err) => {
                localStorage.clear();
                navigate('/');
            });
        }
    }

    useEffect(() => {
        getFilesFromServer();
    }, []);

    return (
        isImageFound && <div className='col-2 small-img position-relative'>
        {!activeImage && <Skeleton variant="rectangular" with="100%" height={200} />}
        {
            activeImage && (
                <>
                 <div className='text-uppercase font-size-12 primary-bg p-2 text-white text-truncate'
                    >{imageTypeName}</div>
                    <img
                        className='w-100 mb-3'
                        src={activeImage}
                        alt={imageTypeName}
                        onClick={handleDialogBox}
                    />
                </>
            )
        }
    </div>
    )
}

export default ReportImage;