import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SwipeableViews from 'react-swipeable-views';
import { Card, CardActions, CardContent, CardHeader, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ToothTable from '../Table/ToothTable';

function SwipeableCard(props) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [stepsData, setStepsData] = useState(props?.stepsData);
    const [isDisableSubmitBtn, setIsDisableSubmitBtn] = useState(true);
    const [isShowSelectToothOption, setIsShowSelectToothOption] = useState(false);
    const maxSteps = 3;

    useEffect(() => {
        if (props?.stepsData) {
            const selectedValue = props?.stepsData.steps1;
            const copyStepsData = { ...stepsData };
            handleFirstStep(selectedValue, copyStepsData, 'steps1');
            setIsDisableSubmitBtn(false);
            setStepsData(copyStepsData);
        } else {
            setStepsData();
        }

    }, []);

    const handleNext = () => {
        if (activeStep === maxSteps - 1) {
            // console.log('stepsData', stepsData);
            props.onSelectedTags(stepsData);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            stepsData[`steps${activeStep + 2}`] ? setIsDisableSubmitBtn(false) : setIsDisableSubmitBtn(true);
            document.getElementById(`teeth${stepsData.steps2}`)?.classList.add('primary-outline');
        };

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setIsDisableSubmitBtn(false);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleChange = (event, propertyName) => {
        const selectedValue = event.target.value;
        const copyStepsData = { ...stepsData };
        copyStepsData[propertyName] = selectedValue;

        handleFirstStep(selectedValue, copyStepsData, propertyName);

        setIsDisableSubmitBtn(false);
        setStepsData(copyStepsData);
    }

    const handleFirstStep = (selectedItem, copyStepsData, propertyName) => {
        if (propertyName === 'steps1') {
            switch (selectedItem) {
                case 'Cavity':
                    copyStepsData.color = '#FD636B';
                    setIsShowSelectToothOption(true);
                    break;
                case 'PB':
                    copyStepsData.color = '#FF7A59';
                    setIsShowSelectToothOption(false);
                    break;
                case 'DR':
                    copyStepsData.color = '#2EB670';
                    setIsShowSelectToothOption(true);
                    break;
                case 'GI':
                    copyStepsData.color = '#27507E';
                    setIsShowSelectToothOption(false);
                    break;
                case 'BT':
                    copyStepsData.color = '#3abfe2';
                    setIsShowSelectToothOption(true);
                    break;
                default:
                    copyStepsData.color = '#5fbadd';
                    break;
            }
        }
    }

    const handleCancel = () => {
        props.onSelectedTags({});
    };

    const selectTooth = (event) => {
        const getElement = document.getElementsByClassName('primary-outline')[0]?.classList.remove('primary-outline');
        event.target.classList.add('primary-outline');
        const copyStepsData = { ...stepsData };
        copyStepsData.steps2 = event.target.innerHTML;

        setIsDisableSubmitBtn(false);
        setStepsData(copyStepsData);
    }

    const onSubmit = () => { }

    return (
        <Box sx={{ maxWidth: activeStep + 1 === 2 && isShowSelectToothOption ? 415 : 250, flexGrow: 1 }}>
            <Card className='basic-card-container'>
                <div className={`p-1 gray-border-bottom ${activeStep === 0 ? 'px-2' : ''}`}>
                    <div className='font-size-13 primary-black font-weight-600'>
                        {
                            activeStep > 0 && (
                                <ChevronLeftIcon
                                    className='cursor-pointer'
                                    onClick={handleBack}
                                />
                            )
                        }
                        Select category
                    </div>
                    <div
                        className={`primary-color font-size-10 ${activeStep > 0 ? 'ms-4' : ''}`}
                    >Step {activeStep + 1} of 3</div>
                </div>
                <CardContent>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                    // enableMouseEvents
                    >
                        <div>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    onChange={($event) => handleChange($event, 'steps1')}
                                    value={stepsData?.steps1 ? stepsData?.steps1 : ""}
                                >
                                    <FormControlLabel value="Cavity" control={<Radio size='small' />} label="Cavity" />
                                    <FormControlLabel value="DR" control={<Radio size='small' />} label="Defective Restoration" />
                                    <FormControlLabel value="BT" control={<Radio size='small' />} label="Broken Tooth" />
                                    <FormControlLabel value="PB" control={<Radio size='small' />} label="Plaque Buildup" />
                                    <FormControlLabel value="GI" control={<Radio size='small' />} label="Gum Inflammation" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {
                            isShowSelectToothOption ? (
                                <div className='p-2'>
                                    <ToothTable selectTooth={selectTooth} />
                                </div>
                            ) : (
                                <div>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group1"
                                            onChange={($event) => handleChange($event, 'steps2')}
                                            value={stepsData?.steps2 ? stepsData?.steps2 : ""}
                                        >
                                            <FormControlLabel value="UR" control={<Radio size='small' />} label="UR" />
                                            <FormControlLabel value="UL" control={<Radio size='small' />} label="UL" />
                                            <FormControlLabel value="LL" control={<Radio size='small' />} label="LL" />
                                            <FormControlLabel value="LR" control={<Radio size='small' />} label="LR" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            )
                        }
                        {
                            isShowSelectToothOption ? (
                                <div>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group2"
                                            onChange={($event) => handleChange($event, 'steps3')}
                                            value={stepsData?.steps3 ? stepsData?.steps3 : ""}
                                        >
                                            <FormControlLabel value="O" control={<Radio size='small' />} label="Observe" />
                                            <FormControlLabel value="NT" control={<Radio size='small' />} label="Needs Treatment" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            ) : (
                                <div>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group2"
                                            onChange={($event) => handleChange($event, 'steps3')}
                                            value={stepsData?.steps3 ? stepsData?.steps3 : ""}
                                        >
                                            <FormControlLabel value="S" control={<Radio size='small' />} label="Slight" />
                                            <FormControlLabel value="M" control={<Radio size='small' />} label="Moderate" />
                                            <FormControlLabel value="SV" control={<Radio size='small' />} label="Severe" />
                                            {/* <FormControlLabel value="No" control={<Radio size='small' />} label="Non Observable" />
                                            <FormControlLabel value="NT" control={<Radio size='small' />} label="Needs Treatment" /> */}
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            )
                        }


                    </SwipeableViews>
                </CardContent>
                <CardActions className='justify-content-end'>
                    <Button variant="contained"
                        className='bg-danger text-capitalize'
                        size="small"
                        onClick={handleCancel}
                    >Cancel</Button>

                    <Button
                        variant="contained"
                        className='text-capitalize'
                        size="small"
                        // onClick={onSubmit}
                        onClick={handleNext}
                        disabled={isDisableSubmitBtn}
                    >{activeStep === maxSteps - 1 ? 'Submit' : 'Next'}</Button>
                </CardActions>
            </Card>

        </Box>
    );
}

export default SwipeableCard;