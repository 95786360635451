import axios from "axios";
import { API_HOST } from "./baseUrl";
import { getHeaders, handleError, handleSuccess } from "./common.service";

export const assignFolderAPI = (data) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(API_HOST + `tool/admin/assign-folder`, data, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};

export const getFolderImages = (data) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(API_HOST + `third-party-reports/list-folder-items`, data, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};

export const saveImageAnnotationData = (data) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(API_HOST + `annotated-images/save`, data, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};