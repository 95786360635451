import { useEffect, useState } from "react";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from "react-router-dom";

import { GetAnnotatedReports, GetReviewedReports } from "../../Services/reportService";
import AnnotatedReportList from "./AnnotatedReportList";
import { USER_TYPE } from "../../constants/common.constant";
import HeaderNav from "../../Components/UI/Header/HeaderNav";

const AnnotatedReport = () => {
    const navigate = useNavigate();
    const [reportListData, setReportListData] = useState([]);
    const [reportCount, setReportCount] = useState();
    const [annotatedReportCount, setAnnotatedReportCount] = useState();
    const user = JSON.parse(localStorage.getItem('user'));

    const handleError = () => {
        localStorage.clear();
        navigate('/')
    }

    useEffect(() => {
        if (!user) return navigate('/');

        if (user.user_type === USER_TYPE.ANNOTATOR || user.user_type === USER_TYPE.SUPER_ANNOTATOR) {
            GetAnnotatedReports().then(async (res) => {
                setReportListData(res.data.data);
                setAnnotatedReportCount(res.data.count)
            }, err => handleError());
        }

        
    }, []);

    const handlePagination = (data) => {
        GetReviewedReports({ pageIndex: data.pageIndex + 1, pageSize: 10 }).then((res) => {
            setReportListData(res.data.data);
            setReportCount(res.data.count);
        }, err => handleError());
    }

    return (
        <>
            <HeaderNav title="Annotated Report list" />
            <div className="container mt-5 pt-5">
                <div className="row">
                    <div className="col-12">
                        <AnnotatedReportList
                            btnLabel="Report"
                            btnUrl="report-list"
                            reportListData={reportListData}
                            reportCount={reportCount}
                            annotatedReportCount={annotatedReportCount}
                            handlePaginationData={user.user_type === USER_TYPE.ANNOTATOR || user.user_type === USER_TYPE.SUPER_ANNOTATOR ?
                                    () => {} : handlePagination}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnnotatedReport;