import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { Col, Row } from 'react-bootstrap';

import GapsInFrontTeethSection from './Components/GapsInFrontTeethSection.component';
import PoorAlignmentSection from './Components/PoorAlignmentSection.component';
import DarkTeethSection from './Components/DarkTeethSection.component';
import ToothChartPopover from './Components/ToothChartPopover.component';
import ImageUseableSection from './Components/ImageUseableSection.component';

const CHECKBOX_DATA_FOR_IMAGE_USEABLE = [
    {
        label: 'Blur',
        value: 'blur',
        size: 'small',
        checked: false
    },
    {
        label: 'Dark',
        value: 'dark',
        size: 'small',
        checked: false
    },
    {
        label: 'Bright',
        value: 'bright',
        size: 'small',
        checked: false
    },
    {
        label: 'Non-useable',
        value: 'non-useable',
        size: 'small',
        checked: false
    },
];

const POOR_ALIGNMENT_OPTIONS = [
    {
        label: 'crooked teeth',
        size: 'small',
        checked: false,
        value: 'crooked teeth'
    },
    {
        label: 'teeth in front',
        size: 'small',
        checked: false,
        value: 'teeth in front'
    },
    {
        label: 'teeth pushed back',
        size: 'small',
        checked: false,
        value: 'teeth pushed back'
    }
]

const DetectionListForm = ({ imageType, handleDetectionFormValues, detectionListFormData, isReviewer, handleFeedbackValue,
    missingTeethData, appDetails, isAligner, permissionData }) => {

    const [isUseableImage, setIsUseableImage] = useState(true);
    const [imageUseableData, setImageUseableData] = useState([]);
    const [teethData, setTeethData] = useState([]);
    // const [gapsData, setGapsData] = useState();
    const [poorAlignmentData, setPoorAlignmentData] = useState();
    const [darkTeethData, setDarkTeethData] = useState();
    const [defaultOptionsForUpperTeeth, setDefaultOptionForUpperTeeth] = useState(JSON.parse(JSON.stringify(POOR_ALIGNMENT_OPTIONS)));
    const [defaultOptionsForLowerTeeth, setDefaultOptionForLowerTeeth] = useState(JSON.parse(JSON.stringify(POOR_ALIGNMENT_OPTIONS)));
    const [checkboxDataForImageUseable, setCheckboxDataForImageUseable] = useState(CHECKBOX_DATA_FOR_IMAGE_USEABLE);

    const handleDefaultCheckbox = (array1, array2 = [], setValue) => {
        if (array2) {
            const data = array1.map((item, index) => {
                const itemIndex = array2.findIndex((item1) => item.value === item1);
                if (itemIndex > -1) {
                    item.checked = true;
                } else {
                    item.checked = false;
                }
                return item;
            });
            setValue(data);
        }
    }

    useEffect(() => {
        handleDefaultCheckbox(checkboxDataForImageUseable, detectionListFormData?.image_useable?.reason, setCheckboxDataForImageUseable);
        setIsUseableImage(detectionListFormData?.image_useable ? detectionListFormData?.image_useable?.is_image_useable : true);
        setImageUseableData(detectionListFormData?.image_useable ? detectionListFormData?.image_useable?.reason : []);
        setTeethData(missingTeethData ? missingTeethData : []);
        // setGapsData({
        //     upper_teeth: detectionListFormData?.gaps_in_teeth?.upper_teeth,
        //     lower_teeth: detectionListFormData?.gaps_in_teeth?.lower_teeth
        // });
        setDarkTeethData({
            upper_teeth: detectionListFormData?.dark_teeth?.upper_teeth,
            lower_teeth: detectionListFormData?.dark_teeth?.lower_teeth
        });

        if (detectionListFormData?.poor_alignment?.upper_teeth || detectionListFormData?.poor_alignment?.lower_teeth) {
            setPoorAlignmentData({
                upper_teeth: detectionListFormData?.poor_alignment?.upper_teeth,
                lower_teeth: detectionListFormData?.poor_alignment?.lower_teeth
            })
        } else {
            setPoorAlignmentData();
        }

        handleDefaultCheckbox(defaultOptionsForUpperTeeth, detectionListFormData?.poor_alignment?.upper_teeth, setDefaultOptionForUpperTeeth);
        handleDefaultCheckbox(defaultOptionsForLowerTeeth, detectionListFormData?.poor_alignment?.lower_teeth, setDefaultOptionForLowerTeeth);
    }, [detectionListFormData]);

    function handleSwitchChange(event) {
        setIsUseableImage(event.target.checked);
        handleDetectionFormValues({ name: 'image_useable', value: { is_image_useable: event.target.checked, reason: '' } });
    }

    const selectTooth = (event) => {
        const data = [...teethData];
        event.target.classList.toggle('teeth-selected-color');
        const findIndex = data.findIndex((item) => item === event.target.innerHTML);
        if (findIndex > -1) {
            data.splice(findIndex, 1);
        } else {
            data.push(event.target.innerHTML);
        }
        setTeethData(tooth => [...new Set(data)]);
        handleDetectionFormValues({ name: 'missing_teeth', value: [...new Set(data)] });
    }

    const handleImageUseable = (event, index) => {
        checkboxDataForImageUseable[index].checked = event.target.checked;
        const data = [...imageUseableData];
        if (event.target.checked) {
            data.push(event.target.id);
        } else {
            const index = data.findIndex((item) => item === event.target.id);
            data.splice(index, 1);
        }
        setImageUseableData(tooth => [...data]);
        handleDetectionFormValues({ name: 'image_useable', value: { is_image_useable: false, reason: data } })
    }

    // const handleGapsChanges = (event, upperOrLowerTeeth) => {
    //     let data = gapsData ? gapsData : {};
    //     data[upperOrLowerTeeth] = event.target.checked;
    //     setGapsData({ ...gapsData, [upperOrLowerTeeth]: event.target.checked });
    //     handleDetectionFormValues({ name: 'gaps_in_teeth', value: data });
    // }

    const handlePoorAlignmentChanges = (event, upperOrLowerTeeth, index, options, setValue) => {
        let data = poorAlignmentData ? poorAlignmentData : { upper_teeth: [], lower_teeth: [] };
        const optionsData = [...options];
        optionsData[index].checked = event.target.checked;
        setValue([...optionsData]);
        if (upperOrLowerTeeth === 'upper_teeth') {
            if (event.target.checked) {
                data.upper_teeth.push(event.target.name);
            } else {
                const index = data.upper_teeth.findIndex((item) => item === event.target.name);
                data.upper_teeth.splice(index, 1);
            }
        } else {
            if (event.target.checked) {
                data.lower_teeth.push(event.target.name);
            } else {
                const index = data.lower_teeth.findIndex((item) => item === event.target.name);
                data.lower_teeth.splice(index, 1);
            }
        }
        setPoorAlignmentData(data);
        handleDetectionFormValues({ name: 'poor_alignment', value: data });
    }

    const handleDarkTeethChanges = (event, upperOrLowerTeeth) => {
        let data = darkTeethData ? darkTeethData : { upper_teeth: 'normal', lower_teeth: 'normal' };
        if (upperOrLowerTeeth === 'upper_teeth') {
            data.upper_teeth = event.target.value;
        } else {
            data.lower_teeth = event.target.value;
        }
        setDarkTeethData({ ...darkTeethData, [upperOrLowerTeeth]: event.target.value });
        handleDetectionFormValues({ name: 'dark_teeth', value: data });
    }

    const handleFeedbackChange = (event) => {
        handleFeedbackValue(event.target.value);
    }

    return (
        <Row>
            {
                isReviewer && (
                    <div className='my-4'>
                        <TextField
                            id="outlined-multiline-static"
                            label="Feedback"
                            className='w-100'
                            multiline
                            rows={4}
                            onChange={handleFeedbackChange}
                        />
                    </div>
                )
            }
            {
                permissionData?.isImageUseableAccess && (
                    <Col xs={6}>
                        <ImageUseableSection
                            handleSwitchChange={handleSwitchChange}
                            isUseableImage={isUseableImage}
                            checkboxDataForImageUseable={checkboxDataForImageUseable}
                            handleImageUseable={handleImageUseable}
                        />
                    </Col>
                )
            }

            {
                isUseableImage && !isAligner && (
                    <>
                        {
                            permissionData?.isMissingTeethAccess && (
                                <Col xs={6} className='my-1'>
                                    <ToothChartPopover appDetails={appDetails} selectTooth={selectTooth} teethData={teethData} />
                                </Col>
                            )
                        }

                        {/* <Col xs={12}>
                            <GapsInFrontTeethSection
                                handleGapsChanges={handleGapsChanges}
                                upper_teeth={gapsData?.upper_teeth}
                                lower_teeth={gapsData?.lower_teeth}
                            />
                        </Col> */}
                        {
                            permissionData?.isPoorAlignmentAccess && (
                                <Col xs={12}>
                                    <PoorAlignmentSection
                                        handlePoorAlignmentChanges={handlePoorAlignmentChanges}
                                        defaultOptionsForUpperTeeth={defaultOptionsForUpperTeeth}
                                        defaultOptionsForLowerTeeth={defaultOptionsForLowerTeeth}
                                        setDefaultOptionForUpperTeeth={setDefaultOptionForUpperTeeth}
                                        setDefaultOptionForLowerTeeth={setDefaultOptionForLowerTeeth}
                                    />
                                </Col>
                            )
                        }
                        {
                            permissionData?.isDarkTeethAccess && (
                                <Col xs={12}>
                                    <DarkTeethSection
                                        handleDarkTeethChanges={handleDarkTeethChanges}
                                        upper_teeth={darkTeethData?.upper_teeth}
                                        lower_teeth={darkTeethData?.lower_teeth}
                                    />
                                </Col>
                            )
                        }

                    </>
                )
            }
        </Row>
    )
}

export default DetectionListForm;