import ReportListTable from "../ReportList/ReportListTable";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { ReviewReportGetAPI, getPoorAlignmentReportsAPI } from "../../Services/reportService";
import HeaderNav from "../../Components/UI/Header/HeaderNav";
import { USER_TYPE } from "../../constants/common.constant";

const PoorAlignmentReports = () => {
    const [reportListData, setReportListData] = useState([]);
    const [reportCount, setReportCount] = useState();
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));

    const getPoorAlignmentReports = useCallback( async (data, signal) => {
        try {
            const reports = await getPoorAlignmentReportsAPI(data, signal);
            setReportListData(reports.data.data);
            setReportCount(reports.data.count);
        } catch (error) {
            localStorage.clear();
            navigate('/')
        }
    }, []);

    useEffect(() => {
        if (!user) return navigate('/');
        // console.log("use effect");
        // const abortController = new AbortController();
        // const signal = abortController.signal;
        // console.log('abort signal', abortController);
        // getPoorAlignmentReports(signal);

        // return () => abortController.abort();
    }, []);

    const handlePagination = (data) => {
        getPoorAlignmentReports({ pageIndex: data.pageIndex + 1, pageSize: data.pageSize })
    }

    return (
        <>
            <HeaderNav title="Report List" />
            <div className="container mt-5 pt-5">
                <div className="row">
                    <div className="col-12">
                        <ReportListTable
                            btnLabel="Annotated reports"
                            btnUrl="annotated-report"
                            reportListData={reportListData}
                            isAnnotator={user?.user_type === USER_TYPE.ANNOTATOR ? true : false}
                            isGapsInFrontTeethReports={true}
                            reportCount={reportCount}
                            handlePaginationData={handlePagination}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PoorAlignmentReports;