import React from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import './zoom.css'
import { SideBySideMagnifier } from '@vanyapr/react-image-magnifiers'

function TestingZoomFeature({ image }) {
  return (
    <div>
      {/* <TransformWrapper className="w-100 h-100">
        <TransformComponent style={{ width: "752px", height: "612px" }}>
          <img
            width="752"
            height="612"
            src={image}
            id="screenshot"
            alt="report"
            draggable="false"
          />
        </TransformComponent>
      </TransformWrapper> */}


      <SideBySideMagnifier
        imageSrc={image}
        imageAlt="report"
        imageId="screenshot"
        imageHeight="612"
        className={`w-100`}
        // id="screenshot"
        // alt="report"
        // draggable="false"
        // ref={imgRef}
        style={{
          width: "100%", height: "612px !important"
        }}
        onmousemove={() => { }}
        renderOverlay={() => { }}
      />

    </div>
  )
}

export default TestingZoomFeature