import React from 'react';
// import Canvas from './Components/Canvas.component';
import CanvasExample from './Components/Canvas1.component';
import Canvas from './containers/Canvas';

export function AnnotationWithCanvas() {
  return (
    <div>
      {/* <h1>New Annotation Tool</h1> */}
      {/* <CanvasExample /> */}
      {/* <Canvas /> */}
      <Canvas />
    </div>
  )
}