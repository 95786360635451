import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";


const GoOrNoGoRadio = ({ reportStatus, handleReportStatus }) => {

    const [radioValue, setRadioValue] = useState(reportStatus);

    const handleRadioInput = (event) => {
        setRadioValue(event.target.value);
        handleReportStatus(event.target.value);
    }

    return (
        <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={radioValue}
        >
            <FormControlLabel onChange={handleRadioInput} value={1} control={<Radio />} label="Go" />
            <FormControlLabel onChange={handleRadioInput} value={2} control={<Radio />} label="No Go" />
            <FormControlLabel onChange={handleRadioInput} value={3} control={<Radio />} label="Reshoot" />
        </RadioGroup>
    )
}

export default GoOrNoGoRadio;