import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import { USER_TYPE } from "../../constants/common.constant";
import HeaderNav from "../../Components/UI/Header/HeaderNav";
import ReportListTable from "./ReportListTable";
import { getThirdPartyReportsAPI } from "../../Services/thirdPartyService";


const ThirdPartyReportsList = () => {
    const navigate = useNavigate();
    const [reportListData, setReportListData] = useState();
    const [reportCount, setReportCount] = useState();
    const user = JSON.parse(localStorage.getItem('user'));
    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState([]);

    const fetchPosts = useCallback(async controller => {
        try {
          // Imagine that the fetch is going to take 3 seconds to finish
          await new Promise(resolve => setTimeout(resolve, 3000))
          const response = await fetch('https://jsonplaceholder.typicode.com/posts', { signal: controller.signal })
          const posts = await response.json()
          setPosts(posts)
        } catch (error) {
          // When abort() is called, the fetch() promise rejects with an AbortError.
          if (error.name === 'AbortError') {
            // It's ok, don't do anything
            console.log('Request aborted!')
          } else {
            console.error(error)
          }
        } finally {
          if (!controller.signal.aborted) {
            setIsLoading(false)
          }
        }
      }, [])

    const getThirdPartyReports = async (signal) => {
        try {
            const reports = await getThirdPartyReportsAPI(signal);
            setReportListData(reports.data.data);
        } catch (error) {
        }
    };

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;
        // getThirdPartyReports(signal);
        setReportListData([])

        return () => {
            abortController.abort();
        }

    }, []);

    // useEffect(() => {
    //     const controller = new AbortController()
    //     fetchPosts(controller)
    //     return () => controller.abort()
    //   }, [fetchPosts])

    return (
        <>
            <HeaderNav title="Third Party Reports" />
            <div className="container mt-5 pt-5">
                <div className="row">
                    <div className="col-12">
                        <ReportListTable
                            // btnLabel="Annotated reports"
                            // btnUrl="annotated-report"
                            reportListData={reportListData}
                            reportCount={reportCount}
                            isAnnotator={user?.user_type === USER_TYPE.ANNOTATOR ? true : false}
                            handlePaginationData={user?.user_type === USER_TYPE.REVIEWER ? () => {} : () => { }}
                            isThirdPartyReports={true}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ThirdPartyReportsList;