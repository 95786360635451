import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

const reportImageSlice = createSlice({
    name: 'reportImage',
    initialState,
    reducers: {
        saveReportImage(state, action) {
            state[action.payload.name] = action.payload.image;
        }
    }
});

export const reportImageActions = reportImageSlice.actions;
export default reportImageSlice.reducer;