import axios from "axios";
import { API_HOST } from "./baseUrl";

export const userLogin = (userData) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `tool/login`, userData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};