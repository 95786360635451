import React from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";

const GapsInFrontTeethSection = ({ handleGapsChanges, upper_teeth, lower_teeth }) => {
    return (
        <div className='my-1'>
            <label className='font-size-14'><b>Gaps in teeth:</b></label>
            <table className='table text-center select-tooth-table font-size-14'>
                <thead>
                    <tr>
                        <th>Upper teeth</th>
                        <th>Lower teeth</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='text-start'>
                        <td>
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={($event) => handleGapsChanges($event, 'upper_teeth')}
                                                name="gap1"
                                                size="small"
                                                value="gaps"
                                                checked={upper_teeth ? true : false}
                                            />
                                        }
                                        label="gap"
                                    />
                                </FormGroup>
                            </FormControl>
                        </td>
                        <td>
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={($event) => handleGapsChanges($event, 'lower_teeth')}
                                                name="gap2"
                                                size="small"
                                                checked={lower_teeth ? true : false}
                                            />
                                        }
                                        label="gap"
                                    />
                                </FormGroup>
                            </FormControl>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default GapsInFrontTeethSection;