import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FolderCopyOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export default function ListFolders({ folderData }) {
    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <Card className='border shadow' color='info'>
            <CardContent>
                <Typography sx={{ fontSize: 14, fontWeight: "bold" }} color="text.primary" gutterBottom>
                    <FolderCopyOutlined /> {folderData}
                </Typography>
            </CardContent>
            <CardActions>
                <div className='row w-100 m-0'>
                    {
                        folderData === 'toothlens-image-store/kids-toothlens-dataset-v2/input' && (
                            <div className='col-6 text-center'>
                                <Link to="/list-annotated-images"
                                    state={{ data: folderData }}
                                    className='w-100'
                                >
                                    <Button size="w-100 small" variant='contained'>View annotated images</Button>
                                </Link>
                            </div>
                        )
                    }
                    {/* <div className='col-6 text-center'>
                        <Link to="/list-images"
                            state={{ data: folderData }}
                            className='w-100'
                        >
                            <Button className='w-100 h-100 text-center' size="small" variant='contained'>View</Button>
                        </Link>
                    </div> */}
                    {
                        folderData !== 'toothlens-image-store/kids-toothlens-dataset-v2/input' && (
                            <div className='col-6 text-center'>
                                <Link to="/list-images-for-annotation"
                                    state={{ data: folderData }}
                                    className='w-100'
                                >
                                    <Button className='w-100 h-100 text-center' size="small" variant='contained'>Images for annotation</Button>
                                </Link>
                            </div>
                        )
                    }

                </div>
            </CardActions>
        </Card>
    );
}