import axios from "axios";
import { API_HOST } from "./baseUrl";

const handleSuccess = (response, resolve, reject) => {
    if (response.status === 200) {
        return resolve(response);
    }
    reject(response);
}

const handleError = (error, reject) => {
    if (error && error.response) {
        reject({ ...error.response, isError: true });
    } else {
        reject({ message: 'Network error', isError: true });
    }
}

const getHeaders = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: user?.authToken,
    };
    return headers;
}

const callAPI = (method, url, reqData) => {
    const headersData = getHeaders();
    return new Promise((resolve, reject) => {
        return axios
            [method](API_HOST + url, { headers: headersData })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
}

export const getAllAlignerReports = (pageIndex, pageSize) => {
    const url = `aligner/get-report/${pageIndex}/${pageSize}`;
    return callAPI('get', url);
    // return new Promise((resolve, reject) => {
    //     return axios
    //         .get(API_HOST + `aligner/get-report`, { headers: getHeaders() })
    //         .then((response) => {
    //             handleSuccess(response, resolve, reject);
    //         })
    //         .catch((error) => {
    //             handleError(error, reject);
    //         });
    // });
};

export const getAlignerUserById = (userId) => {
    return new Promise((resolve, reject) => {
        return axios
            .get(API_HOST + `aligner/user/${userId}`, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};

export const submitAlignerReportData = (data) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(API_HOST + `aligner/report-reviewed`, data, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};

export const getAlignerVideo = (fileName) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(API_HOST + `aligner/get-video`, {fileName}, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};