import { Box, Button, CssBaseline } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import HeaderNav from "../../Components/UI/Header/HeaderNav";
import ReportImage from "../../Components/Report/ReportImage.component";
import ReportImageInDialogBox from "../../Components/UI/Dialog/ReportImageInDialogBox";
import { getAnnotatedImagesAPI } from "../../Services/annotator.service";

const ListAnnotatedImages = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [images, setImages] = useState([]);
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false);
    const [imageData, setImageData] = useState();
    const [completedImagesObj, setCompletedImagesObj] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isAllImagesCompleted, setIsAllImagesCompleted] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [imagesCount, setImagesCount] = useState({
        totalAnnotatedImagesCount: 0,
        totalImagesToAnnotateCount: 0
    });
    const [reportId, setReportId] = useState();

    // Getting bucket, subFolder and txtSubFolder
    const folderData = location?.state?.data?.split("/");
    const bucket = folderData.splice(0, 1)[0];
    const subFolder = folderData.join("/");
    const annotatedUseableImagesSubFolder = `${subFolder}/annotated-images/useable-images`;
    const txtSubFolder = `${subFolder.split("/")[0]}/output`;
    // Getting Total pages
    const totalPages = Math.ceil(imagesCount?.totalImagesToAnnotateCount / 10);

    const getImages = async () => {
        setIsLoading(true);
        try {
            const results = await getAnnotatedImagesAPI({
                bucket,
                subFolder,
                pageIndex,
                pageSize: 10,
            });
            if (results?.data?.data.length) {
                // const imagesData = results.data.data.map((img) => img.image_name);
                setImagesCount(results?.data?.count)
                setIsAllImagesCompleted(false);
                setImages(results.data.data);
            } else {
                setIsAllImagesCompleted(true);
            }
            setIsLoading(false);
        } catch (error) {
            localStorage.clear();
            navigate("/");
        }
    };

    useEffect(() => {
        getImages();
    }, [pageIndex]);

    const handleDialogBox = (index, image, typesOfImages, reportId) => {
        setImageData({ image, imageType: "" });
        setIsDialogBoxOpen(true);
        setReportId(reportId);
    };

    const handleCloseDialogBox = (dialogData) => {
        if (dialogData) {
            setCompletedImagesObj({ ...completedImagesObj, ...dialogData });
            getImages();
        }
        setIsDialogBoxOpen(false);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <HeaderNav title="Toothlens Annotation Tool" />

            <div className="container mt-5 pt-5">
                <div className="row my-3 fw-bold">
                    <div className="col-6">
                        <div>Total Images left: {imagesCount?.totalImagesToAnnotateCount}</div>
                        {/* <div>Total Images: 562</div> */}
                        {/* <div>Total Images: {imagesCount?.totalAnnotatedImagesCount}</div> */}
                    </div>
                </div>
                <div className="row">
                    {images.map((img, index) => {
                        return !completedImagesObj[img.image_name.split('/')[2]] && (
                            <ReportImage
                                key={img.image_name + index}
                                bucket={`${bucket}/${annotatedUseableImagesSubFolder}`}
                                image={img.image_name}
                                imageTypeName={img.image_name}
                                handleDialogBox={() => handleDialogBox(index, img.image_name, [], img._id)}
                                isImageAnnotated={false}
                            />
                        )
                    })}
                    {isAllImagesCompleted && <p className="text-center">Images are not remaining</p>}
                </div>
                <div className="my-3 text-center">
                    {/* Loading Text */}
                    {isLoading && <span>Loading...</span>}
                    {/* Page number */}
                    {!isLoading && <div>Current Page: {pageIndex}/{totalPages}</div>}
                    {/* Pagination Buttons */}
                    {!isLoading && pageIndex > 1 && <Button className="me-2" variant='contained'
                        onClick={() => setPageIndex((prevState) => prevState - 1)}
                    >prev</Button>}
                    {(!isLoading && totalPages !== pageIndex) &&
                        <Button variant='contained'
                            onClick={() => setPageIndex((prevState) => prevState + 1)}
                        >Next</Button>}
                </div>
            </div>

            {isDialogBoxOpen && (
                <>
                    {
                        <ReportImageInDialogBox
                            bucketName={bucket}
                            JSONBucketName="toothlens-image-store"
                            JSONFolderName="json_output"
                            subFolderName={subFolder}
                            txtFolderName={txtSubFolder}
                            isDialogBoxOpen={isDialogBoxOpen}
                            imageData={imageData}
                            handleCloseDialogBox={handleCloseDialogBox}
                            missingTeeth={[]}
                            isAligner={false}
                            isGapsInFrontTeethReports={false}
                            isThirdPartyReports={false}
                            isImageAnnotation={true}
                            filePath={annotatedUseableImagesSubFolder}
                            reportId={reportId}
                        />
                    }
                </>
            )}
        </Box>
    );
};

export default ListAnnotatedImages;
