import { Box, Button } from '@mui/material';
import { addDays } from 'date-fns';
import { useState } from 'react';
import { DateRange, DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export default function BasicDateRangePicker({ onDateChange }) {
    const [isShowDatePicker, setIsShowDatePicker] = useState(false);
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    const handleChange = (item) => {
        setState([item.selection])
    }

    return (
        <Box>
            <Button onClick={() => setIsShowDatePicker(prevState => !prevState)} variant='contained'>Start Date - End Date</Button>
            <div>
                {
                    isShowDatePicker && <>
                        <DateRange
                            editableDateInputs={false}
                            onChange={handleChange}
                            moveRangeOnFirstSelection={true}
                            ranges={state}
                            months={1}
                        />
                        <div className='text-center'>
                            <Button variant='contained' onClick={() => onDateChange(state)}>Apply</Button>
                        </div>
                    </>
                }
            </div>
        </Box>
    );
}