import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { Alert, Button, Snackbar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { GetReportByAnnotatorId, GetReviewedReportByReportId, submitReportData } from '../../Services/reportService';
import {
    ALIGNER_DEFAULT_SUB_FOLDER,
    ALIGNER_JSON_DEFAULT_FOLDER,
    DEFAULT_BUCKET,
    DEFAULT_SUB_FOLDER,
    DEFAULT_TXT_SUB_FOLDER,
    JSON_DEFAULT_FOLDER,
    REPORT_IMAGE_TYPES,
    REPORT_IMAGE_TYPES_FOR_ALIGNER,
    USER_TYPE
} from '../../constants/common.constant';
import { getItemFromLocalStorage } from '../../utility/localStorage.utility';
import { submitAlignerReportData } from '../../Services/alignerService';
import { handleBeforeunloadEvent, handleRemoveBeforeUnloadEvent } from './common/common.utils';
import ReportImageInDialogBox from '../../Components/UI/Dialog/ReportImageInDialogBox';
import AlertDialog from '../../Components/UI/Dialog/AlertDialog';
import ReportImage from '../../Components/Report/ReportImage.component';
import AlignerHeading from '../../Components/Headings/AlignerHeading';
import HeaderNav from '../../Components/UI/Header/HeaderNav';
import HandleSubmitReport from './Components/HandleSubmitReport.component';
import GoOrNoGoRadio from '../../Components/UI/Radio/GoOrNoGoRadio';
import { saveJSONData } from '../../Services/thirdPartyService';

export default function AnnotationTool(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const reportImagesType = REPORT_IMAGE_TYPES;
    const alignerImagesType = REPORT_IMAGE_TYPES_FOR_ALIGNER;
    const locationData = location.state.data;
    // const [locationData, setLocationData] = useState(location.state.data);
    // const [reportImagesType, setReportImagesType] = useState(['front_teeth', 'upper_jaw', 'lower_jaw', 'left_side', 'right_side']);
    const [imageAngle, setImageAngle] = useState();
    const [activeImage, setActiveImage] = useState();
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false);
    const [imageData, setImageData] = useState();
    const [snackbarData, setSnackbarData] = useState();
    const [isShowAlertDialog, setIsShowAlertDialog] = useState(false);
    const [missingTeethData, setMissingTeethData] = useState([]);
    const [reportStatus, setReportStatus] = useState(1);
    const user = JSON.parse(getItemFromLocalStorage('user'));
    const [subFolder, setSubFolder] = useState(user?.user_type === USER_TYPE.ALIGNER ? ALIGNER_DEFAULT_SUB_FOLDER : DEFAULT_SUB_FOLDER);
    let defaultJSONFolder = user?.user_type === USER_TYPE.ALIGNER ? ALIGNER_JSON_DEFAULT_FOLDER : JSON_DEFAULT_FOLDER;

    // const getReviewedReportsByReportId = async () => {
    //     try {
    //         // const response = await GetReviewedReportByReportId(locationData._id);
    //         // if (response?.data?.data !== 'No Data' && response?.status === 200) {
    //         //     // const getAnnotatorMissingTeeth = response?.data?.data.report_tags_data;
    //         //     // const missingTeeth = [...getAnnotatorMissingTeeth].flatMap(teeth => teeth.missing_teeth);
    //         //     // setMissingTeethData(missingTeeth);
    //         // }
    //     } catch (error) {
    //         navigate('/report-list');
    //     }
    // }

    const getReportByAnnotatorId = async () => {
        try {
            const report = await GetReportByAnnotatorId(locationData?._id);
            if (report?.data?.data === 'No Data' && report?.status === 200) {
                navigate('/report-list');
            }
        } catch (error) {
            navigate('/report-list');
        }
    }

    const handleUsers = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            return navigate('/report-list');
        } else if (user?.user_type === USER_TYPE.ANNOTATOR) {
            if (!locationData?.isThirdPartyReports && !locationData.isGapsInFrontTeethReports) getReportByAnnotatorId();
            return;
        } else if (user?.user_type === USER_TYPE.ALIGNER) {
            return;
        } else if (user?.user_type === USER_TYPE.ADMIN) {
            return;
        } else if (user?.user_type === USER_TYPE.REVIEWER || user?.user_type === USER_TYPE.SUPER_ANNOTATOR) {
            // return getReviewedReportsByReportId();
            return;
        }

        return navigate('/report-list');
    }

    useEffect(() => {
        handleUsers();
        // Handle before unload event
        handleBeforeunloadEvent();

        return () => {
            // Remove before unload event once we leave this page
            handleRemoveBeforeUnloadEvent();
        }

    }, []);

    function handleDialogBox(index, imageType, typesOfImages) {
        let img = imageType;
        if (!locationData?.isThirdPartyReports) {
            const splitFolderData = user?.user_type === USER_TYPE.ALIGNER ?
                locationData.images[0][typesOfImages[index]].split('/') :
                locationData[typesOfImages[index]].split('/');
            img = splitFolderData.pop();
            const sub = splitFolderData.join('/');
            setSubFolder(sub);

        }
        setImageData({ image: img, imageType });
        setIsDialogBoxOpen(true);
    }

    function handleCloseDialogBox(data) {
        setIsDialogBoxOpen(false);
    }

    function handleAlertDialog() {
        setIsShowAlertDialog(true);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarData(null);
    }

    const handleSubmitRedirection = () => {
        if (locationData?.isThirdPartyReports) {
            return '/third-party-reports'
        } else if (locationData?.isGapsInFrontTeethReports) {
            return '/poor-alignment-reports'
        }
        return '/report-list';
    }

    const successHandler = (res) => {
        if (res?.data?.code === 200) {
            localStorage.removeItem('teeth');
            setSnackbarData({ message: res.data.message, severity: 'success' });
            setTimeout(() => {
                const routePath = handleSubmitRedirection();
                navigate(routePath);
            }, 1000);
        }
    }

    const errorHandler = () => {
        localStorage.clear();
        navigate('/');
        setSnackbarData({ message: 'Report not saved', severity: 'error' });
    }

    const handleReportSubmitForAligner = () => {
        const reqData = {
            aligner: user._id,
            reportId: locationData._id,
            reportStatus,
            ...locationData
        }
        delete reqData._id;
        submitAlignerReportData(reqData)
            .then((res) => successHandler(res))
            .catch((err) => errorHandler());
    }

    const handleReportSubmitForReviewerAndAnnotator = () => {
        const reqData = {
            report: locationData._id,
            annotator: user._id,
            ...location.state.data,
            userId: user._id,
            isUpdatingReport: locationData?.isGapsInFrontTeethReports ? true : false
        }
        submitReportData(reqData)
            .then((res) => successHandler(res))
            .catch((err) => errorHandler());
    }

    const handleThirdPartyReportSubmit = () => {
        const reqData = {
            report: locationData._id,
            annotator: user._id,
            ...location.state.data,
            userId: user._id
        }
        saveJSONData(reqData)
            .then((res) => successHandler(res))
            .catch((err) => errorHandler());
    }

    const handleSubmit = () => {

        if (locationData?.isThirdPartyReports) {
            handleThirdPartyReportSubmit();
        } else {
            if (user.user_type === USER_TYPE.ALIGNER) {
                handleReportSubmitForAligner();
            } else {
                handleReportSubmitForReviewerAndAnnotator();
            }
        }
        setIsShowAlertDialog(false);
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <HeaderNav title="Toothlens Annotation Tool" />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                <Snackbar
                    open={snackbarData?.message ? true : false}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert onClose={handleSnackbarClose} variant="filled" severity={snackbarData?.severity} sx={{ width: '100%' }}>
                        {snackbarData?.message}
                    </Alert>
                </Snackbar>
                <div className='container-fluid'>
                    <div className='row justify-content-center'>
                        <div className='col-10 offset-1 position-relative'>
                            <div className='row my-1'>
                                {/* Hygiene report images */}
                                {
                                    !locationData?.isThirdPartyReports && user?.user_type !== USER_TYPE.ALIGNER && (
                                        <div className='col-12 mb-5'>
                                            <div className='row'>
                                                {
                                                    reportImagesType.map((item, index) => (
                                                        <ReportImage
                                                            key={item}
                                                            bucket={DEFAULT_BUCKET}
                                                            image={`${locationData[item]}`}
                                                            imageTypeName={reportImagesType[index]}
                                                            handleDialogBox={() => handleDialogBox(index, reportImagesType[index], reportImagesType)}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    )
                                }

                                {/* Third party report images */}
                                {
                                    locationData?.isThirdPartyReports && (
                                        <div className='col-12 mb-5'>
                                            <div className='row'>
                                                {
                                                    locationData.images.map((item, index) => (
                                                        <ReportImage
                                                            key={item}
                                                            bucket={`${locationData?.bucket_name}/${locationData?.folder_name}`}
                                                            image={item}
                                                            imageTypeName={locationData.images[index]}
                                                            handleDialogBox={() => handleDialogBox(index, locationData.images[index], [])}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    )
                                }

                                {/* Aligner report images */}
                                {
                                    user?.user_type === USER_TYPE.ALIGNER && (
                                        <>
                                            <AlignerHeading title="Without aligner" />
                                            <div className='col-12'>
                                                <div className='row'>
                                                    {
                                                        reportImagesType.map((item, index) => (
                                                            <ReportImage
                                                                key={item}
                                                                bucket={`${DEFAULT_BUCKET}/${ALIGNER_DEFAULT_SUB_FOLDER}`}
                                                                image={`${locationData.images[0][item].split('/')[1]}`}
                                                                imageTypeName={reportImagesType[index].split('_').join(' ')}
                                                                handleDialogBox={() => handleDialogBox(index, reportImagesType[index], reportImagesType)}
                                                            />
                                                        ))
                                                    }
                                                </div>
                                            </div>

                                            <AlignerHeading title="With aligner" />
                                            <div className='col-12'>
                                                <div className='row'>
                                                    {
                                                        alignerImagesType.map((item, index) => (
                                                            <ReportImage
                                                                key={item}
                                                                bucket={`${DEFAULT_BUCKET}/${ALIGNER_DEFAULT_SUB_FOLDER}`}
                                                                image={`${locationData.images[0][item].split('/')[1]}`}
                                                                imageTypeName={alignerImagesType[index].split('_').join(' ')}
                                                                handleDialogBox={() => handleDialogBox(index, alignerImagesType[index], alignerImagesType)}
                                                            />
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className='text-end my-2'>
                                                <GoOrNoGoRadio reportStatus={reportStatus} handleReportStatus={setReportStatus} />
                                            </div>
                                        </>
                                    )
                                }
                                {/* Submit dialog box */}
                                {
                                    isShowAlertDialog && <AlertDialog
                                        message="Are you sure you want to submit it?"
                                        open={isShowAlertDialog}
                                        onClose={() => setIsShowAlertDialog(false)}
                                        onSubmit={handleSubmit}
                                    />
                                }
                                <div className='col-9 offset-1 text-end my-3'>
                                    {/* <HandleSubmitReport
                                        user={user}
                                        handleAlertDialog={handleAlertDialog}
                                        reportStatus={reportStatus}
                                        setReportStatus={setReportStatus}
                                    /> */}
                                    <Button variant="contained" onClick={handleAlertDialog}>Submit</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
            {
                isDialogBoxOpen && (
                    <>
                        {<ReportImageInDialogBox
                            bucketName={!locationData?.isThirdPartyReports ? DEFAULT_BUCKET : `${locationData?.bucket_name}`}
                            JSONBucketName={DEFAULT_BUCKET}
                            JSONFolderName={defaultJSONFolder}
                            subFolderName={!locationData?.isThirdPartyReports ? subFolder : locationData.folder_name}
                            txtFolderName={DEFAULT_TXT_SUB_FOLDER}
                            isDialogBoxOpen={isDialogBoxOpen}
                            imageData={imageData}
                            handleCloseDialogBox={handleCloseDialogBox}
                            missingTeeth={missingTeethData}
                            isAligner={user?.user_type === USER_TYPE.ALIGNER ? true : false}
                            isGapsInFrontTeethReports={locationData?.isGapsInFrontTeethReports ? true : false}
                            isThirdPartyReports={locationData?.isThirdPartyReports ? true : false}
                            reportId={locationData._id}
                        />}
                    </>
                )
            }
        </Box>
    );
}