export const USER_TYPE = {
    ADMIN: 'admin',
    ANNOTATOR: 'annotator',
    REVIEWER: 'reviewer',
    ALIGNER: 'aligner',
    SUPER_ANNOTATOR: 'super_annotator'
}

export const API_RESPONSE = {
    NO_DATA: 'No Data'
}

export const IMAGE_TAGS = {
    IMAGE_USEABLE: 'image_useable',
    MISSING_TEETH: 'missing_teeth',
    GAPS_IN_TEETH: 'gaps_in_teeth',
    POOR_ALIGNMENT: 'poor_alignment',
    DARK_TEETH: 'dark_teeth'
}

export const TEETH_TYPES = {
    FRONT_TEETH: 'front_teeth',
    UPPER_JAW: 'upper_jaw',
    LOWER_JAW: 'lower_jaw',
    LEFT_SIDE: 'left_side',
    RIGHT_SIDE: 'right_side'
}

export const FILE_OPTION = {
    WEB_URL: 'webUrl',
    UPLOAD: 'upload'
}

export const COUNTRY = {
    USA: 'USA',
    UNITED_STATES: 'United States'
}

export const DEFAULT_BUCKET = "toothlens-image-store";
export const JSON_DEFAULT_FOLDER = "json_output";
export const ALIGNER_JSON_DEFAULT_FOLDER = "aligner_json_output";
export const DEFAULT_SUB_FOLDER = "input";
export const ALIGNER_DEFAULT_SUB_FOLDER = "aligner-input";
export const DEFAULT_TXT_SUB_FOLDER = "output";
export const DEMO_DENTAL_DOCTOR_EMAIL = "manoj@toothlens.com"

export const REPORT_IMAGE_TYPES = ['front_teeth', 'upper_jaw', 'lower_jaw', 'left_side', 'right_side'];
export const REPORT_IMAGE_TYPES_FOR_ALIGNER = ['aligner_front_teeth', 'aligner_upper_jaw', 'aligner_lower_jaw', 'aligner_left_side', 'aligner_right_side']