import React from 'react';
import ReusableTable from './TableList.component';

const TestingPage = () => {
  const data = [
    { id: 1, name: 'John', age: 30 },
    { id: 2, name: 'Jane', age: 25 },
    // Add more data as needed
  ];

  const columns = [
    { field: 'id', accessorKey: 'id', header: 'id' },
    { field: 'name', accessorKey: 'name', header: 'name' },
    { field: 'age', accessorKey: 'age', header: 'age' },
    // { field: 'actions', header: 'Actions' },
    // Add more columns as needed
  ];

  return (
    <div>
      <h1>My Data Table</h1>
      <ReusableTable data={data} columns={columns} />
    </div>
  );
};

export default TestingPage;
