import { USER_TYPE } from "../constants/common.constant"

export const ButtonLinkData = (user) => {
    let buttonLinkData = [
        {
            btnUrl: "/report-list",
            btnLabel: "report list"
        },
        {
            btnUrl: "/folders",
            btnLabel: "Folders"
        }
    ];

    // Based on the user_type adding elements into the `buttonLinkData`
    if (user?.user_type === USER_TYPE.ANNOTATOR || user?.user_type === USER_TYPE.SUPER_ANNOTATOR) {
        buttonLinkData.push({
            btnUrl: "/annotated-report",
            btnLabel: "Annotated reports"
        })
    } else if (user?.user_type === USER_TYPE.REVIEWER) {
        // buttonLinkData.push({
        //     btnUrl: "/reviewer-reviewed-reports",
        //     btnLabel: "Reviewed Reports"
        // })
    } else if (user?.user_type === USER_TYPE.ADMIN) {
        buttonLinkData = [];
        // buttonLinkData = [{
        //     btnUrl: "/tool-users",
        //     btnLabel: "Tool users"
        // }];
    }

    // Checking third-party report access
    if (user?.is_third_party_report_access) {
        buttonLinkData.push({
            btnUrl: "/third-party-reports",
            btnLabel: "Third party reports"
        })
    }

    // Checking for whether user is `SUPER_ANNOTATOR` or not
    if (user?.user_type === USER_TYPE.SUPER_ANNOTATOR) {
        // buttonLinkData.push({
        //     btnUrl: "/poor-alignment-reports",
        //     btnLabel: "Poor alignment Reports"
        // })
    }

    return buttonLinkData;

}