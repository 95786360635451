import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

const DarkTeethSection = ({ handleDarkTeethChanges, upper_teeth, lower_teeth }) => {
    return (
        <div className='my-1'>
            <label className='font-size-14'><b>Dark teeth:</b></label>
            <table className='table select-tooth-table font-size-14'>
                <thead>
                    <tr className='text-center'>
                        <th>Upper teeth</th>
                        <th>Lower teeth</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={upper_teeth ? upper_teeth : ''}
                                    name="radio-buttons-group"
                                    onChange={(event) => handleDarkTeethChanges(event, 'upper_teeth')}
                                    className='d-flex flex-column justify-content-start align-items-start'
                                >
                                    <FormControlLabel value="white" control={<Radio size="small" />} label="white" />
                                    <FormControlLabel value="normal" control={<Radio size="small" />} label="normal" />
                                    <FormControlLabel value="yellow/grey" control={<Radio size="small" />} label="yellow/grey" />
                                </RadioGroup>
                            </FormControl>
                        </td>
                        <td>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={lower_teeth ? lower_teeth : ''}
                                    name="radio-buttons-group"
                                    onChange={(event) => handleDarkTeethChanges(event, 'lower_teeth')}
                                    className='d-flex flex-column'
                                >
                                    <FormControlLabel value="white" control={<Radio size="small" />} label="white" />
                                    <FormControlLabel value="normal" control={<Radio size="small" />} label="normal" />
                                    <FormControlLabel value="yellow/grey" control={<Radio size="small" />} label="yellow/grey" />
                                </RadioGroup>
                            </FormControl>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default DarkTeethSection;