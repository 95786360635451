import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Crop32Icon from '@mui/icons-material/Crop32';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';

export default function NestedList({ drawRegion, exportToJsonFile, exportToCsvFile }) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
    //   sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
    //   component="nav"
    //   aria-labelledby="nested-list-subheader"
    //   subheader={
    //     <ListSubheader component="div" id="nested-list-subheader">
    //       Nested List Items
    //     </ListSubheader>
    //   }
    >
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <FormatShapesIcon />
        </ListItemIcon>
        <ListItemText primary="Region shape" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={drawRegion}>
            <ListItemIcon>
              <Crop32Icon className='text-danger' />
            </ListItemIcon>
            <ListItemText primary="Rectangle" />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}