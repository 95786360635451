import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";

const PoorAlignmentSection = (props) => {

    const { handlePoorAlignmentChanges,
        defaultOptionsForUpperTeeth,
        defaultOptionsForLowerTeeth,
        setDefaultOptionForUpperTeeth,
        setDefaultOptionForLowerTeeth } = props;

    return (
        <div className='my-1'>
            <label className='font-size-14'><b>Poor alignment:</b></label>
            <table className='table select-tooth-table text-center font-size-14'>
                <thead>
                    <tr>
                        <th>Upper teeth</th>
                        <th>Lower teeth</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup className='d-flex flex-column'>
                                    {
                                        defaultOptionsForUpperTeeth.map((item, index) => {
                                            return (
                                                <FormControlLabel key={item.label}
                                                    control={
                                                        <Checkbox
                                                            onChange={($event) => handlePoorAlignmentChanges($event, 'upper_teeth', index, defaultOptionsForUpperTeeth, setDefaultOptionForUpperTeeth)}
                                                            name={item.label}
                                                            size={item.size}
                                                            checked={item.checked}
                                                        />
                                                    }
                                                    label={item.label}
                                                />
                                            )
                                        })
                                    }
                                </FormGroup>
                            </FormControl>
                        </td>
                        <td>
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup className='d-flex flex-column'>
                                    {
                                        defaultOptionsForLowerTeeth.map((item, index) => {
                                            return (
                                                <FormControlLabel key={item.label}
                                                    control={
                                                        <Checkbox
                                                            onChange={($event) => handlePoorAlignmentChanges($event, 'lower_teeth', index, defaultOptionsForLowerTeeth, setDefaultOptionForLowerTeeth)}
                                                            name={item.label}
                                                            size={item.size}
                                                            checked={item.checked}
                                                        />
                                                    }
                                                    label={item.label}
                                                />
                                            )
                                        })
                                    }
                                </FormGroup>
                            </FormControl>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default PoorAlignmentSection;