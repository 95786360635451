import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function SelectSmall(props) {
  const [tags, setTags] = React.useState('');

  const handleChange = (event) => {
    setTags(event.target.value);
    props.onSelectedTags(event.target.value)
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small" className=''>Tags</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={tags}
        label="Tag"
        // className='text-white'
        // style={{ border: '1px solid white' }}
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="cavity">Cavity</MenuItem>
        <MenuItem value="tartar">Tartar</MenuItem>
      </Select>
    </FormControl>
  );
}