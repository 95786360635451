import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { LegendListData } from './utils/legendListData';

const LegendsList = ({ handleLegendsListChanges, isLegendFilterApplied, tagsList = [], isCheckBoxRequired = true }) => {

    const [legendsData, setLegendsData] = useState(JSON.parse(JSON.stringify(LegendListData))); // Deep clone array

    useEffect(() => {
        legendsData.map((item) => {
            tagsList.forEach((tag) => {
                if (item.type.toLowerCase() === tag.toLowerCase()) {
                    item.isShow = true;
                }
            })
            return item;
        })
        setLegendsData(legendsData);
    }, [tagsList]);

    useEffect(() => {
        setLegendsData((prevState) => {
            prevState.forEach((item) => {
                item.checked = true;
            })
            return prevState;
        })
    }, [isLegendFilterApplied]);

    const handleChange = (event, type, index) => {
        const data = {
            name: event.target.name,
            status: event.target.checked,
            type
        };
        event.target.checked ? legendsData[index].checked = true : legendsData[index].checked = false;
        handleLegendsListChanges(data);
    }

    return (
        tagsList.length ? <div className='legends mt-3 pb-3'>
            <h5 className='font-size-14 fw-bold'>Legends</h5>
            <div className='row'>
                <div className='col-12'>
                    <ul className='parent'>
                        {
                            legendsData.map((item, index) => {
                                return (
                                    item.isShow && <li key={item.title}>
                                        <FormControlLabel className='m-0'
                                            control={
                                                <Checkbox
                                                    onChange={($event) => handleChange($event, item.type, index)}
                                                    name={item.title}
                                                    className={isCheckBoxRequired ? '' : 'd-none'}
                                                    size="small"
                                                    checked={item.checked}
                                                />
                                            }
                                            label={
                                                <div className="d-flex align-items-center">
                                                    <span className='text font-size-12'>{item.title}</span>
                                                    <div className={`shape mt-0 ms-2 ${item.cssClasses}`}></div>
                                                </div>
                                            }
                                        />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div> : <p className='text-center my-2'>No tags assigned</p>
    )
}

export default LegendsList;