import { Switch } from "@mui/material";
import SimpleCheckbox from "../../Checkbox/SimpleCheckbox";

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const ImageUseableSection = ({ handleSwitchChange, isUseableImage, checkboxDataForImageUseable, handleImageUseable }) => {

    return (
        <div>
            <span className='font-size-14'><b>Image Useable:</b></span>
            <Switch
                onChange={handleSwitchChange}
                {...label}
                size="small"
                checked={isUseableImage}
            />
            {
                !isUseableImage && (
                    <>
                        <p className='font-size-13 mb-0'><b>Why is the image not useable?</b></p>
                        <div className="mb-2" component="fieldset">
                            {
                                checkboxDataForImageUseable.map((item, index) => {
                                    return (
                                        <div key={item.label}>
                                            <SimpleCheckbox
                                                handleImageUseable={handleImageUseable}
                                                label={item.label}
                                                size={item.size}
                                                checked={item.checked}
                                                value={item.value}
                                                index={index}
                                                id={item.value}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default ImageUseableSection;