import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { reviewerReviewedReports } from "../../Services/reportService";
import { USER_TYPE } from "../../constants/common.constant";
import HeaderNav from "../../Components/UI/Header/HeaderNav";
import ReportReviewedListTable from "./ReviewedListTable";
import BasicDateRangePicker from "./Components/DateRangePicker.component";
import { addDays } from "date-fns";
import AnnotatorFilter from "../../Components/UI/Autocomplete/AnnotatorFilter";
import { Button } from "@mui/material";

const ReviewedReports = () => {
    const navigate = useNavigate();
    const [reportListData, setReportListData] = useState();
    const [reportCount, setReportCount] = useState(0);
    const [totalReviewedReports, setTotalReviewedReports] = useState(0)
    const [selectedDate, setSelectedDate] = useState({ startDate: undefined, endDate: undefined });
    const [isLoading, setIsLoading] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [annotatorId, setAnnotatorId] = useState(undefined);
    const user = JSON.parse(localStorage.getItem('user'));

    const handleError = () => {
        localStorage.clear();
        navigate('/')
    }

    const handlePagination = (paginationData) => {
        const { startDate, endDate } = selectedDate;
        reviewerReviewedReports({ pageIndex: paginationData.pageIndex + 1, pageSize: paginationData.pageSize, startDate, endDate, annotatorId })
            .then(async (res) => {
                setReportListData(res.data.data.reports);
                setReportCount(res.data.data.useableReportCount);
                setTotalReviewedReports(res.data.data.totalReportCount);
            }, err => handleError());
    }

    const onDateChange = (data) => {
        let { startDate, endDate } = data[0];
        startDate = new Date(startDate).getTime();
        endDate = new Date(addDays(endDate, 1)).getTime();
        setSelectedDate({ startDate, endDate });
        reviewerReviewedReports({ pageIndex: 1, pageSize: 10, startDate, endDate, annotatorId })
            .then(async (res) => {
                setReportListData(res.data.data.reports);
                setReportCount(res.data.data.useableReportCount);
                setTotalReviewedReports(res.data.data.totalReportCount);
            }, err => handleError());
    };

    const getFilterState = (value) => {
        if (!value) {
            // props.handlePaginationData(pagination);
        }
        setIsFilterApplied(value)
    }

    const getFiltersData = async (data) => {
        setIsLoading(true);
        try {
            const { startDate, endDate } = selectedDate;
            setAnnotatorId(data._id);
            reviewerReviewedReports({ pageIndex: 1, pageSize: 10, startDate, endDate, annotatorId: data._id }).then(async (res) => {
                setReportListData(res.data.data.reports);
                setReportCount(res.data.data.useableReportCount);
                setTotalReviewedReports(res.data.data.totalReportCount);
            }, err => handleError());
        } catch (error) {

        }
        setIsLoading(false);
    }

    return (
        <>
            <HeaderNav title="Report List" />
            <div className="container mt-5 pt-5">
                <div className="row">
                    <div className="col-12 d-flex justify-content-between">
                        <div>
                            <BasicDateRangePicker onDateChange={onDateChange} />
                            <div className="col-12 py-3">
                                <span>Total Reviewed Reports: <b>{totalReviewedReports}</b></span>
                                <span className="ps-3">Total Useable Reports: <b>{reportCount}</b></span>
                            </div>
                        </div>
                        {
                            user?.user_type === USER_TYPE.ADMIN && <AnnotatorFilter
                                getFiltersData={getFiltersData}
                                // getLoadingState={setIsLoading}
                                getFilterState={getFilterState}
                                width={300}
                                label="Search by annotators"
                            />
                        }
                    </div>
                    <div className="col-12">
                        <Link to="/annotator-management" className="ms-2">
                            <Button variant="outlined">Annotator Management</Button>
                        </Link>
                    </div>
                    <div className="col-12">
                        <ReportReviewedListTable
                            btnLabel="Annotated reports"
                            btnUrl="annotated-report"
                            reportListData={reportListData}
                            reportCount={reportCount}
                            isFilterRequired={false}
                            isAnnotator={user?.user_type === USER_TYPE.ANNOTATOR ? true : false}
                            handlePaginationData={user?.user_type === USER_TYPE.ADMIN ? handlePagination : () => { }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReviewedReports;