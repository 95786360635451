export const LegendListData = [
    {
        title: 'C1, C2, C3 - Cavities',
        cssClasses: 'lc-bg-red',
        type: 'Cavities',
        checked: true,
        isShow: false
    },
    {
        title: 'T - Tartar and Stains',
        cssClasses: 'lc-bg-primary-1',
        type: 'Tartar',
        checked: true,
        isShow: false
    },
    {
        title: 'E - Enamel',
        cssClasses: 'lc-bg-green',
        type: 'Enamel',
        checked: true,
        isShow: false
    },
    {
        title: 'GR - Gum Recession',
        cssClasses: 'lc-bg-orange',
        type: 'Gum Recession',
        checked: true,
        isShow: false
    },
    {
        title: 'GS - Gum Swollen',
        cssClasses: 'lc-bg-blue',
        type: 'Gum Swollen',
        checked: true,
        isShow: false
    },
    {
        title: 'BT - Broken / Chipped teeth',
        cssClasses: 'lc-bg-primary',
        type: 'Broken Teeth',
        checked: true,
        isShow: false
    },
    {
        title: 'G - Gaps',
        cssClasses: 'lc-bg-yellow',
        type: 'Gaps',
        checked: true,
        isShow: false
    }
]