import React, { useEffect, useState } from 'react'
import { Box, Button, CssBaseline } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

import HeaderNav from '../../../Components/UI/Header/HeaderNav'
import ReportImage from '../../../Components/Report/ReportImage.component'
import ReportImageInDialogBox from '../../../Components/UI/Dialog/ReportImageInDialogBox'
import { getImagesByBucketAndFolderName } from '../../../Services/annotator.service'

const ListImagesForAnnotation = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [images, setImages] = useState([]);
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false);
    const [imageData, setImageData] = useState();
    const [completedImagesObj, setCompletedImagesObj] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isAllImagesCompleted, setIsAllImagesCompleted] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [imagesCount, setImagesCount] = useState({
        totalAnnotatedImagesCount: 0,
        totalImagesCount: 0
    });
    const [reportId, setReportId] = useState();

    // Getting bucket, subFolder and txtSubFolder
    const folderData = location?.state?.data?.split("/");
    const bucket = folderData.splice(0, 1)[0];
    const subFolder = folderData.join("/");
    const txtSubFolder = `${subFolder.split("/")[0]}/output`;

    // Getting Total pages
    const totalImagesLeft = imagesCount?.totalImagesCount - imagesCount?.totalAnnotatedImagesCount;
    const totalPages = Math.ceil(totalImagesLeft / 10) || 0;

    const getImages = async () => {
        setIsLoading(true);
        try {
            const response = await getImagesByBucketAndFolderName({ bucket, subFolder, pageIndex, pageSize: 10 });
            setIsLoading(false);
            setImagesCount(response?.data?.count)
            if (response?.data?.data.length) {
                setImages(response?.data?.data);
                setIsAllImagesCompleted(false);
                // const imagesData = response.data.data.map((img) => img.image_name);
            } else {
                setIsAllImagesCompleted(true);
                setImages([])
            }
        } catch (error) {
            localStorage.clear();
            navigate('/');
        }
    }

    useEffect(() => {
        getImages();
    }, [pageIndex]);

    function handleDialogBox(index, image, typesOfImages, reportId) {
        setImageData({ image, imageType: '' });
        setIsDialogBoxOpen(true);
        setReportId(reportId);
    }

    const handleCloseDialogBox = (dialogData) => {
        if (dialogData) {
            setCompletedImagesObj({ ...completedImagesObj, ...dialogData });
            getImages();
        }
        setIsDialogBoxOpen(false);
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <HeaderNav title="Toothlens Annotation Tool" />

            <div className='container mt-5 pt-5'>
                
            <div className="row my-3 fw-bold">
                    <div className="col-6">
                        <div>Total Images Annotated: {imagesCount?.totalAnnotatedImagesCount}</div>
                        <div>Total Images: {imagesCount?.totalImagesCount || 0}</div>
                    </div>
                </div>

                <div className='row'>
                    {
                        images.map((img, index) => {
                            return !completedImagesObj[img.image_name.split('/')[2]] && (
                                <ReportImage
                                    key={img.image_name + index}
                                    bucket={`${bucket}/${subFolder}`}
                                    image={img.image_name}
                                    imageTypeName={img.image_name}
                                    handleDialogBox={() => handleDialogBox(index, img.image_name, [], img._id)}
                                    isImageAnnotated={false}
                                />
                            )
                        })
                    }
                </div>

                <div className="my-3 text-center">
                        {/* Loading Text */}
                        {isLoading && <span>Loading...</span>}
                        {/* Page number */}
                        {!isLoading && <div>Current Page: {pageIndex}/{totalPages}</div>}
                        {/* Pagination Buttons */}
                        {!isLoading && pageIndex > 1 && <Button className="me-2" variant='contained'
                            onClick={() => setPageIndex((prevState) => prevState - 1)}
                        >prev</Button>}
                        {(!isLoading && totalPages !== pageIndex) &&
                            <Button variant='contained'
                                onClick={() => setPageIndex((prevState) => prevState + 1)}
                            >Next</Button>}
                    </div>

            </div>

            {
                isDialogBoxOpen && (
                    <>
                        {<ReportImageInDialogBox
                            bucketName={bucket}
                            JSONBucketName="toothlens-image-store"
                            JSONFolderName="json_output"
                            subFolderName={subFolder}
                            txtFolderName={txtSubFolder}
                            isDialogBoxOpen={isDialogBoxOpen}
                            imageData={imageData}
                            handleCloseDialogBox={handleCloseDialogBox}
                            missingTeeth={[]}
                            isAligner={false}
                            isGapsInFrontTeethReports={false}
                            isThirdPartyReports={false}
                            isImageAnnotation={true}
                            reportId={reportId}
                        />}
                    </>
                )
            }

        </Box>
    )
}

export default ListImagesForAnnotation