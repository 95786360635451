import { Box, CssBaseline } from '@mui/material'
import React, { useEffect, useState } from 'react'
import HeaderNav from '../../Components/UI/Header/HeaderNav'
import ListFolders from './Components/ListFolders.component'
import { useNavigate } from 'react-router-dom'
import { getAssignedFoldersAPI } from '../../Services/annotator.service';
import FolderNotFoundImage from '../../assets/images/folders-not-found-image.jpg';

const FolderImages = () => {

  const navigate = useNavigate();
  const [foldersData, setFoldersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAssignedFolders = async () => {
    setIsLoading(true);
    try {
      const response = await getAssignedFoldersAPI();
      if (response.status === 200) {
        setFoldersData(response.data.data.assigned_folders);
      }
    } catch (error) {
      localStorage.clear();
      navigate("/");
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getAssignedFolders();
  }, []);

return (
  <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    {/* Header nav */}
    <HeaderNav title="Toothlens Annotation Tool" />

    {/* When loading data from API */}
    {isLoading && <p className='w-100 text-center my-5 py-5'>Loading...</p>}

    {/* If there are no folders found */}
    {(!isLoading && (!foldersData || !foldersData?.length)) && <div className='row justify-content-center align-items-center mt-5 pt  -5'>
      <div className='col-4'>
      <p className='w-100 text-center'>Folders not found!</p>
      <img className='w-100' src={FolderNotFoundImage} alt='folders not found' />
      </div>
    </div>}

    {/* Listing all the folders */}
    <Box component="main" sx={{ flexGrow: 1, p: 4, }}>
      <div className='container-fluid mt-5 pt-5'>
        <div className='row justify-content-center align-items-center'>
          {
            foldersData?.map((folderData) => {
              return <div key={folderData} className='col-3'>
                <ListFolders folderData={folderData} />
              </div>
            })
          }

        </div>
      </div>
    </Box>

  </Box>
)
}

export default FolderImages