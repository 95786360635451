export const handleBeforeunloadEvent = () => {
    window.addEventListener('beforeunload', (e) => {
        e = e || window.event;
        // Cancel the event
        e.preventDefault();

        // For IE and Firefox prior to version 4
        if (e) {
            e.returnValue = 'Sure?';
        }

        // For Safari
        return 'Sure?';
    })
}

export const handleRemoveBeforeUnloadEvent = () => {
    window.removeEventListener('beforeunload', (e) => {
        e.preventDefault();
        e.returnValue = '';
    });
}