const LegendsListVDC = () => {
    return (
        <div className='legends mt-3 pb-3'>
            <h5 className='font-size-14'>Legends</h5>
            <div className='row'>
                <div className='col-12'>
                    <ul className='parent'>
                        <li className='d-flex'>
                            <div className='shape lc-bg-red'></div>
                            <span className='text font-size-12'>C - Cavities</span>
                        </li>
                        <li className='d-flex'>
                            <div className='shape lc-bg-orange'></div>
                            <span className='text font-size-12'>PB - Plaque Buildup</span>
                        </li>
                        <li className='d-flex'>
                            <div className='shape lc-bg-green'></div>
                            <span className='text font-size-12'>DR - Defective Restoration</span>
                        </li>
                        <li className='d-flex'>
                            <div className='shape lc-bg-blue'></div>
                            <span className='text font-size-12'>GI - Gum Inflammation</span>
                        </li>
                        <li className='d-flex'>
                            <div className='shape lc-bg-primary'></div>
                            <span className='text font-size-12'>B - Broken Tooth</span>
                        </li>
                    </ul>
                </div>

                <div className='col-12 my-3'>
                    <ul className='parent'>
                        <li className='d-flex'>
                            <span className='text font-size-12'>O - Observe</span>
                        </li>
                        <li className='d-flex'>
                            <span className='text font-size-12'>S - Slight</span>
                        </li>
                        <li className='d-flex'>
                            <span className='text font-size-12'>NT - Needs Treatment</span>
                        </li>
                        <li className='d-flex'>
                            <span className='text font-size-12'>M - Moderate</span>
                        </li>
                        <li className='d-flex'>
                            <span className='text font-size-12'>NO - Non Observable</span>
                        </li>
                        <li className='d-flex'>
                            <span className='text font-size-12'>SV - Severe</span>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default LegendsListVDC;