import { Alert, AppBar, Button, Snackbar, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_IMAGE_URL } from "../../Services/baseUrl";
import { vdcJsonStore } from "../../Services/reportService";

const imgData = [
    {
        image: '10081662134596092.jpeg'
    },
    {
        image: '57401675681199620.jpeg'
    }
]


const ShowReportImages = () => {

    const [reportListData, setReportListData] = useState([]);
    const [snackbarData, setSnackbarData] = useState();

    const saveFilesToBucket = () => {
        const fileData = {
            // filesData: ['10081662134596092.jpeg', '57401675681199620.jpeg'],
            // parentFolder: 'vdc-image-store',
            // subFolder: 'output',
            folderData: 'demo'
        }
        vdcJsonStore(fileData).then((res) => {
            setSnackbarData({ message: res?.data?.message, severity: 'success' });
        }, err => {
            setSnackbarData({ message: err?.data?.message, severity: 'error' });
        })
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarData();
    }


    return (
        <>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar className='justify-content-center'>
                    <Typography variant="h6" noWrap component="div">
                        Report list
                    </Typography>
                </Toolbar>
            </AppBar>
            <Snackbar
                open={snackbarData?.message ? true : false}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                <Alert onClose={handleSnackbarClose} variant="filled" severity={snackbarData?.severity} sx={{ width: '100%' }}>
                    {snackbarData?.message}
                </Alert>
            </Snackbar>
            <div className="container mt-5 pt-5">
                <div className="row">
                    {
                        imgData.map((item) => (
                            <div className="col-4" key={item.image}>
                                <Link to={`/view-report-images/vdc-image-store/demo/${item.image}`}>
                                    <img className="w-100" src={`${BASE_IMAGE_URL}/input/${item.image}`} alt="Report Images" width={480} height={320} />
                                </Link>
                            </div>
                        ))
                    }
                    <div className="col-12 text-center my-4">
                        <Button onClick={saveFilesToBucket} variant="contained">Submit</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShowReportImages;