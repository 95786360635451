import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CloseIconButton = ({ handleClose }) => {
    return (
        <IconButton aria-label="delete" onClick={handleClose}>
            <CloseIcon />
        </IconButton>
    )
}

export default CloseIconButton;