import React from 'react';
import { ListItemButton, ListItemIcon } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import Crop32Icon from '@mui/icons-material/Crop32';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SettingMenu from '../Menu/SettingMenu';

const ToolHeader = ({ drawRegion, clearRect, showImageWithoutTags, handleImageWithoutTags, handleLabel, isShowLabel, isAligner, activeTool,
            currentAligner, checkedAligner }) => {
    return (
        <div className='bg-dark text-white'>
            <Row>
                <Col xs={2} className={`text-center ${activeTool === 'rectangle' ? 'primary-bg' : ''}`}>
                    <ListItemButton className='d-flex flex-column' onClick={drawRegion}>
                        <ListItemIcon>
                            <Crop32Icon className='text-white font-size-35' />
                        </ListItemIcon>
                        <div className='font-size-12'>Rectangle</div>
                    </ListItemButton>
                </Col>
                <Col xs={2} className={`text-center ${activeTool === 'box' ? 'primary-bg' : ''}`}>
                    <ListItemButton className='d-flex flex-column' onClick={handleImageWithoutTags}>
                        <ListItemIcon>
                            {
                                !showImageWithoutTags ?
                                    <VisibilityIcon className='text-white font-size-35' /> :
                                    <VisibilityOffIcon className='text-white font-size-35' />
                            }

                        </ListItemIcon>
                        <div className='font-size-12'>Box</div>
                    </ListItemButton>
                </Col>
                {
                    !isAligner && (
                        <Col xs={2} className={`text-center ${activeTool === 'label' ? 'primary-bg' : ''}`}>
                            <ListItemButton className='d-flex flex-column' onClick={handleLabel}>
                                <ListItemIcon>
                                    {
                                        isShowLabel ?
                                            <VisibilityIcon className='text-white font-size-35' /> :
                                            <VisibilityOffIcon className='text-white font-size-35' />
                                    }

                                </ListItemIcon>
                                <div className='font-size-12'>Label</div>
                            </ListItemButton>
                        </Col>
                    )
                }
                <Col xs={1} className={`text-center`}>
                    <ListItemButton className='d-flex flex-column'>
                        <ListItemIcon>
                            <SettingMenu />
                        </ListItemIcon>
                        <div className='font-size-12'>Setting</div>
                    </ListItemButton>
                </Col>
                {
                    isAligner && <Col xs={6} className={`text-center `}>
                    <div className='d-flex align-items-center justify-content-evenly ms-auto h-100'>
                        <span>Current aligner: <b>{currentAligner}</b></span>
                        <span> Checked aligner: <b>{checkedAligner}</b></span>
                    </div>
                </Col>
                }
                {/* <Col xs={2} className={`text-center ${activeTool === 'rectangle' ? 'primary-bg' : ''}`}>
                    <ListItemButton className='d-flex flex-column' onClick={clearRect}>
                        <ListItemIcon>
                            <ClearIcon className='text-white font-size-35' />
                        </ListItemIcon>
                        <div className='font-size-12'>Clear</div>
                    </ListItemButton>
                </Col> */}
            </Row>
        </div>
    )
}

export default ToolHeader;