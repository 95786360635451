import axios from "axios";
import { API_HOST } from "./baseUrl";
import { getHeaders, handleError, handleSuccess } from "./common.service";

export const getAssignedFoldersAPI = () => {
    return new Promise((resolve, reject) => {
        return axios
            .get(API_HOST + `tool/annotator/get-folders`, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};

export const getAnnotatedImagesAPI = (data) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(API_HOST + `tool/annotator/get-annotated-images`, data, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};

export const getAssignedTagsAPI = (image) => {
    return new Promise((resolve, reject) => {
        return axios
            .get(API_HOST + `tool/annotator/access-tags?image=${image}`, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => { 
                handleError(error, reject);
            });
    });
};

export const getAnnotatorUsersAPI = (data) => {
    return new Promise((resolve, reject) => {
        return axios
            .get(API_HOST + `tool/get-annotator-users/${data.pageIndex}/${data.pageSize}`, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};

export const updateAnnotatorTagsAPI = (data) => {
    return new Promise((resolve, reject) => {
        return axios
            .put(API_HOST + `tool/annotator/update-tags`, data, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};

export const createAnnotatorAPI = (data) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(API_HOST + `tool/register`, data, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};

export const getImagesByBucketAndFolderName = (data) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(API_HOST + `tool/annotator/get-images-by-folder-name`, data, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};

export const removeAssignedFolder = (data) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(API_HOST + `tool/annotator/remove-folder`, data, { headers: getHeaders() })
            .then((response) => {
                handleSuccess(response, resolve, reject);
            })
            .catch((error) => {
                handleError(error, reject);
            });
    });
};