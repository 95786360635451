import { Checkbox } from "@mui/material";

const labels = { inputProps: { 'aria-label': 'Switch demo' } };

const SimpleCheckbox = ({ handleImageUseable, id, label, checked, value, index }) => {
    return (
        <>
            <Checkbox
                onChange={(event) => handleImageUseable(event, index)}
                {...labels}
                id={id}
                size="small"
                value={value}
                checked={checked}
            />
            <label className='font-size-14 cursor-pointer' htmlFor={id}>{label}</label>
        </>
    )
};

export default SimpleCheckbox;