
import './AlignerHeading.css';
const AlignerHeading = ({ title }) => {
    return (
        <div className="aligner-heading mb-4 col-10 text-center ">
            <h5>{title}</h5>
        </div>
    )
}

export default AlignerHeading;