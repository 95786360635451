import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { CardHeader, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import './BasicCard.css';
import SwipeableViews from 'react-swipeable-views';
import { getAssignedTagsAPI } from '../../../Services/annotator.service';
import { useNavigate } from 'react-router-dom';

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

export default function BasicCard(props) {
    const theme = useTheme();
    const [tags, setTags] = useState(props?.stepsData);
    const [activeStep, setActiveStep] = useState(0);

    const handleChange = (event) => {
        const obj = {
            name: event.target.value
        }
        switch (event.target.value) {
            case 'C':
                obj.color = '#FD636B';
                break;
            case 'GR':
                obj.color = '#FF7A59';
                break;
            case 'E':
                obj.color = '#2EB670';
                break;
            case 'GS':
                obj.color = '#27507E';
                break;
            case 'BT':
                obj.color = '#2196f3';
                break;
            case 'T':
                obj.color = '#5fbadd';
                break;
            case 'G':
                obj.color = '#ffc107';
                break;
            default:
                obj.color = '#5fbadd';
                break;
        }
        setTags(obj);
    }

    const handleCancel = () => {
        props.onSelectedTags();
    };

    const handleCavitySecondStep = (event) => {
        tags.name = event.target.value;
        setTags(tags);
    }

    const onSubmit = () => {
        if (tags.name === 'C' && activeStep === 0) {
            return setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        props.onSelectedTags(tags);
    }

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const initialName = (words) => {
        return words
            .replace(/\b(\w)\w+/g, '$1')
            .replace(/\s/g, '')
            .replace(/\.$/, '')
            .toUpperCase();
    }

    return (
        <Card className='basic-card-container' sx={{ minWidth: 250 }}>
            <CardHeader subheader="Select Category" />
            <CardContent>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                // enableMouseEvents
                >
                    <div>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                className='text-capitalize'
                                name="radio-buttons-group"
                                onChange={handleChange}
                                value={tags?.name ? tags.name : ""}
                            >

                                {
                                    props?.tagsList.map((tag) => {
                                        return (
                                            <FormControlLabel key={tag} value={initialName(tag)} control={<Radio size='small' />} label={tag} />
                                        )
                                    })
                                }
                                {/* <FormControlLabel value="C" control={<Radio size='small' />} label="Cavities" />
                                <FormControlLabel value="GR" control={<Radio size='small' />} label="Gum Recession" />
                                <FormControlLabel value="T" control={<Radio size='small' />} label="Tartar" />
                                <FormControlLabel value="GS" control={<Radio size='small' />} label="Gum Swollen" />
                                <FormControlLabel value="BT" control={<Radio size='small' />} label="Broken teeth" />
                                <FormControlLabel value="E" control={<Radio size='small' />} label="Enamel" />
                                <FormControlLabel value="G" control={<Radio size='small' />} label="Gaps" /> */}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {
                        tags?.name === 'C' && (
                            <div>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group1"
                                        onChange={($event) => handleCavitySecondStep($event, 'steps2')}
                                    >
                                        <FormControlLabel value="C1" control={<Radio size='small' />} label="C1" />
                                        <FormControlLabel value="C2" control={<Radio size='small' />} label="C2" />
                                        <FormControlLabel value="C3" control={<Radio size='small' />} label="C3" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        )
                    }
                </SwipeableViews>
            </CardContent>
            <CardActions className='justify-content-end'>
                <Button variant="contained" className='bg-danger text-capitalize' size="small" onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" className='text-capitalize' size="small" onClick={onSubmit}>
                    {
                        tags?.name === 'C' && activeStep === 0 ? 'Next' : 'Submit'
                    }
                </Button>
            </CardActions>
        </Card>
    );
}