import React, { useState } from 'react';
import MaterialTable from 'material-react-table';
import { Button, MenuItem } from '@mui/material';
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
} from 'material-react-table';
import { useNavigate } from 'react-router-dom';

const ReusableTable = ({ columns, data }) => {
  const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState(data);
  
    const handlePageChange = (newPage) => {
      console.log("newPage", newPage)
      setPage(newPage);
    };
  
    const handleRowsPerPageChange = (perPage) => {
      console.log("perPage", perPage)
      setRowsPerPage(perPage);
    };
  
    const handleSearch = (term) => {
      console.log("term", term);
      setSearchTerm(term);
      const filtered = data.filter((row) =>
        Object.values(row).some((value) =>
          value.toString().toLowerCase().includes(term.toLowerCase())
        )
      );
      setFilteredData(filtered);
      setPage(1); // Reset page when filtering
    };
  
    const paginatedData = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  
    return (
      <MaterialTable
        columns={[
          ...columns,
          {
            // accessorKey: 'actions',
            header: 'Actions',
            Cell: (row) => (
                <Button onClick={() => navigate('/annotation-tool', {
                    state: {
                        data: ''
                    }
                })}>view</Button>
            ),
          },
        ]}
        data={paginatedData}
      />
    );
  };

// const ReusableTable = ({ data, columns }) => {
//     const [currentPage, setCurrentPage] = useState(0);
//     const [rowsPerPage] = useState(5); // Adjust as needed
//     const [filteredData, setFilteredData] = useState(data);

//     const handleFilterChange = (filters) => {
//         // Filter the data based on the applied filters
//         // You can implement your custom filtering logic here
//         let filteredData = data.filter((row) =>
//             Object.entries(filters).every(([columnId, filterValue]) => {
//                 return row[columnId].toLowerCase().includes(filterValue.toLowerCase());
//             })
//         );
//         setFilteredData(filteredData);
//     };

//     const handleCustomAction = (rowData) => {
//         // Implement your custom action logic here
//         alert(`Custom action clicked for ${rowData.name}`);
//     };

//     const handleChangePage = (newPage) => {
//         setCurrentPage(newPage);
//     };

//     const getPaginatedData = () => {
//         const startIndex = currentPage * rowsPerPage;
//         const endIndex = startIndex + rowsPerPage;
//         return filteredData.slice(startIndex, endIndex);
//     };

//     return (
//         <MaterialTable
//             data={getPaginatedData()}
//             columns={columns}
//             options={{
//                 pagination: true,
//                 filtering: true,
//                 actionsColumnIndex: -1, // Place custom actions as the last column
//                 enableRowActions: true,
//                 enableColumnActions: true,
//                 onChangeFilter: handleFilterChange,
//                 onChangePage: handleChangePage,
//                 // You can add more options as needed
//                 renderRowActionMenuItems: ({ row }) => [
//                     <MenuItem key="edit" onClick={() => console.info('Edit')}>
//                         Edit
//                     </MenuItem>,
//                     <MenuItem key="delete" onClick={() => console.info('Delete')}>
//                         Delete
//                     </MenuItem>,
//                 ],
//             }}
//             // actions={[
//             //     {
//             //         title: 'Actions',
//             //         header: 'sss',
//             //         columns: 'helo',
//             //         icon: 'edit',
//             //         tooltip: 'Custom Action',
//             //         onClick: (event, rowData) => handleCustomAction(rowData),
//             //     },
//             //     // Add more custom actions as needed
//             // ]}
//             totalCount={filteredData.length} // Total count for pagination
//         />
//     );
// };

export default ReusableTable;
