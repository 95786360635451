import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import NestedList from '../UI/List/List';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import './rect.css';
import { Fab } from '@mui/material';
import DataTable from '../UI/Table/TableGrid';
import BasicTable from '../UI/Table/TableGrid';
import SelectSmall from '../UI/SelectBox/SelectBox';
import { useLocation } from 'react-router-dom';
import { CAVITY_IMG_URL, TARTAR_IMG_URL } from '../../Services/baseUrl';
import * as markerjs2 from "markerjs2";
import * as mjslive from 'markerjs-live';



const drawerWidth = 240;

export default function ImageAnnotationTool(props) {

    /**
     * Collection of rectangles defining user generated regions
     */
    let rectangles = [];
    const location = useLocation();
    const [locationData, setLocationData] = useState(location.state.data);
    const [rectanglesData, setRectanglesData] = useState([]);
    const [reportImagesType, setReportImagesType] = useState(['front_teeth', 'upper_jaw', 'lower_jaw', 'left_side', 'right_side']);
    const [activeImage, setActiveImage] = useState();
    const [imageAngle, setImageAngle] = useState();

    // DOM elements
    const [screenshot, setScreenShot] = useState();
    // const $draw = domValue('#draw');
    const [marquee, setMarquee] = useState();
    const [boxes, setBoxes] = useState();
    const [imageTags, setImageTags] = useState({ zIndex: '9', display: 'none' });
    const [selectedImageTags, setSelectedImageTags] = useState([]);
    const imgRef = useRef();
    const imageContainer = useRef();
    const [markedImages, setMarkedImages] = useState();

    // Temp variables
    let startX = 0;
    let startY = 0;
    const marqueeRect = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    };

    const config = {
        width: 798,
        height: 775,
        markers: [
            {
                fillColor: "transparent",
                strokeColor: "#FFFF00",
                strokeWidth: 3,
                strokeDasharray: "3",
                opacity: 1,
                left: 435,
                top: 738,
                width: 63,
                height: 34,
                rotationAngle: 0,
                visualTransformMatrix: { a: 1, b: 0, c: 0, d: 1, e: 0, f: 0 },
                containerTransformMatrix: { a: 1, b: 0, c: 0, d: 1, e: 0, f: 0 },
                typeName: "EllipseFrameMarker",
                notes: "https://en.wikipedia.org/wiki/Logo",
                state: "select"
            },
            {
                strokeColor: "#EF4444",
                strokeWidth: 3,
                strokeDasharray: "",
                x1: 212,
                y1: 474,
                x2: 211,
                y2: 535,
                typeName: "MeasurementMarker",
                notes: "https://en.wikipedia.org/wiki/Parallel_(geometry)",
                state: "select"
            },
            {
                strokeColor: "#EF4444",
                strokeWidth: 3,
                strokeDasharray: "",
                x1: 734,
                y1: 470,
                x2: 733,
                y2: 553,
                typeName: "MeasurementMarker",
                notes: "https://en.wikipedia.org/wiki/Parallel_(geometry)",
                state: "select"
            }
        ]
    };

    useEffect(() => {
        const domValue = document.querySelector.bind(document);
        setActiveImage(setActiveReportImages(0));
        setScreenShot(domValue('#screenshot'));
        setMarquee(domValue('#marquee'));
        setBoxes(domValue('#boxes'));
        // setCanvas(document.getElementById('canvas'));

        marquee?.classList.add('hide');

        return () => {
        }

    }, [marquee, screenshot]);

    function drawRegion() {
        screenshot?.addEventListener('pointerdown', startDrag);
    }

    function startDrag(ev) {
        // middle button delete rect
        if (!boxes.innerHTML) {
            rectangles = [];
        }
        if (ev.button === 1) {
            const rect = hitTest(ev.layerX, ev.layerY);
            if (rect) {
                rectangles.splice(rectangles.indexOf(rect), 1);
                redraw();
            }
            return;
        }
        window.addEventListener('pointerup', stopDrag);
        screenshot.addEventListener('pointermove', moveDrag);
        marquee.classList.remove('hide');
        startX = ev.layerX;
        startY = ev.layerY;
        drawRect(marquee, startX, startY, 0, 0);
    }

    function stopDrag(ev) {
        marquee.classList.add('hide');
        window.removeEventListener('pointerup', stopDrag);
        screenshot.removeEventListener('pointermove', moveDrag);
        if (ev.target === screenshot && marqueeRect.width && marqueeRect.height) {
            const lastTagValue = rectangles[rectangles.length - 1];
            if (marqueeRect.x !== lastTagValue?.x && marqueeRect.y !== lastTagValue?.y) {
                rectangles.push(Object.assign({}, marqueeRect));
                setRectanglesData([...rectangles]);
                redraw();
                setImageTags({ left: `${marqueeRect.x}px`, top: `${marqueeRect.y + marqueeRect.height}px`, display: 'block', zIndex: '3' });
            }
        }
    }

    function moveDrag(ev) {
        let x = ev.layerX;
        let y = ev.layerY;
        let width = startX - x;
        let height = startY - y;
        if (width < 0) {
            width *= -1;
            x -= width;
        }
        if (height < 0) {
            height *= -1;
            y -= height;
        }
        Object.assign(marqueeRect, { x, y, width, height });
        drawRect(marquee, marqueeRect);
    }

    function hitTest(x, y) {
        return rectangles.find(rect => (
            x >= rect.x &&
            y >= rect.y &&
            x <= rect.x + rect.width &&
            y <= rect.y + rect.height
        ));
    }

    function redraw() {
        boxes.innerHTML = '';
        rectangles.forEach((data) => {
            boxes.appendChild(drawRect(
                document.createElementNS("http://www.w3.org/2000/svg", 'rect'), data
            ));
        });
    }

    function drawRect(rect, data) {
        const { x, y, width, height } = data;
        if (x && y && width && height) {
            rect?.setAttributeNS(null, 'width', width);
            rect?.setAttributeNS(null, 'height', height);
            rect?.setAttributeNS(null, 'x', x);
            rect?.setAttributeNS(null, 'y', y);
            return rect;
        }
    }

    function clearRect() {
        boxes.innerHTML = '';
        rectangles = [];
        setRectanglesData([]);
        setSelectedImageTags([]);
        setImageTags({ display: 'none' });
    }

    function exportToJsonFile(jsonData) {
        let dataStr = JSON.stringify(jsonData);
        let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

        let exportFileDefaultName = 'data.json';

        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
    }

    function parseJSONToCSVStr(jsonData) {
        if (jsonData.length === 0) {
            return '';
        }

        let keys = Object.keys(jsonData[0]);

        let columnDelimiter = ',';
        let lineDelimiter = '\n';

        let csvColumnHeader = keys.join(columnDelimiter);
        let csvStr = csvColumnHeader + lineDelimiter;

        jsonData.forEach(item => {
            keys.forEach((key, index) => {
                if ((index > 0) && (index < keys.length - 1)) {
                    csvStr += columnDelimiter;
                }
                csvStr += item[key];
            });
            csvStr += lineDelimiter;
        });

        return encodeURIComponent(csvStr);;
    }

    function exportToCsvFile(jsonData) {
        let csvStr = parseJSONToCSVStr(jsonData);
        let dataUri = 'data:text/csv;charset=utf-8,' + csvStr;

        let exportFileDefaultName = 'data.csv';

        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
    }

    function getSelectedTags(tag) {
        const rectData = rectanglesData[rectanglesData.length - 1];
        const tags = [...selectedImageTags];
        tags.push({ name: tag, style: { left: `${rectData.left}px`, top: `${rectData.top - 25}px` } });
        setSelectedImageTags(tags);
        setImageTags({ display: 'none' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function setActiveReportImages(index) {
        setImageAngle(reportImagesType[index]);
        return locationData.isCavityOrTartar === 'cavity' ? `${CAVITY_IMG_URL}${locationData[reportImagesType[index]]}` :
            `${TARTAR_IMG_URL}${locationData[reportImagesType[index]]}`
    }

    function showMarkers(target) {
        const markerView = new mjslive.MarkerView(target);
        // markerView.availableMarkerTypes = ['FrameMarker'];
        markerView.show(config);
    }

    function showMarkerArea() {
        if (imgRef.current !== null) {
            // create a marker.js MarkerArea
            showMarkers(imgRef.current)
            const markerArea = new markerjs2.MarkerArea(imgRef.current);
            markerArea.availableMarkerTypes = ['FrameMarker'];
            // attach an event handler to assign annotated image back to our image element
            markerArea.addEventListener("render", (event) => {
                console.log('render', event.dataUrl)
                imageContainer.current.style.position = 'unset';
                setMarkedImages(event.dataUrl);
                if (imgRef.current) {
                    imgRef.current.src = event.dataUrl;
                }
            });

            // make sure clicking close wasn't an accident
            markerArea.addEventListener("beforeclose", (event) => {
                // eslint-disable-next-line no-restricted-globals
                if (!confirm("Do you want to discard changes?")) {
                    event.preventDefault();
                }
            });

            // start creating a new marker each time a marker is created
            markerArea.addEventListener("markercreate", (event) => {
                console.log('created', event);
                imageContainer.current.style.position = 'relative';
                setImageTags({ left: `${event.marker.left}px`, top: `${event.marker.top + event.marker.height}px`, display: 'block', zIndex: '9' });
                const rectTags = [...rectanglesData];
                rectTags.push({
                    left: event.marker.left,
                    top: event.marker.top,
                    height: event.marker.height,
                    width: event.marker.width,
                })
                setRectanglesData(rectTags);
                // event.markerArea.createNewMarker(event.marker.typeName);
            });

            // launch marker.js
            markerArea.show();
        }
    }

    function addArrow() {
        if (imgRef.current !== null) {
            const markerArea = new markerjs2.MarkerArea(imgRef.current);
            markerArea.addEventListener("render", (event) => {
                if (imgRef.current) {
                    imgRef.current.src = event.dataUrl;
                }
            });
            // launch marker.js
            markerArea.show();
            if (markerArea) {
                markerArea.createNewMarker(markerjs2.FrameMarker);
            }
        }
    }


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar className='justify-content-center'>
                    <Typography variant="h6" noWrap component="div">
                        Toothlens Annotation Tool
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <NestedList
                        drawRegion={drawRegion}
                    />
                    <Divider />
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => exportToCsvFile(rectanglesData)}>
                                <ListItemIcon>
                                    <InboxIcon />
                                </ListItemIcon>
                                <ListItemText primary="Download as csv" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => exportToJsonFile(rectanglesData)}>
                                <ListItemIcon>
                                    <InboxIcon />
                                </ListItemIcon>
                                <ListItemText primary="Download as json" />
                            </ListItemButton>
                        </ListItem>
                    </List>

                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                <div className='container'>
                    {rectanglesData.length}
                    <Button>Download Image</Button>
                    <Button onClick={addArrow}>Arrow</Button>
                    <div className='row align-item-center justify-content-center'>
                        <div className='col-6'>
                            <div className='height-600px width-500px' ref={imageContainer}>
                                {/* <h5>Front teeth</h5> */}

                                {/* <div className='row my-2'>
                                    <div className='col-6'>
                                        <Fab color="primary" size="small" aria-label="add" className='prev-arrow position-absolute'>
                                            <ArrowBackIosIcon />
                                        </Fab>

                                    </div>
                                    <div className='col-6 text-end'>
                                        <Fab color="primary" size="small" aria-label="add" className='next-arrow position-absolute'>
                                            <ArrowForwardIosIcon />
                                        </Fab>
                                    </div>
                                </div> */}
                                {/* <img
                                width="500"
                                height="500"
                                src={activeImage}
                                alt="report"
                                draggable="false"
                                ref={imgRef}
                                onClick={() => showMarkerArea()}
                                crossOrigin="anonymous"
                            /> */}
                                <div className='w-100 height-85'>
                                    <img
                                        width="500"
                                        height="500"
                                        src={activeImage}
                                        // id="screenshot"
                                        alt="report"
                                        draggable="false"
                                        ref={imgRef}
                                        onClick={() => showMarkerArea()}
                                        crossOrigin="anonymous"
                                    />

                                    {/* <svg width="500" height="500" viewBox="0 0 500 500" id="draw" xmlns="http://www.w3.org/2000/svg">
                                        <rect id="marquee" x="450" y="420" width="150" height="150" />
                                        <g id="boxes"></g>
                                    </svg> */}

                                    {
                                        selectedImageTags.map((item, index) => (
                                            <div key={index} className='position-absolute tag-name' style={item.style}>{item.name}</div>
                                        ))
                                    }

                                    <div className='position-absolute tag-box' style={imageTags}>
                                        <SelectSmall onSelectedTags={getSelectedTags} />
                                    </div>
                                </div>
                                <div className='row my-1 mx-0'>
                                    {
                                        reportImagesType.map((item, index) => (
                                            <div key={item} className='col-2 width-20 small-img px-1'
                                                onClick={() => {
                                                    setActiveImage(setActiveReportImages(index));
                                                    clearRect();
                                                }}>
                                                <img
                                                    className='w-100'
                                                    src={locationData.isCavityOrTartar === 'cavity' ?
                                                        `${CAVITY_IMG_URL}${locationData[item]}` :
                                                        `${TARTAR_IMG_URL}${locationData[item]}`}
                                                    alt={item}
                                                    draggable="false"
                                                />
                                            </div>
                                        ))
                                    }
                                    <div className='col-12 text-end my-2 p-0'>
                                        <Button variant="contalet config = {
        width: 798,
        height: 775,
        
      };ined" onClick={clearRect}>Clear</Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-6'>
                            {/* <h5>Tags</h5> */}
                            <BasicTable
                                reason={locationData.reason}
                                imgAngle={imageAngle}
                            />
                            {
                                markedImages && <img src={markedImages} alt="marked images" />
                            }

                            {/* <img
                                width="500"
                                height="500"
                                src={activeImage}
                                alt="report"
                                draggable="false"
                                ref={imgRef}
                                onClick={() => showMarkerArea()}
                                crossOrigin="anonymous"
                            /> */}

                        </div>

                    </div>
                    <Divider />
                </div>

            </Box>
        </Box>
    );
}