import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';

const PermissionOptionsWithSwitch = ({ annotatorData, handlePermissionOptions }) => {
    const [permissionOptionsData, setPermissionOptionsData] = useState({
        is_image_useable_access: annotatorData?.tags_and_access.is_image_useable_access ? annotatorData.tags_and_access.is_image_useable_access : false,
        is_dark_teeth_access: annotatorData?.tags_and_access.is_dark_teeth_access ? annotatorData.tags_and_access.is_dark_teeth_access : false,
        is_missing_teeth_access: annotatorData?.tags_and_access.is_missing_teeth_access ? annotatorData.tags_and_access.is_missing_teeth_access : false,
        is_poor_alignment_access: annotatorData?.tags_and_access.is_poor_alignment_access ? annotatorData.tags_and_access.is_poor_alignment_access : false,
        is_third_party_report_access: annotatorData?.tags_and_access.is_third_party_report_access ? annotatorData.tags_and_access.is_third_party_report_access : false,
    })

    useEffect(() => {
        handlePermissionOptions(permissionOptionsData);
    }, [permissionOptionsData]);

    const handleChange = (event) => {
        const permissionObjData = { ...permissionOptionsData };

        switch (event.target.name) {
            case 'Image useable access':
                permissionObjData.is_image_useable_access = event.target.checked;
                break;
            case 'Dark Teeth access':
                permissionObjData.is_dark_teeth_access = event.target.checked;
                break;
            case 'Missing Teeth access':
                permissionObjData.is_missing_teeth_access = event.target.checked;
                break;
            case 'Poor alignment access':
                permissionObjData.is_poor_alignment_access = event.target.checked;
                break;
            case 'Third Party Report Access':
                permissionObjData.is_third_party_report_access = event.target.checked;
                break;
            default:
                return permissionObjData;
        }
        setPermissionOptionsData(permissionObjData);
    }

    return (
        <FormGroup>
            <FormControlLabel control={<Switch checked={permissionOptionsData.is_image_useable_access}
                onChange={handleChange} />} label="Image useable access" name="Image useable access" />
            <FormControlLabel control={<Switch checked={permissionOptionsData.is_poor_alignment_access}
                onChange={handleChange} />} label="Poor alignment access" name="Poor alignment access" />
            <FormControlLabel control={<Switch checked={permissionOptionsData.is_dark_teeth_access}
                onChange={handleChange} />} label="Dark Teeth access" name="Dark Teeth access" />
            <FormControlLabel control={<Switch checked={permissionOptionsData.is_missing_teeth_access}
                onChange={handleChange} />} label="Missing Teeth access" name="Missing Teeth access" />
            <FormControlLabel control={<Switch checked={permissionOptionsData.is_third_party_report_access}
                onChange={handleChange} />} label="Third party report Access" name="Third Party Report Access" />
        </FormGroup>
    )
}

export default PermissionOptionsWithSwitch;